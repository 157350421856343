import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate} from '@angular/router';
import {AuthenticationService} from './authentication.service';
import {Location} from '@angular/common';

/**
 * Guard to activate or deactivate anonymous routes
 */
@Injectable()
export class AnonymousGuard implements CanActivate {

  constructor(private authenticationService: AuthenticationService,
              private location: Location,) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (this.authenticationService.isAuthenticated()) {
      this.location.back();
      return false;
    }
    return true;
  }
}
