import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';
import { IpagedQuery } from 'src/app/model/IpagedQuery';

@Injectable({
  providedIn: 'root'
})
export class ManageJobsService {

  constructor(private http: HttpClient) { }

  viewJobs(query:IpagedQuery,status?:any,clientId?:any,fromDate?:any,toDate?:any,fleetId?:any,sort?:any,assignedTo?:string, notes?: any, bucket?:string): Observable<any> {
    var params = new HttpParams({
      fromObject:{
        isActive:query.isActive.valueOf(),
        page:query.page.toString(),
        size:query.size.toString(),
        
      }
    })
    
    if(assignedTo)
    {
      params = params.append("assignedTo.uuid",assignedTo)
    }
   
    if(sort)
    {
      var sortBy = 'createdDate,'+sort;
      params = params.append("sort",sortBy)
    }
    else
    {
      if(bucket)
      {
        
         params = params.append("sort",'queuePosition,asc')
      }
      else
      {
        params = params.append("sort",'createdDate,desc')
      } 
    }
    if(status){
      params = params.append("status",status.toString())
    }
    if(clientId){
      params = params.append("pickupLocation.client.uuid",clientId.toString())
    }
    if(fromDate && toDate){
      params = params.append("fromDate",fromDate.toString())
      params = params.append("toDate",toDate.toString())
    }
    if(fleetId){
      params = params.append("fleet.uuid",fleetId.toString())
    }
    if(notes){
      // console.log("notes", notes);
      
      params = params.append("description",notes)
    }
    if(bucket)
    {
      params = params.append("bucket",bucket)
    }
    return this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/jobs`,{params});
  }

  changeJobStatus(jobId,obj):Observable<any>{
    return this.http.patch(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/jobs/${jobId}/status`,obj);
  }

  addException(jobId, exception):Observable<any>{
    return this.http.put(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/jobs/update/exception/${jobId}?exception=${exception}`,{});
  }

  changeQueueOrder(changeQueueOrderObj):Observable<any>{
     return this.http.post(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/jobs/changeQueueOrder`,changeQueueOrderObj);
  }

}
