<div class="grid">
    <div class="col-12">
        <div class="card card-w-title">
            <p-breadcrumb [model]="breadcrumbItems" [home]="{icon: 'pi pi-home',routerLink:'../'}"></p-breadcrumb>
        </div>
    </div>
    <div class="col-12">
        <div class="card">
            <p-toast></p-toast>
            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="left">
                    <div class="my-2">
                        <h5 class="m-0">Overview</h5>
                    </div>
                </ng-template>

                <ng-template pTemplate="right">

                    <p-splitButton label="Export" [model]="exportTypes" (onClick)="exportExcel()"
                        styleClass="p-button-raised p-button-help mr-2 "></p-splitButton>

                </ng-template>
            </p-toolbar>

            <p-table #dt [value]="data" [loading]=""
                styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" [responsive]="true" [columns]=""
                [rows]="10" [rowHover]="true" dataKey="id">
                <ng-template pTemplate="caption">
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <span>
                            <p-dropdown [showClear]="true" *ngIf="userType == 'ROLE_ADMIN'" placeholder="Select Client"
                                [options]="clientList" (onChange)="filterByClient($event,0)"
                                [(ngModel)]="selectedClient" optionLabel="clientName"
                                optionValue="clientId"></p-dropdown>
                        </span>
                        <div class="flex">
                            <p-multiSelect class='multiselect-input-width mr-4' [options]="uniqueCarrierList"
                                optionLabel="label" optionValue="label" placeholder="Exclude carriers"
                                resetFilterOnHide=true display="chip" [(ngModel)]="selectedExcludeCarrier"
                                (onChange)="filterByCarrier(selectedExcludeCarrier,0,0)" [showClear]="true"></p-multiSelect>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="search($event.target.value,0)"
                                    [(ngModel)]="searchText" placeholder="Search..." />
                            </span>
                        </div>


                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>

                        <th pSortableColumn="unitNumber">
                            <div class="flex flex-column md:flex-row md:justify-content-between">
                                <span>Unit Number</span>

                            </div>
                        </th>
                        <th pSortableColumn="type">Trailer /Container</th>
                        <th pSortableColumn="carrier">Carrier</th>
                        <th pSortableColumn="location">Location</th>
                        <th>
                            <div class="flex justify-content-center justify-content-between">
                                <span>Time in Area</span>
                                <p-overlayPanel #op1>

                                    <ng-template pTemplate="content" style="height: 2000px;">
                                        <h4>Sort</h4>
                                        <p-dropdown [(ngModel)]="selectedTimeInArea" placeholder="Order By"
                                            [showClear]="true" [options]="sort" optionLabel="name"
                                            (onChange)="sortTime($event,'client',0)" appendTo="body">
                                        </p-dropdown>
                                    </ng-template>


                                </p-overlayPanel>
                                <i class="pi pi-filter" (click)="op1.toggle($event)"></i>
                            </div>
                        </th>
                        <th>


                            <div class="flex justify-content-center justify-content-between">
                                <span>Time on Yard</span>
                                <p-overlayPanel #op2>

                                    <ng-template pTemplate="content" style="height: 2000px;">
                                        <h4>Sort</h4>
                                        <p-dropdown [(ngModel)]="selectedTimeInYard" placeholder="Order By"
                                            [showClear]="true" [options]="sort" optionLabel="name"
                                            (onChange)="sortTime($event,'location',0)" appendTo="body">
                                        </p-dropdown>
                                    </ng-template>


                                </p-overlayPanel>
                                <i class="pi pi-filter" (click)="op2.toggle($event)"></i>
                            </div>

                        </th>
                        <th>
                            <div class="flex justify-content-center justify-content-between">
                                <span>Time in Spot</span>
                                <p-overlayPanel #op3>

                                    <ng-template pTemplate="content" style="height: 2000px;">
                                        <h4>Sort</h4>
                                        <p-dropdown [(ngModel)]="selectedTimeInSpot" placeholder="Order By"
                                            [showClear]="true" [options]="sort" optionLabel="name"
                                            (onChange)="sortTime($event,'spot',0)" appendTo="body">
                                        </p-dropdown>
                                    </ng-template>


                                </p-overlayPanel>
                                <i class="pi pi-filter" (click)="op3.toggle($event)"></i>
                            </div>
                        </th>
                        <th *ngIf="userType != 'ROLE_CLIENT'">New Spot</th>
                    </tr>
                    <tr>

                        <th>

                        </th>
                        <th>
                            <p-dropdown [options]="types" placeholder="Select type" [showClear]="true"
                                optionLabel="label" optionValue="label" [(ngModel)]="selectedType"
                                (onChange)="filterByType($event,0)"></p-dropdown>
                        </th>
                        <th>
                                <p-dropdown [showClear]="true" placeholder="Select Carrier"
                                    [options]="uniqueCarrierList" [(ngModel)]="selectedCarrier"
                                    (onChange)="filterByCarrier($event,1,0)" optionLabel="label"
                                    optionValue="label"></p-dropdown>

                        </th>
                        <th>

                            <p-dropdown [showClear]="true" placeholder="Select Location"
                            [options]="locationLists" [(ngModel)]="selectedLocation"
                            (onChange)="filterByLocation($event,0)" optionLabel="locationName"
                            optionValue="locationName"></p-dropdown>
                        </th>
                        <th>

                        </th>
                        <th>

                        </th>
                        <th>

                        </th>
                        <th *ngIf="userType != 'ROLE_CLIENT'">

                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                    <tr>

                        <td><span class="p-column-title">Unit Number</span>
                            {{item.unitNumber}}
                        </td>
                        <td>
                            <span class="p-column-title">Trailer /Container</span>
                            {{item.type}}
                        </td>
                        <td>
                            <span class="p-column-title">Carrier</span>
                            {{item.carrier}}
                        </td>
                        <td>
                            <span class="p-column-title">Location</span>

                            {{item.location}} {{ item.location != undefined && item.location != "" && item.spot != undefined && item.spot != ""? '-' : '' }} {{ item.spot }}
                        </td>
                        <td>
                            <span class="p-column-title">Time in Area</span>
                            {{item.clientOccupiedTime}}
                        </td>
                        <td>
                            <span class="p-column-title">Time in Yard</span>

                            {{item.locationOccupiedTime}}
                        </td>
                        <td>
                            <span class="p-column-title">Time in Spot</span>

                            {{item.spotOccupiedTime}}
                        </td>
                        <td *ngIf="userType != 'ROLE_CLIENT'">
                            <button type="button" (click)="show(item)" pButton label="+" ></button>
                        </td>


                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="9">No Data found.</td>
                    </tr>
                </ng-template>
            </p-table>




            <p-paginator [rows]="10" [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" [totalRecords]="totalRecords"
                (onPageChange)="paginate($event)"></p-paginator>

        </div>
    </div>

</div>