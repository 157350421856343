import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';
import { IpagedQuery } from 'src/app/model/IpagedQuery';

@Injectable({
  providedIn: 'root'
})
export class ManageOvertimeUsersService {

  constructor(
    private http: HttpClient
  ) { }

  getCurrentOvertimeUsers(query:IpagedQuery, clientId?:any): Observable<any>{
    var params = new HttpParams({
      fromObject:{
        isActive:query.isActive.valueOf(),
        page:query.page.toString(),
        size:query.size.toString(),
        sort:'createdDate,desc',
      }
    })
    if(clientId)
      {
        params = params.append("user.clients.uuid",clientId)
      }
    return this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/overTime`, {params});
  }

  saveOvertimeUsers(userId: any): Observable<any>{
    let data = {
      userId: userId
    };
    return this.http.post(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/overTime`, data);
  }

  deleteOvertimeUsers(userId: any): Observable<any>{
    return this.http.delete(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/overTime/${userId}`);
  }

}
