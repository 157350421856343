import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';

@Injectable({
  providedIn: 'root'
})
export class AddFleetService {

  constructor(private http: HttpClient) { }

  saveFleet(fleetObj): Observable<any> {
    return this.http.post(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/fleets`,fleetObj);
  }

  updateFleet(fleetId,fleetObj): Observable<any> {
    return this.http.put(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/fleets/${fleetId}`,fleetObj);
  }

  getFleetById(fleetId):Observable<any>{
    return this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/fleets/${fleetId}`);
  }

  deleteFleet(fleetId): Observable<any> {
    return this.http.delete(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/fleets/${fleetId}`);
  }

  deactivateFleet(fleetId): Observable<any> {
    return this.http.patch(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/fleets/${fleetId}/deactivate`,null);
  }

  getCarrier(carrier):Observable<any>{
    return carrier ? this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/carrier/${carrier}`) : this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/carrier`);
  }
  
}
