import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AppAlertService } from 'src/app/app-alert/service/app-alert.service';
import { AppLoaderService } from 'src/app/app-loader/service/app-loader.service';
import { ErrorService } from 'src/app/error-handling/error.service';
import { AddLocationService } from './add-location.service';
import { TokenService } from 'src/app/security/token.service';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';
import { ClientConfig } from 'src/app/model/ClientConfig';

@Component({
  selector: 'app-add-location',
  templateUrl: './add-location.component.html',
  styleUrls: ['./add-location.component.scss']
})
export class AddLocationComponent implements OnInit, OnDestroy {

  selectedState:any;
  breadcrumbItems: MenuItem[];
  @ViewChild('search')
  public searchElementRef: ElementRef;
  // private geoCoder;
  stateList = [
    { name: 'Alabama', code: 'AL' },
    { name: 'Alaska', code: 'AK' },
    { name: 'Arizona', code: 'AZ' },
    { name: 'Arkansas', code: 'AR' },
    { name: 'California', code: 'CA' },
    { name: 'Colorado', code: 'CO' },
    { name: 'Connecticut', code: 'CT' },
    { name: 'Delaware', code: 'DE' },
    { name: 'District Of Columbia', code: 'DC' },
    { name: 'Florida', code: 'FL' },
    { name: 'Georgia', code: 'GA' },
    { name: 'Hawaii', code: 'HI' },
    { name: 'Idaho', code: 'ID' },
    { name: 'Illinois', code: 'IL' },
    { name: 'Indiana', code: 'IN' },
    { name: 'Iowa', code: 'IA' },
    { name: 'Kansas', code: 'KS' },
    { name: 'Kentucky', code: 'KY' },
    { name: 'Louisiana', code: 'LA' },
    { name: 'Maine', code: 'ME' },
    { name: 'Maryland', code: 'MD' },
    { name: 'Massachusetts', code: 'MA' },
    { name: 'Michigan', code: 'MI' },
    { name: 'Minnesota', code: 'MN' },
    { name: 'Mississippi', code: 'MS' },
    { name: 'Missouri', code: 'MO' },
    { name: 'Montana', code: 'MT' },
    { name: 'Nebraska', code: 'NE' },
    { name: 'Nevada', code: 'NV' },
    { name: 'New Hampshire', code: 'NH' },
    { name: 'New Jersey', code: 'NJ' },
    { name: 'New Mexico', code: 'NM' },
    { name: 'New York', code: 'NY' },
    { name: 'North Carolina', code: 'NC' },
    { name: 'North Dakota', code: 'ND' },
    { name: 'Ohio', code: 'OH' },
    { name: 'Oklahoma', code: 'OK' },
    { name: 'Oregon', code: 'OR' },
    { name: 'Pennsylvania', code: 'PA' },
    { name: 'Rhode Island', code: 'RI' },
    { name: 'South Carolina', code: 'SC' },
    { name: 'South Dakota', code: 'SD' },
    { name: 'Tennessee', code: 'TN' },
    { name: 'Texas', code: 'TX' },
    { name: 'Utah', code: 'UT' },
    { name: 'Vermont', code: 'VT' },
    { name: 'Virginia', code: 'VA' },
    { name: 'Washington', code: 'WA' },
    { name: 'West Virginia', code: 'WV' },
    { name: 'Wisconsin', code: 'WI' },
    { name: 'Wyoming', code: 'WY' },
  ];
  locationForm: FormGroup;
  clientId: any;
  subscription = new Subscription();
  locationId: any;
  clientName: any;
  fromDetails: any;
  location: any;
  userRoles: any;
  isAdmin: boolean = false;
  clientConfig: any;
  isTrailerAudit: boolean;

  constructor(
    private loader:AppLoaderService,
    private fb:FormBuilder,
    private activatedRoute:ActivatedRoute,
    private router:Router,
    private addLocationService:AddLocationService,
    private alertService:AppAlertService,
    private errorService:ErrorService,
    private tokenService:TokenService,
  ) {
    this.locationForm = this.fb.group({
      locationName: ['',Validators.required],
      latitude: [0,Validators.required],
      longitude: [0,Validators.required],
      remarks: [''],
      street: ['',Validators.required],
      city: ['',Validators.required],
      country: ['USA',Validators.required],
      state: ['',Validators.required],
      zip: ['',Validators.required],
      locationMapJson: [''],
      mapImageBase64: [''],
      pieChartColor:['']
    });

    this.activatedRoute.queryParams.subscribe(qparams=>{
      if(qparams["clientId"]){
          this.clientId = qparams["clientId"];
      }
      if(qparams["locationId"]){
        this.locationId = qparams["locationId"];
      }
      if(qparams["clientName"]){
        this.clientName = qparams["clientName"];
      }
      if(qparams["fromDetails"]){
        this.fromDetails = qparams["fromDetails"];
      }
    })
  }

  ngOnInit(): void {
   

    this.breadcrumbItems = [];
      this.breadcrumbItems.push({ label: 'Clients',routerLink:'../manage-clients'});
      this.breadcrumbItems.push({ label: 'Locations',routerLink:'../manage-locations', queryParams:{clientName:this.clientName,clientId:this.clientId} });
      this.breadcrumbItems.push({ label: 'Add Location'});

      this.userRoles = this.tokenService.getUserRoles();
      if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_ADMIN)){
          this.isAdmin = true;
      }
      this.getClientConfig ();
    //   this.mapsAPILoader.load().then(() => {
    //     this.geoCoder = new google.maps.Geocoder;
    //     const options = {
    //       componentRestrictions: { country: "us" }
    //     };
    //     let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement,options);
    //     autocomplete.addListener("place_changed", () => {
    //       this.ngZone.run(() => {
    //         this.loader.show();
    //         var place: google.maps.places.PlaceResult = autocomplete.getPlace();
    //         if (place.geometry === undefined || place.geometry === null) {
    //           return;
    //         }

    //         this.locationForm.patchValue({
    //           latitude:place.geometry.location.lat(),
    //           longitude: place.geometry.location.lng()
    //         })
    //       });

    //       const place = autocomplete.getPlace();
    //       let address1 = "";
    //       let postcode = "";

    //       for (const component of place.address_components) {
    //         const componentType = component.types[0];

    //         switch (componentType) {
    //           case "street_number": {
    //             address1 = `${component.long_name} ${address1}`;
    //             break;
    //           }

    //           case "route": {
    //             address1 += component.short_name;
    //             break;
    //           }

    //           case "postal_code": {
    //             postcode = `${component.long_name}${postcode}`;
    //             break;
    //           }

    //           case "postal_code_suffix": {
    //             postcode = `${postcode}-${component.long_name}`;
    //             break;
    //           }
    //           case "locality":
    //             this.locationForm.patchValue({
    //               city:component.long_name
    //             });
    //             break;

    //           case "administrative_area_level_1": {
    //             this.locationForm.patchValue({
    //               state:component.short_name
    //             })
    //             break;
    //           }
    //           case "country":
    //             this.locationForm.patchValue({
    //               country:component.short_name
    //             })
    //           break;
    //         }
    //       }

    //       this.locationForm.patchValue({
    //         street:address1,
    //         zip:postcode
    //       })

    //       this.loader.hide();

    //     })
    // });
    
    if(this.locationId){
      this.getLocationById(this.clientId,this.locationId)
    }
  }

  getLocationById(clientId,locationId){
    this.loader.show();
    this.subscription.add(
      this.addLocationService.getLocationById(clientId,locationId).subscribe(res=>{
        this.location = res;
        this.locationForm.patchValue(res);
        console.log(this.locationForm)
        console.log(this.location)
        // if(res.mapImageUrl){
        //   this.locationForm.patchValue({
        //     mapImageBase64 : this.UrlToBase64Image(res.mapImageUrl)
        //   })
        // }
        this.loader.hide();
      },(error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
    })
    )
  }

  onSubmit(){
    if(this.locationForm.invalid){
      this.locationForm.markAllAsTouched();
    }else{
      this.loader.show();
        if(this.locationId){
          this.addLocationService.updateLocation(this.clientId,this.locationId,this.locationForm.value).subscribe(res=>{
            this.loader.hide();
            this.alertService.alertSuccess(['Location Updated Successfully']);
            if(!this.fromDetails){
              this.router.navigate(['main/manage-locations'],{queryParams:{clientId:this.clientId,clientName:this.clientName}})
            }else{
              this.router.navigate(['/main/client-details'],{queryParams:{clientId:this.clientId}});
            }
          },(error) => {
            this.loader.hide();
            this.errorService.handleError(error, true);
        })
        }else{
          this.addLocationService.saveLocation(this.clientId,this.locationForm.value).subscribe(res=>{
            this.loader.hide();
            this.alertService.alertSuccess(['Location Added Successfully']);
            if(!this.fromDetails){
              this.router.navigate(['main/manage-locations'],{queryParams:{clientId:this.clientId,clientName:this.clientName}})
            }else{
              this.router.navigate(['/main/client-details'],{queryParams:{clientId:this.clientId}});
            }
          },(error) => {
            this.loader.hide();
            this.errorService.handleError(error, true);
        })
        }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async onImageUpload(files:FileList){
    let imageBase64 =  await this.fileToBase64(files.item(0));
    this.locationForm.patchValue({
      mapImageBase64 : imageBase64
    });
  }

  fileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  UrlToBase64Image(img) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

  removeLocationImage(){
    this.loader.show();
    
    this.addLocationService.removeLocationImage(this.clientId, this.locationId, this.locationForm.value).subscribe(res=>{
      this.loader.hide();
      this.location.mapImageUrl = "";
      this.locationForm.patchValue({
        mapImageBase64 : ""
      });
      this.alertService.alertSuccess(['Location Image Removed Successfully']);
     
    },(error) => {
      this.loader.hide();
      this.errorService.handleError(error, true);
  })
  }

  getClientConfig()
  {
    this.addLocationService.getConfigOfClient(this.clientId).subscribe(res=>{
      this.clientConfig = res;
      this.isTrailerAudit = this.clientConfig.trailerAudit;
      console.log("TrailerAudit",this.isTrailerAudit);
    });
  }

}
