    <div class="grid">
        <div class="col-12 xl:col-6 ">
            <div class="flex-container">
                <div class="col-12 lg:col-6 xl:col-6">
                    <div class="card">
                        <div class="flex justify-content-between mb-3">
                            <div>
                                <span class="block text-orange font-medium mb-3 text-xl">In-Transit Spots</span>
                                <div (click)="routeToManageJobs('IN_TRANSIT')" class="text-800 font-medium text-xl pointer">{{jobStatistics.activeJobs}}</div>
                            </div>
                            <div class="flex align-items-center justify-content-center bg-orange border-round" [ngStyle]="{width: '3.5rem', height: '3.5rem'}">
                                <img src="assets/dashboard/active-jobs.svg" alt="">
                            </div>
                        </div>
                    </div>
    
    
                    
                </div>
    
                <div class="col-12 lg:col-6 xl:col-6">
                    <div class="card">
                        <div class="flex justify-content-between mb-3">
                            <div>
                                <span class="block text-grey font-medium mb-3 text-xl">Open Spots</span>
                                <div (click)="routeToManageJobs('OPEN')" class="text-800 font-medium text-xl pointer">{{jobStatistics.pendingJobs}}</div>
                            </div>
                            <div class="flex align-items-center justify-content-center bg-grey border-round" [ngStyle]="{width: '3.5rem', height: '3.5rem'}">
                                <img src="assets/dashboard/pending-jobs.svg" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex-container">
                <div class="col-12 lg:col-6 xl:col-6">
                    <div class="card">
                        <div class="flex justify-content-between mb-3">
                            <div>
                                <span class="block text-green font-medium mb-3 text-xl">Completed Spots(24 hrs)</span>
                                <div (click)="routeToManageJobs('COMPLETED')" class="text-800 font-medium text-xl pointer">{{jobStatistics.completedJobsToday}}</div>
                            </div>
                            <div class="flex align-items-center justify-content-center bg-green border-round" [ngStyle]="{width: '3.5rem', height: '3.5rem'}">
                                <img src="assets/dashboard/completed-jobs.svg" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 lg:col-6 xl:col-6">
                    <div class="card">
                        <div class="flex justify-content-between mb-3">
                            <div>
                                <span class="block text-blue font-medium mb-3 text-xl">Completed Spots(1 Month)</span>
                                <div (click)="routeToManageJobs('COMPLETED')" class="text-800 font-medium text-xl pointer">{{jobStatistics.completedJobsOneMonth}}</div>
                            </div>
                            <div class="flex align-items-center justify-content-center bg-blue border-round" [ngStyle]="{width: '3.5rem', height: '3.5rem'}">
                                <img src="assets/dashboard/completed-jobs.svg" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex-container">
                <div class="col-12 lg:col-6 xl:col-6">
                    <div class="card">
                        <div class="flex justify-content-between mb-3">
                            <div>
                                <span class="block text-purple font-medium mb-3 text-xl">Clients</span>
                                <div [routerLink]="['/main/manage-clients']" class="text-800 font-medium text-xl pointer">{{generalStatistics.totalClients}}</div>
                            </div>
                            <div class="flex align-items-center justify-content-center bg-purple border-round" [ngStyle]="{width: '3.5rem', height: '3.5rem'}">
                                <img src="assets/dashboard/clients.svg" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 lg:col-6 xl:col-6">
                    <div class="card">
                        <div class="flex justify-content-between mb-3">
                            <div>
                                <span class="block text-brown font-medium mb-3 text-xl">Trailer/Truck Inventory</span>
                                <div [routerLink]="['/main/manage-fleets']" class="text-800 font-medium text-xl pointer">{{generalStatistics.totalTrailers + generalStatistics.totalTrucks}}</div>
                            </div>
                            <div class="flex align-items-center justify-content-center bg-brown border-round" [ngStyle]="{width: '3.5rem', height: '3.5rem'}">
                                <img src="assets/dashboard/active-jobs.svg" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <div class="flex-container">

            <div *ngIf="userType !== 'ROLE_CLIENT'" class="col-12 lg:col-6 xl:col-6">
                <div class="card">
                    <div class="flex justify-content-between mb-3">
                        <div>
                            <span class="block text-orange-dark font-medium mb-3 text-xl">Users</span>
                            <div [routerLink]="['/main/manage-users']" class="text-800 font-medium text-xl pointer">{{generalStatistics.totalUsers}}</div>
                        </div>
                        <div class="flex align-items-center justify-content-center bg-orange-dark border-round" [ngStyle]="{width: '3.5rem', height: '3.5rem'}">
                            <img src="assets/dashboard/users.svg" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 lg:col-6 xl:col-6">
                <div class="card">
                    <div class="flex justify-content-between mb-3">
                        <div>
                            <span class="block text-cyan font-medium mb-3 text-xl">Unread Messages</span>
                            <div [routerLink]="['/main/manage-messages']" class="text-800 font-medium text-xl pointer">{{messageStatistics.newCount}}</div>
                        </div>
                        <div *ngIf="unreadMessage=== false" class="flex align-items-center justify-content-center bg-cyan border-round" [ngStyle]="{width: '3.5rem', height: '3.5rem'}">
                            <!-- <img src="assets/dashboard/messages.svg" alt="" >  -->
                            
                            <i class="pi pi-envelope p-white" style="font-size: 1.5rem" severity="danger"></i>
                        </div>
                        <div *ngIf="unreadMessage === true" class="flex align-items-center justify-content-center bg-cyan border-round" [ngStyle]="{width: '3.5rem', height: '3.5rem'}">
                            <!-- <img src="assets/dashboard/messages.svg" alt="" >  -->
                            
                            <i class="pi pi-envelope p-white" pBadge  style="font-size: 1.5rem" severity="danger"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>           
        <div class="col-12 xl:col-6">
        
            <div class="card flex flex-column justify-content-between mt-2 custom-card">
                <div class="flex flex-row justify-content-between mb-4">
                    <h5 style="align-self: flex-start;">Spots {{daysLabel}}</h5>
                    <div>
                        <div *ngIf="userType !== 'ROLE_CLIENT'" class="field">
                            <div style="margin-bottom: 4px;display: flex;flex-direction: column;">
                               <label htmlFor="clients" class="mr-2">Client</label>
                               <p-dropdown [options]="clients" placeholder="Select Client" [showClear]="true" (onChange)="getClientJobs($event)" optionLabel="clientName" optionValue="clientId"></p-dropdown>
                            </div>
                        </div>
                        <div style="display: flex;flex-direction: column;">
                            <label htmlFor="days" class="mr-3">Duration</label>
                            <p-dropdown [options]="days" placeholder="Select Duration" (onChange)="selectDays($event)" optionLabel="label" optionValue="value"></p-dropdown>
                        </div>
                    </div>  
                </div>
                <div class="flex justify-content-center">
                    <p-chart type="pie" [data]="pieData" [options]="pieOptions" [style]="{'width': '90%','max-width':'450px'}"></p-chart>
                </div>
                
            </div>
        </div>
 </div>
            



                




           









                

           

               
           


            

               

            



            
                
        
        

        

        <!-- <div class="col-12 lg:col-6">
    
            <div class="card flex flex-column">
                <div class="flex flex-row justify-content-between mb-4">
                    <h5 style="align-self: flex-start;">Spots (Last 30 Days)</h5>
                    <div *ngIf="userType !== 'ROLE_CLIENT'" class="field">
                        <label htmlFor="clients" class="mr-2">Client</label>
                        <p-dropdown [options]="clients" placeholder="Select Client" (onChange)="getClientJobs($event,'month')" optionLabel="clientName" optionValue="clientId"></p-dropdown>
                    </div>
                </div>
                <div class="flex justify-content-center">
                    <p-chart type="pie" [data]="pieData1" [options]="pieOptions1" [style]="{'width': '50%'}"></p-chart>
                </div>
                
            </div>
        </div> -->

   

