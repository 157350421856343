import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';

@Injectable({
  providedIn: 'root'
})
export class UserAvailabilityService {

  constructor(
    private http: HttpClient
  ) { }

  getUserAvailability(userId: any): Observable<any>{
    return this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/userAvailability/${userId}`);
  }

  saveUserAvailability(data: any): Observable<any>{
    return this.http.post(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/userAvailability`,data);
  }

  deleteUserAvailability(userAvailabilityId: any): Observable<any>{
    return this.http.delete(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/userAvailability/${userAvailabilityId}`);
  }

  getUserAvailabilityException(userId: any): Observable<any>{
    return this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/userAvailabilityException/${userId}`);
  }

  saveNewAvailabilityException(data: any): Observable<any>{
    return this.http.post(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/userAvailabilityException`,data);
  }

  saveAvailabilityActive(data: any, id: any): Observable<any>{
    return this.http.put(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/userAvailability/${id}`,data);
  }

  deleteAvailabilityException(userAvailabilityExceptionId: any): Observable<any>{
    return this.http.delete(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/userAvailabilityException/${userAvailabilityExceptionId}`);
  }
}

