import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';
import { IpagedQuery } from 'src/app/model/IpagedQuery';

@Injectable({
  providedIn: 'root'
})
export class ManageUsersService {

  constructor(private http: HttpClient) { }

 

  viewUsers(query:IpagedQuery,clientId?:any,firstName?:string,roleName?:string): Observable<any> {
    var params = new HttpParams({
      fromObject:{
        isActive:query.isActive.valueOf(),
        page:query.page.toString(),
        size:query.size.toString(),
        sort:'createdDate,desc',
      }
    })

    if(roleName)
    {
      params = params.append("roleName",roleName);
    }

    if(firstName)
    {
      params = params.append("firstName",firstName);
    }
   
    return clientId ? this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/clients/${clientId}/users`,{params}) : this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/users`,{params});
  }
  
  resetpassword(userId : any):Observable<any>{
    return this.http.patch(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/users/${userId}/resetPassword`,null);
  }

  activateInactiveUser(userId:string):Observable<any>{
    return this.http.post(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/users/inactive`,{userId});
  }
}
