<div class="grid">
    <div class="col-12">
        <div class="card card-w-title">
            <p-breadcrumb [model]="breadcrumbItems" [home]="{icon: 'pi pi-home',routerLink:'../'}"></p-breadcrumb>
        </div>
    </div>
    <div class="col-12">
        <div class="card">
            <p-toast></p-toast>
            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="left">
                    <div class="my-2">
                        <h5 class="m-0">Trailer Audit</h5>
                    </div>
                </ng-template>
               
                <ng-template pTemplate="right">
                    <button *ngIf="userType === 'ROLE_SUPERVISOR'" pButton pRipple label="New" icon="pi pi-plus" class="p-button-success mr-2" (click)="routeToAddTrailerAudit()"></button>
                    <p-splitButton label="Export" [model]="items" (onClick)="exportPdf()" styleClass="p-button-raised p-button-help mr-2 "></p-splitButton>
                        
                </ng-template>
            </p-toolbar>



                <p-table #dt [value]="trailerAudit" [loading]="loading" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" [responsive]="true" [rows]="50" [rowHover]="true" dataKey="id">
                    <ng-template pTemplate="caption">
                         <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <span></span>
                             <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <span class="mr-2 font-medium">From Date : <input pInputText type="date" [(ngModel)]="fromDate" (change)="filterJobsByDate()" name="fromDate" placeholder="Select Date" /></span> 
                                <span class="mx-2 font-medium">To Date : <input pInputText type="date" [(ngModel)]="toDate" (change)="filterJobsByDate()" name="toDate" placeholder="Select Date" /></span>
                            </span> 
                        </div> 
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            
                            <th pSortableColumn="area">Area</th>
                            <th pSortableColumn="slot">Slot</th>
                            <th pSortableColumn="carrier">Carrier</th>
                            <th pSortableColumn="trailer#">Trailer#</th>
                            <th pSortableColumn="trailerStatus">Trailer Status</th>
                            <th pSortableColumn="notes">Notes</th>
                            <th pSortableColumn="dateAndTime">Date and Time Entered</th>
                            
                        </tr>
                    
                    
                    </ng-template>

                    <ng-template pTemplate="body" let-trailer>
                        <tr>
                            <td>
                                <span class="p-column-title">Area</span>
                            {{trailer.area}} 
                            </td>

                            <td>
                                <span class="p-column-title">Slot</span>
                            {{trailer.slot}} 
                            </td>

                            <td>
                                <span class="p-column-title">Carrier</span>
                            {{trailer.carrier}} 
                            </td>

                            <td>
                                <span class="p-column-title">Trailer#</span>
                            {{trailer.trailerNumber}} 
                            </td> 
                            <td>
                                <span class="p-column-title">Trailer Status</span>
                            {{trailer.trailerStatus}} 
                            </td>
                             <td>
                                <span class="p-column-title">Notes</span>
                            {{trailer.notes ? trailer.notes : '-'}} 
                            </td>
                            <td>
                                <span class="p-column-title">Date And Time Entered</span>
                            {{trailer.audit.createdDate}} 
                            </td>
                        </tr>
                    </ng-template>

                </p-table>
                <p-paginator [rows]="50" [showCurrentPageReport] = "true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" [totalRecords]="totalRecords"  (onPageChange)="paginate($event)" #paginator></p-paginator>
     

        </div>  
    </div>
</div>
