import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RedirectPageService } from './redirect-page.service';
import { APP_CONSTANTS } from "src/app/constants/app.constants";
import { TokenService } from 'src/app/security/token.service';
import { AppLoaderService } from 'src/app/app-loader/service/app-loader.service';
import { ErrorService } from 'src/app/error-handling/error.service';

@Component({
  selector: 'app-redirect-page',
  templateUrl: './redirect-page.component.html',
  styleUrls: ['./redirect-page.component.scss']
})
export class RedirectPageComponent implements OnInit {

  token:string;
  isLogin: boolean = false;


  constructor(private route: ActivatedRoute,
             private redirectPageService:RedirectPageService,
             private tokenService: TokenService,
             private loader: AppLoaderService,
             private router: Router,
             private errorService: ErrorService
  ) { }

  ngOnInit(): void {
    this.loader.show();
    this.route.queryParams.subscribe(params => {
      debugger
      this.token =  atob(params['token']) ;
     
    });
    console.log("token",this.token);
   this.loginSsoUser(this.token)
  }


  loginSsoUser(token)
  {
    // this.redirectPageService.loginSsoUser(temporaryId)
    // .subscribe(
    //   (token) => {
       console.log("token",token);

        debugger
        // Depending on the role redirect user to appropriate landing page
       const authorities = JSON.parse(token)["roles"];
        // const authorities = ["ROLE_SUPERVISOR"];
        console.log("authorities",authorities);
        if (
          authorities.some(
            (role) =>
              role === APP_CONSTANTS.USER_ROLES.ROLE_IT ||
              role === APP_CONSTANTS.USER_ROLES.ROLE_CLIENT ||
              role === APP_CONSTANTS.USER_ROLES.ROLE_ADMIN ||
              role === APP_CONSTANTS.USER_ROLES.ROLE_SUPERVISOR
          )
        ) {
          console.log("if");
          // Save token in local storage
          this.tokenService.saveToken(token);
          this.isLogin = true;
          setTimeout(() => {
            console.log("sleep");
            this.router.navigate(["main"]);
            // And any other code that should run only after 5s
          }, 2000);
        } else if (
          authorities.some(
            (role) =>
              role === APP_CONSTANTS.USER_ROLES.ROLE_DRIVER ||
              role === APP_CONSTANTS.USER_ROLES.ROLE_SPOTTER ||
              role === APP_CONSTANTS.USER_ROLES.ROLE_GUARD
          )
        ) {
          this.tokenService.saveToken(token);
          this.isLogin = true;
          setTimeout(() => {
            console.log("sleep");
            this.router.navigate(["main/manage-clients"]);
            // And any other code that should run only after 5s
          }, 2000);
        }
        this.loader.hide();
  //     },
  //     (error) => {
  //       console.log("error")
  //       this.loader.hide();
  //       this.errorService.handleError(error, true);
  //       this.router.navigate(["login"]);
  //     }
  //   );
 }
}
