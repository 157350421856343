import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';
import { IpagedQuery } from 'src/app/model/IpagedQuery';

@Injectable({
  providedIn: 'root'
})
export class ManageTrailerAuditService {

  constructor(private http: HttpClient) { }


  viewTrailerAudit(query:IpagedQuery, from_date:any, to_date: any): Observable<any>
  {
    var params = new HttpParams({
      fromObject:{
        isActive:query.isActive.valueOf(),
        page:query.page.toString(),
        size:query.size.toString(),
        sort:'createdDate,desc',
        isWeb: true
        
      }
    })

    if(from_date){
      params = params.append("fromDate",from_date.toString())
    }

    if(to_date){
      params = params.append("toDate",to_date.toString())
    }

     return this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/trailerAudit`,{params});

  }

}
