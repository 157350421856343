import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-user-terms',
  templateUrl: './user-terms.component.html',
  styleUrls: ['./user-terms.component.scss']
})
export class UserTermsComponent implements OnInit {
 
  breadcrumbItems: MenuItem[];
  constructor() { }

  ngOnInit(): void {


    this.breadcrumbItems = [];
    this.breadcrumbItems.push({ label: 'Terms And Conditions'});
  }

}
