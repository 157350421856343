import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthenticationService} from './authentication.service';
import {AuthorizationService} from './authorization.service';
import {AppAlertService} from '../app-alert/service/app-alert.service';
import { Observable } from 'rxjs';
import { TokenService } from './token.service';

/**
 * Authentication/Authorization related guard to activate or deactivate routes
 */
@Injectable()
export class AuthGuard implements CanActivate {

  constructor(public authenticationService: AuthenticationService,
              public router: Router,
              private location: Location,
              private authorizationService: AuthorizationService,
              private alertService: AppAlertService,
              private tokenService:TokenService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (this.authenticationService.isAuthenticated()) {

      
        const requiredRoles = next.data.roles as Array<string>;
        
        if (requiredRoles && requiredRoles.length > 0) {
          const userRoles = this.tokenService.getUserRoles();
           
          // Check if the user has at least one of the required roles
          if (userRoles.some(role => requiredRoles.includes(role))) {
            return true;
          } else {
            // User does not have the required role, redirect or show an error
            //this.alertService.alertError('You do not have permission to access this page.');
            this.router.navigate(['/main']); // Redirect to a suitable page
            return false;
          }
        }
  



        return true;
        }
      else {
          this.router.navigate(['']);
          return false;
        }

  }
}
