<div class="grid">
    <div class="col-12">
        <div class="card card-w-title">
            <p-breadcrumb [model]="breadcrumbItems" [home]="{icon: 'pi pi-home',routerLink:'../'}"></p-breadcrumb>
        </div>
    </div>
    <div class="col-12">
        <div class="card">
            <p-toast></p-toast>
            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="left">
                    <div class="my-2">
                        <h5 class="m-0">Locations ({{clientName}})</h5>
                    </div>
                </ng-template>

                <ng-template pTemplate="right">
                    <button *ngIf="userType !== 'ROLE_CLIENT' && hideButtonsIfDriver !== true && hideButtonsIfSupervisor === false && hideButtonsIfGuard === false && hideButtonsIfSpotter === false" pButton pRipple label="New" icon="pi pi-plus" class="p-button-success mr-2" (click)="routeToAddLocation()"></button>
                    <!-- <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help" (click)="exportExcel()"></button>
                 -->
                 <p-splitButton label="Export" [model]="items" (onClick)="exportExcel()" styleClass="p-button-raised p-button-help mr-2 "></p-splitButton>
                     
                </ng-template>
            </p-toolbar>

            <p-table #dt [value]="locationList" [loading]="loading" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" [responsive]="true" [columns]="cols" [rows]="10" [globalFilterFields]="['locationName']" [rowHover]="true" dataKey="id">
                <ng-template pTemplate="caption">
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <span></span>
                        <span class="block mt-2 md:mt-0 p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th></th>
                        <th pSortableColumn="locationName">Name</th>
                        <th pSortableColumn="address">Address</th>
                        <th></th>
                        <!-- <th pSortableColumn="latitude">Latitude</th>
                        <th pSortableColumn="longitude">Longitude</th> -->
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-location>
                    <tr>
                        <td>
                            <button *ngIf="location.isDefault !== true && hideButtonsIfDriver !== true && hideButtonsIfSupervisor === false && hideButtonsIfGuard === false && hideButtonsIfSpotter === false" pButton pRipple icon="pi pi-bookmark" pTooltip="Make Default" class="p-button-rounded p-button-secondary mr-2" (click)="makeDefault(clientId,location.locationId)"></button>
                            <button pButton pRipple icon="pi pi-eye" pTooltip="View" class="p-button-rounded p-button-success mr-2" (click)="routeToDetails(clientId,location.locationId)"></button>
                            <button pButton pRipple icon="pi pi-compass" pTooltip="Docks/Parking Spot" class="p-button-rounded p-button-help mr-2" (click)="routeToSpots(location.locationId,location.locationName)"></button>
                            <!-- <button pButton pRipple icon="pi pi-plus" pTooltip="Create Entry/Exit" class="p-button-rounded p-button-secondary mr-2" (click)="openEntryExitDialog(location.locationId)"></button> -->
                            <button *ngIf="userType !== 'ROLE_CLIENT' && hideButtonsIfDriver !== true && hideButtonsIfSupervisor === false && hideButtonsIfGuard === false && hideButtonsIfSpotter === false" pButton pRipple icon="pi pi-pencil" pTooltip="Edit" class="p-button-rounded p-button-warning mr-2" (click)="routeToEditLocation(location.locationId)"></button>
                            <button *ngIf="userType !== 'ROLE_CLIENT' && hideButtonsIfDriver !== true && hideButtonsIfSupervisor === false && hideButtonsIfGuard === false && hideButtonsIfSpotter === false" pButton pRipple icon="pi pi-trash" pTooltip="Delete" class="p-button-rounded p-button-danger" (click)="deleteProduct(location)"></button>
                        </td>
                        <td><span class="p-column-title">Name</span>
                            {{location.locationName}}
                        </td>
                        <td>
                            <span class="p-column-title">Address</span>
                            {{location.street}}, {{location.city}}, {{location.state}}
                        </td>
                        <td>
                            <p-tag *ngIf="location.isDefault == true" class="tag-hover" severity="success" value="Default Location" icon="pi pi-bookmark"></p-tag>
                        </td>
                        <!-- <td><span class="p-column-title">Latitude</span>
                            {{location.latitude}}
                        </td>
                        <td><span class="p-column-title">Longitude</span>
                            {{location.longitude}}
                        </td> -->
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="5">No Location found.</td>
                    </tr>
                </ng-template>
            </p-table>
            <p-paginator [rows]="10" [showCurrentPageReport] = "true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" [totalRecords]="totalRecords"  (onPageChange)="paginate($event)"></p-paginator>
        </div>


        <p-dialog [(visible)]="entryExitDialog" [style]="{width: '450px'}" header="Create Entry/Exit Record" [modal]="true" class="p-fluid">
            <ng-template pTemplate="content">
                <form [formGroup]="entryExitForm">
                    <div class="field">
                        <label for="fleet">Trailer/Container</label>
                        <p-dropdown [options]="fleetList" placeholder="Select Fleet" formControlName="fleetId" optionLabel="plateNumber_unitNumber" optionValue="fleetId"></p-dropdown>
                        <small class="ng-dirty ng-invalid" *ngIf="entryExitForm.controls.fleetId.touched &&
                        entryExitForm.controls.fleetId.invalid">Trailer/Container is required.</small>
                    </div>
                    <div class="field">
                        <label for="type">Type</label>
                        <p-dropdown [options]="type" placeholder="Select Type" formControlName="type" optionLabel="name" optionValue="code"></p-dropdown>
                        <small class="ng-dirty ng-invalid" *ngIf="entryExitForm.controls.type.touched &&
                        entryExitForm.controls.type.invalid">Type is required.</small>
                    </div>
                    <div class="field">
                        <label for="notes">Notes</label>
                        <textarea pInputTextarea autoResize formControlName="notes" rows="3" cols="30"></textarea>
                    </div>
                </form>
            </ng-template>

            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
                <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" type="button" (click)="createEntryExit()"></button>
            </ng-template>
        </p-dialog>

        <p-dialog [(visible)]="deleteLocationDialog" header="Confirm" [modal]="true" [style]="{width:'450px'}">
            <div class="flex align-items-center justify-content-center">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
                <span *ngIf="location">Are you sure you want to delete <b>{{location.locationName}}</b>?</span>
            </div>
            <ng-template pTemplate="footer">
                <button pButton pRipple icon="pi pi-times" class="p-button-text" label="No" (click)="deleteLocationDialog = false"></button>
                <button pButton pRipple icon="pi pi-check" class="p-button-text" label="Yes" (click)="confirmDelete(location.locationId)"></button>
            </ng-template>
        </p-dialog>
    </div>
</div>
