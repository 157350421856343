import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';

@Injectable({
  providedIn: 'root'
})
export class AddLocationService {

  constructor(private http: HttpClient) { }

  saveLocation(clientId,locationObj): Observable<any> {
    return this.http.post(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/clients/${clientId}/locations`,locationObj);
  }

  updateLocation(clientId,locationId,locationObj): Observable<any> {
    return this.http.put(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/clients/${clientId}/locations/${locationId}`,locationObj);
  }

  getLocationById(clientId,locationId):Observable<any>{
    return this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/clients/${clientId}/locations/${locationId}`);
  }

  removeLocationImage(clientId,locationId,locationObj): Observable<any> {
    return this.http.delete(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/clients/${clientId}/locations/${locationId}/removeLocationImage`);
  }

  getConfigOfClient(clientId){
     return this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/clients/${clientId}/clientConfig`)
  }

}
