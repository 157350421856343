import { Component, OnInit } from '@angular/core';
import { TermsService } from './terms.service';
import { Router } from '@angular/router';
import { IpagedQuery } from 'src/app/model/IpagedQuery';
import { Subscription } from 'rxjs';
import { AppLoaderService } from 'src/app/app-loader/service/app-loader.service';
import { ErrorService } from 'src/app/error-handling/error.service';
import { toBase64String } from '@angular/compiler/src/output/source_map';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  terms:string;
  query:IpagedQuery;
  subscription = new Subscription();

  constructor(private termsService:TermsService,
              private router:Router,
              private loader:AppLoaderService,
              private errorService:ErrorService,
              
              ) { }
  
  ngOnInit(): void {
    
  }


  

}
