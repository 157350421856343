import {ErrorHandler, Injectable} from '@angular/core';
import { ErrorService } from './error.service';

/**
 * Custom Error handler
 * Not being used right now but can be
 */
@Injectable()
export class AppErrorHandler implements ErrorHandler {

  constructor(private errorService:ErrorService){}

  handleError(error: any) {
    this.errorService.handleError(error,true);
    // As Error handler gets initialized before providers are setup use injector to get hold of services;
    //console.error(JSON.stringify(error));
  }
}
