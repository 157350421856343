import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';

@Injectable({
  providedIn: 'root'
})
export class OverviewService {

  constructor(private http: HttpClient) { }

  viewDetails(type: any, query: any): Observable<any> {
    var params = new HttpParams({
      fromObject: {
        page: query.page.toString(),
        size: query.size.toString(),

      }
    })
    return this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/overview/trailers/type/` + type, { params });
  }

  filterCarrier(data: any, query: any): Observable<any> {
    // var params = new HttpParams({
    //   fromObject:{
    //     includeCarriers: data.includeCarriers,
    //     excludeCarriers: data.excludeCarriers.length >0 ? data.excludeCarriers : [],
    //     page:query.page.toString(),
    //     size:query.size.toString(),

    //   }
    // })
    return this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/overview/trailers/filter/carrier?includeCarriers=` + data.includeCarriers + `&excludeCarriers=` + data.excludeCarriers + `&page=` + query.page + `&size=10`);
  }

  search(text: any, query: any): Observable<any> {
    var params = new HttpParams({
      fromObject: {
        page: query.page.toString(),
        size: query.size.toString(),

      }
    })
    return this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/overview/trailers/search/` + text, { params });
  }

  sortTime(event: any, op: any, query): Observable<any> {
    var params = new HttpParams({
      fromObject: {
        page: query.page.toString(),
        size: query.size.toString(),
        sort: op

      }
    })
    return this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/overview/trailers/trailer/time/` + event, { params });
  }

  filterByClient(client: any, query: any): Observable<any> {
    var params = new HttpParams({
      fromObject: {
        page: query.page.toString(),
        size: query.size.toString(),

      }
    })
    return this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/overview/trailers/client/` + client, { params });
  }

  getLocations(query): Observable<any> {
    return this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/overview/trailers/locations`);
  }

  filterLocations(location: any, query: any): Observable<any> {
    var params = new HttpParams({
      fromObject: {
        page: query.page.toString(),
        size: query.size.toString(),

      }
    })
    return this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/overview/trailers/filter/location/` + location, { params });
  }
  viewDetails1(type: any, query: any, clientId?: any, carrier?: any, excludeCarrier?: any, location?: any): Observable<any> {
    var params = new HttpParams({
      fromObject: {
        page: query.page.toString(),
        size: query.size.toString(),
        clientIds: clientId?clientId:[]
      }
    })

    // if (clientId) {
    //   params = params.append("clientIds", clientId);
    // }

    if (type) {
      params = params.append("type", type.toUpperCase());
    }

    if (carrier) {
      params = params.append("includeCarriers", carrier);
    }
    console.log(excludeCarrier)
    if (excludeCarrier) {
      params = params.append("excludeCarriers", excludeCarrier);
    }

    if (location) {
      params = params.append("location", location);
    }

    return this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/overview/trailers`, { params });
  }
}
