<div class="grid">
    <div class="col-12">
        <div class="card card-w-title">
            <p-breadcrumb
                [model]="breadcrumbItems"
                [home]="{ icon: 'pi pi-home', routerLink: '../' }"
            ></p-breadcrumb>
        </div>
    </div>
    <div class="col-12">
        <div class="card">
            <h5>{{ this.clientId ? 'Edit Client' : 'Add Client'}}</h5>
            <form [formGroup]="clientForm">
                <div class="p-fluid p-formgrid grid">
                    <div class="field col-12 md:col-9">
                        <label htmlFor="clientName">Client Name<span class="text-danger">*</span></label>
                        <input
                            pInputText
                            id="clientName"
                            formControlName="clientName"
                            type="text"
                        />
                        <span
                            class="text-danger"
                            *ngIf="
                                clientForm.controls.clientName.touched &&
								clientForm.controls.clientName.invalid
                            "
                            >Client Name is required</span
                        >
                    </div>
                    <div class="field col-12 md:col-3">
                        <label htmlFor="timeZone">Time Zone<span class="text-danger">*</span></label>
                        <p-dropdown
                            [options]="timeZoneList"
                            formControlName="timeZone"
                            placeholder="Select Time Zone"
                            optionLabel="zone"
							optionValue="zone"
                        ></p-dropdown>
						<span
                            class="text-danger"
                            *ngIf="
                                clientForm.controls.timeZone.touched &&
								clientForm.controls.timeZone.invalid
                            "
                            >Time Zone is required</span
                        >
                    </div>
                    <div class="field col-12">
                        <label htmlFor="street">Street<span class="text-danger">*</span></label>
                        <input
                            pInputTextarea
                            id="street"
                            formControlName="street"
                            rows="4"
                            #search
                        />
						<span
                            class="text-danger"
                            *ngIf="
                                clientForm.controls.street.touched &&
								clientForm.controls.street.invalid
                            "
                            >Street is required</span
                        >
                    </div>
                    <div class="field col-12 md:col-6">
                        <label htmlFor="city">City<span class="text-danger">*</span></label>
                        <input
                            pInputText
                            id="city"
                            formControlName="city"
                            type="text"
                        />
						<span
                            class="text-danger"
                            *ngIf="
                                clientForm.controls.city.touched &&
								clientForm.controls.city.invalid
                            "
                            >City is required</span
                        >
                    </div>
                    <div class="field col-12 md:col-3">
                        <label htmlFor="state">State<span class="text-danger">*</span></label>
                        <p-dropdown
                            [options]="stateList"
                            formControlName="state"
                            placeholder="Select State"
                            optionLabel="name"
							optionValue="code"
                        ></p-dropdown>
						<span
                            class="text-danger"
                            *ngIf="
                                clientForm.controls.state.touched &&
								clientForm.controls.state.invalid
                            "
                            >State is required</span
                        >
                    </div>
                    <div class="field col-12 md:col-3">
                        <label htmlFor="zip">Zip Code<span class="text-danger">*</span></label>
                        <input
                            pInputText
                            id="zip"
                            formControlName="zip"
                            type="text"
                        />
						<span
                            class="text-danger"
                            *ngIf="
                                clientForm.controls.zip.touched &&
								clientForm.controls.zip.invalid
                            "
                            >Zip Code is required</span
                        >
                    </div>
                    <div class="field col-12">
                        <label htmlFor="contactPerson">Contact Person<span class="text-danger">*</span></label>
                        <input
                            pInputText
                            id="contactPerson"
                            formControlName="contactPerson"
                            type="text"
                        />
						<span
                            class="text-danger"
                            *ngIf="
                                clientForm.controls.contactPerson.touched &&
								clientForm.controls.contactPerson.invalid
                            "
                            >Contact Person is required</span
                        >
                    </div>
                    <div class="field col-12 md:col-6">
                        <label htmlFor="contactEmail">Contact Email<span class="text-danger">*</span></label>
                        <input
                            pInputText
                            id="contactEmail"
                            formControlName="contactEmail"
                            type="email"
                        />
						<span
                            class="text-danger"
                            *ngIf="
                                clientForm.controls.contactEmail.touched &&
								clientForm.controls.contactEmail.invalid
                            "
                            >Contact Email is required</span
                        >
                    </div>
                    <div class="field col-12 md:col-3">
                        <label htmlFor="contactPhone">Contact Phone</label>
                        <input
                            pInputText
                            id="contactPhone"
                            formControlName="contactPhone"
                            appPhoneMask
                            maxlength="14"
                            type="text"
                        />
						<span
                            class="text-danger"
                            *ngIf="
                                clientForm.controls.contactPhone.touched &&
								clientForm.controls.contactPhone.invalid
                            "
                            >Contact Phone is required</span
                        >
                    </div>
                    <div class="field col-12 md:col-3">
                        <label htmlFor="overTime">Over Time</label>
                        <input
                            pInputText
                            id="coverTime"
                            formControlName="overTime"
                            type="number"
                        />
						<span
                            class="text-danger"
                            *ngIf="
                                clientForm.controls.overTime.touched &&
								clientForm.controls.overTime.invalid
                            "
                            >Contact Phone is required</span
                        >
                    </div>
                   
                    <!-- <div class="field col-12">
						<label htmlFor="remarks">Remarks</label>
						<textarea pInputTextarea autoResize rows="3" cols="30"></textarea>
					</div> -->
                    <button type="button" pButton (click)="onSubmit()" label="Submit"></button>
                </div>
            </form>
        </div>
    </div>
</div>
