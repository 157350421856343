<div class="block-section" style="height: 100vh;">
	<div class="block-content" style="height: 100%;">
		<div class="pt-8 flex align-items-center justify-content-center"> 
			<img class="logo" src="../../../assets/logo/spoton_vector.svg " alt="logo"> 
		</div>
		<div *ngIf="!isLogin">
			<div class="surface-ground px-4 pt-6 md:px-6 lg:px-8 flex align-items-center justify-content-center">
				<div class="surface-card p-4  border-round w-full lg:w-6 xl:w-4">
					<!-- <div class="text-center mb-5">
						 <img src="assets/demo/images/blocks/logos/hyper.svg" alt="Image" height="50" class="mb-3"> 
						<div class="text-900 text-3xl font-medium mb-3 text-white">Login</div>
					</div> -->
					<div>
						<form [formGroup]="loginForm">  
							<!-- <label for="email" class="block text-900 mb-2 text-white">Email Address</label>  -->
							<input id="email" type="email" formControlName="userName" pInputText class="w-full mb-3 custom-input" placeholder="Email Address"> <span class="text-danger" *ngIf="loginForm.controls.userName.touched && loginForm.controls.userName.invalid">please enter valid email address</span>
							<!-- <label for="password1" class="block text-900 font-medium mb-2">Password</label> -->
							<input id="password1" type="password" formControlName="password" pInputText placeholder="Password" class="w-full mb-3 mt-4 custom-input"> <span class="text-danger" *ngIf="loginForm.controls.password.touched && loginForm.controls.password.invalid">password is required</span>
							
							<div class="flex align-items-center justify-content-center mb-4 mt-4"> <button (click)="login()" pButton pRipple label="LOG IN" class="flex w-12 p-button-rounded custom-button"></button> 
							</div>
							
							<div class="flex align-items-center justify-content-between mb-4 mt-4">
								<div class="flex align-items-center"> <p-checkbox id="rememberme1" [binary]="true" styleClass="mr-2"></p-checkbox> 
									<label for="rememberme1" class="text-white">Remember me</label> 
								</div> 
								<a class="font-medium no-underline ml-2 text-blue-500 text-white text-right cursor-pointer"
									[routerLink]="['/forgot-password']">Forgot password?</a>
							</div>
							<a [href]="ssoPageUrl" class="flex p-button-rounded p-button-secondary align-items-center justify-content-center custom-btn-secondary">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
								<g id="surface1">
								<path style=" stroke:none;fill-rule:nonzero;fill:rgb(94.509804%,31.764706%,10.588235%);fill-opacity:1;" d="M 9.503906 9.503906 L 0.00390625 9.503906 L 0.00390625 0 L 9.503906 0 Z M 9.503906 9.503906 "/>
								<path style=" stroke:none;fill-rule:nonzero;fill:rgb(50.196078%,80%,15.686275%);fill-opacity:1;" d="M 19.996094 9.503906 L 10.496094 9.503906 L 10.496094 0 L 19.996094 0 Z M 19.996094 9.503906 "/>
								<path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,67.843137%,93.72549%);fill-opacity:1;" d="M 9.503906 20 L 0.00390625 20 L 0.00390625 10.496094 L 9.503906 10.496094 Z M 9.503906 20 "/>
								<path style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,73.72549%,3.529412%);fill-opacity:1;" d="M 19.996094 20 L 10.496094 20 L 10.496094 10.496094 L 19.996094 10.496094 Z M 19.996094 20 "/>
								</g>
								</svg>&nbsp;&nbsp;&nbsp;Login with Microsoft</a>

						</form>
					</div>
				</div>
			</div>
		</div>
		
		<div *ngIf="isLogin" style="height: 70%;width: 100vw;display: flex;align-items: center;text-align: center;">
			<h1 style="color: white;width: 100%; font-weight: bolder;text-align: center;"> Let's go to Work!!</h1>
		</div>
	</div>
</div>