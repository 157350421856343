import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';

@Injectable({
  providedIn: 'root'
})
export class AddTrailerAuditService {

  constructor(private http: HttpClient) { }

  saveTrailerAudit(trailerAuditObj): Observable<any> {
    return this.http.post(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/trailerAudit`,trailerAuditObj);
  }
}
