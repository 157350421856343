import { Component } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { Router } from '@angular/router';

@Component({
    selector: 'app-footer',
    templateUrl: './app.footer.component.html'
})
export class AppFooterComponent{
    constructor(public appMain: AppMainComponent,
                public router:Router) {}


    displayTerms()
    {
       this.router.navigate(['/main/user-terms']);
    }


    displayPrivacyPolicy()
    {
        this.router.navigate(['/main/user-privacy-policy']);
    }

}
