import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ManageOvertimeUsersService } from './manage-overtime-users.service';
import { AppLoaderService } from 'src/app/app-loader/service/app-loader.service';
import { AppAlertService } from 'src/app/app-alert/service/app-alert.service';
import { ErrorService } from 'src/app/error-handling/error.service';
import { IpagedQuery } from 'src/app/model/IpagedQuery';
import { TokenService } from 'src/app/security/token.service';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';
import { ManageClientsService } from '../manage-clients/manage-clients.service';

@Component({
  selector: 'app-manage-overtime-users',
  templateUrl: './manage-overtime-users.component.html',
  styleUrls: ['./manage-overtime-users.component.scss']
})
export class ManageOvertimeUsersComponent implements OnInit {

  breadcrumbItems: MenuItem[];
  loading: boolean;
  userList = [];
  cols: any[];
  totalRecords: any;
  query:IpagedQuery;
  userRoles: any;
  userType: any;
  clientQuery: { isActive: boolean; size: number; page: number; };
  clientList = [];

  constructor(
    private overtimeUsersService: ManageOvertimeUsersService,
    private loader : AppLoaderService,
    private alertService : AppAlertService,
    private errorService:ErrorService,
    private tokenService: TokenService,
    private manageClientsService: ManageClientsService,
  ) { }

  ngOnInit(): void {
    this.breadcrumbItems = [];
    this.breadcrumbItems.push({ label: 'Users', routerLink: '../manage-users' });
    this.breadcrumbItems.push({ label: 'Overtime Users'});
    this.query = {isActive:true,size:100,page:0};
    this.clientQuery = { isActive: true, size: 1000, page: 0 };
   
    this.userRoles = this.tokenService.getUserRoles();
    if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_SUPERVISOR)) {
      this.userType = APP_CONSTANTS.USER_ROLES.ROLE_SUPERVISOR;
      this.getClientList(this.clientQuery);
    }else{
      this.getCurrentOvertimeUsers(this.query);
    }

    
    
  }

  paginate(event){
    this.query.page = event.page;
    // this.filterByClient ? this.getUserList(this.query,this.filterByClient) : this.getUserList(this.query);
  }

  getCurrentOvertimeUsers(query: IpagedQuery, clientId?:any){
    this.loader.show();
    this.overtimeUsersService.getCurrentOvertimeUsers(query,clientId).subscribe(res=>{
      this.userList = res.list;
      this.totalRecords = res.totalElements;
      this.loader.hide();
    },(error) => {
      this.loader.hide();
      this.errorService.handleError(error, true);
  })
  }

  deleteOvertimeUser(user: any){
    this.loader.show();
    this.overtimeUsersService.deleteOvertimeUsers(user.userId).subscribe(res=>{
      if(this.userType == "ROLE_SUPERVISOR"){
        this.getCurrentOvertimeUsers(this.query, this.clientList[0].clientId);
      }else{
        this.getCurrentOvertimeUsers(this.query);
      }
      this.alertService.alertSuccess(["Successfully deleted"]);
     this.loader.hide();
    },(error) => {
      this.loader.hide();
      this.errorService.handleError(error, true);
    });
  }

  getClientList(query) {
    this.loader.show();
    this.manageClientsService.viewClients(query).subscribe(response => {
      this.clientList = response.list;
      this.getCurrentOvertimeUsers(this.query, this.clientList[0].clientId);
      this.loader.hide();
    }, (error) => {
      this.loader.hide();
      this.errorService.handleError(error, true);
    })
  }

}
