import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NewUserService } from './new-user.service';
import { AppLoaderService } from 'src/app/app-loader/service/app-loader.service';
import { Subscription } from 'rxjs';
import { ErrorService } from 'src/app/error-handling/error.service';
import { ActivatedRoute, Router } from '@angular/router';
import { jwtDecode } from "jwt-decode";

@Component({
  selector: 'app-new-user',
   template: `
  <p>Parameter value: {{ token }}</p>
`,
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit {

  isNewUser:boolean; 
  newUserForm: FormGroup;
  newUser:any; 
  subscription = new Subscription();
  token:string;
  decodedToken:any;
  expiryDate: Date;
  presentDate:Date;
  userName:string;
  isUserActivated:boolean;
  constructor( private fb:FormBuilder,
               private newUserService:NewUserService,
                private loader : AppLoaderService,
                private errorService:ErrorService,
                private route: ActivatedRoute,
                 private router: Router,
    ) { 
    this.newUserForm = this.fb.group({
      
      password1: ['',Validators.required],
      password2: ['',Validators.required],
      
    });
  }

  ngOnInit(): void {
    
    this.isUserActivated = false;
    this.presentDate = new Date();
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
    });
    this.decodedToken = jwtDecode(this.token);
    this.getNewUser(this.decodedToken.uuid);
    this.convertTimestampToDate();
    if(this.newUser !== null)
    {
       this.isNewUser = true;
    }
   
  }


  getNewUser(userId:string) {
    this.newUserService.getUser(userId).subscribe(res=>{
      this.loader.hide();
      this.newUser = res;
     
      this.userName = this.newUser.firstName+ " " +this.newUser.lastName;
    },(error) => {
      this.isNewUser=false;
      this.loader.hide();
  })
}


convertTimestampToDate() {
    this.expiryDate = new Date(this.decodedToken.exp * 1000);
  }

  validatePassword(){
   
    if(this.newUserForm.get('password1').value !== this.newUserForm.get('password2').value)
    {
     this.newUserForm.get('password2').setErrors({customError: true});
    }
  }

  activate()
  {
  if(this.newUserForm.valid)
  {
    this.isUserActivated = true;
    let assignObj = {
      userId : this.decodedToken.uuid,
      password:this.newUserForm.value.password2
    }
    this.newUserService.activateUser(assignObj).subscribe(res=>{
      this.loader.hide();
      setTimeout(() => {
        this.router.navigate(["login"]);
      }, 2000);
    },(error) => {
      this.loader.hide();
      this.errorService.handleError(error, true);
    })
    
  }
  }
}
