<div id="Test"></div>

<div id="overlay" style="display: none;"> 

    <div id="uidnameShow" class="uidnameShow">
    </div>

    <div class="overlayDetails" style="padding-top:10px;display: none;">
      <span id="overlayType"></span> : <span id="overlayUidnameShow"></span>
    </div>
    <div class="overlayDetails"  style="padding-top:10px;">
      Trailer : <span id="overlayTrailer"></span>
    </div>
    <div class="overlayDetails">
      Location Status: <span id="overlayStatus"></span>
    </div> 
    <div class="overlayDetails">
      Unit # : <span id="overlayUnitNumber"></span>
    </div>
    
    <div class="overlayDetails">
      Time Empty : <span id="overlayTimeElapsed"></span>
    </div>
    <div class="overlayDetails">
      Occupied Time : <span id="occupiedTime"></span>
    </div>
  
  </div>
  

  <div class="canvasContainer" style="height:unset;" id="canvasContainer">
    <canvas id="canvas" width="1000" height="500" class="canvas" style="transform: scale(0.7,0.7);transform-origin:0% 0%;" (click)="handleMouseDown($event)"></canvas>
  </div> 
  <div style="display: none;align-items: center;justify-content: end;margin-top: 20px;">
    <div class="incRedBtn" (click)="zoom(true)">+</div>
    <div class="incRedVal" id="incRedVal">0.6</div>
    <div class="incRedBtn" (click)="zoom(false)">-</div>

  </div>