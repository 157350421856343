import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';

@Injectable({
  providedIn: 'root'
})
export class AddClientService {

  constructor(private http: HttpClient) { }

  saveClient(clientObj): Observable<any> {
    return this.http.post(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/clients`,clientObj);
  }

  updateClient(clientId,clientObj): Observable<any> {
    return this.http.put(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/clients/${clientId}`,clientObj);
  }

  getClientById(clientId):Observable<any>{
    return this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/clients/${clientId}`);
  }

}
