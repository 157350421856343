import { Component, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { MenuItem } from 'primeng/api';
import { AppLoaderService } from 'src/app/app-loader/service/app-loader.service';
import { ManageClientsService } from '../manage-clients/manage-clients.service';
import { ErrorService } from 'src/app/error-handling/error.service';
import * as moment from 'moment';
import { AddSpotsPopupComponent } from '../add-spots-popup/add-spots-popup.component';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';
import { IpagedQuery } from 'src/app/model/IpagedQuery';
import { TokenService } from 'src/app/security/token.service';
import { OverviewService } from './overview.service';
import { ManageFleetsService } from '../manage-fleets/manage-fleets.service';
import { interval, min, Subscription } from 'rxjs';
import { ManageLocationsService } from '../manage-locations/manage-locations.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  providers: [DialogService],
})
export class OverviewComponent implements OnInit {

  totalRecords: any = 0;
  ref: DynamicDialogRef;
  exportTypes: MenuItem[];
  breadcrumbItems: MenuItem[];
  clientList: any;
  types: any;
  date: any;
  dateNow: any;
  accessToken: string;
  query: IpagedQuery;
  data: any = [];
  userType: string;
  userRoles: any;
  uniqueCarrierList: any;
  sort = [
    {name:'ascending',code:false},
    {name:'descending',code:true}
  ];
  timerSubscription: any;
  selectedTimeInArea: string;
  selectedTimeInYard: string;
  selectedTimeInSpot: string;
  selectedClient: string;
  selectedCarrier: string;
  selectedType: string;
  selectedExcludeCarrier: string;
  searchText: string;
  selectedLocation: string;
  timerCount: any = 0;
  locationLists: any = [];
  locationListsIfAdmin: boolean = true;

  constructor(
    public dialogService: DialogService,
    private loader: AppLoaderService,
    private manageClientsService: ManageClientsService,
    private errorService: ErrorService,
    private tokenService: TokenService,
    private overviewService: OverviewService,
    private manageFleetsService: ManageFleetsService,
    private manageLocationsService: ManageLocationsService,
  ) {

    this.exportTypes = [
      {
        label: 'Excel',
        icon: 'pi pi-download',
        command: () => {
          this.exportExcel();
        }
      },
      {
        label: 'CSV',
        icon: 'pi pi-download',
        command: () => {
          this.exportCsv();
        }
      }
    ];

    this.types = [
      {
        label: 'Trailer',
      },
      {
        label: 'Container',
      }
    ];

  }

  ngOnInit(): void {
    this.breadcrumbItems = [];
    this.breadcrumbItems.push({ label: 'Overview' });
    this.query = {isActive:true,size:10,page:0};
    this.getClientList({ isActive: true, size: 1000, page: 0 });
    this.userRoles = this.tokenService.getUserRoles();

    if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_CLIENT && role !== APP_CONSTANTS.USER_ROLES.ROLE_ADMIN && role !== APP_CONSTANTS.USER_ROLES.ROLE_IT)) {
      this.userType = APP_CONSTANTS.USER_ROLES.ROLE_CLIENT;
    }

    if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_ADMIN)) {
      this.userType = APP_CONSTANTS.USER_ROLES.ROLE_ADMIN;
      this.locationListsIfAdmin = false;
    }

    this.accessToken = this.tokenService.getAccessToken();

    this.getDetails(null);
    this.getCarrier("");
    

  }

  getDetails(type:any){
    this.loader.show();
    this.overviewService.viewDetails(type,this.query).subscribe(response => {
      this.data = response.content;
      this.totalRecords = response.totalElements;
      
      this.data.forEach(item => {
        if(item.locationOccupiedTime != "" && item.locationOccupiedTime != null){
          item.timeOnYard = this.parseTimeToSeconds(item.locationOccupiedTime);
        }
        if(item.clientOccupiedTime != "" && item.clientOccupiedTime != null){
          item.timeInArea = this.parseTimeToSeconds(item.clientOccupiedTime);
        }
        if(item.spotOccupiedTime != "" && item.spotOccupiedTime != null){
          item.timeInSpot = this.parseTimeToSeconds(item.spotOccupiedTime);
        }
      });
      if(this.timerCount == 0){
        this.startTimer();
        this.timerCount++;
      }
     
     
     
      this.loader.hide();
    }, (error) => {
      this.loader.hide();
      this.errorService.handleError(error, true);
    });
  }

  getDetails1(){
    this.loader.show();

    if(this.userType != "ROLE_ADMIN"){
      this.selectedClient = this.clientList[0].clientId;
    }

    this.overviewService.viewDetails1(this.selectedType,this.query,this.selectedClient,this.selectedCarrier,this.selectedExcludeCarrier,this.selectedLocation).subscribe(response => {
      this.data = response.content;
      this.totalRecords = response.totalElements;
      
      this.data.forEach(item => {
        if(item.locationOccupiedTime != "" && item.locationOccupiedTime != null){
          item.timeOnYard = this.parseTimeToSeconds(item.locationOccupiedTime);
        }
        if(item.clientOccupiedTime != "" && item.clientOccupiedTime != null){
          item.timeInArea = this.parseTimeToSeconds(item.clientOccupiedTime);
        }
        if(item.spotOccupiedTime != "" && item.spotOccupiedTime != null){
          item.timeInSpot = this.parseTimeToSeconds(item.spotOccupiedTime);
        }
      });
      // if(this.timerCount == 0){
      //   this.startTimer();
      //   this.timerCount++;
      // }
     
     
     
      this.loader.hide();
    }, (error) => {
      this.loader.hide();
      this.errorService.handleError(error, true);
    });
  }

  exportExcel() {
   
    window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/overview/trailers/export/excel?access_token=${this.accessToken}`, '_blank');
  }

  exportCsv() {
    window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/overview/trailers/export/csv?access_token=${this.accessToken}`, '_blank');
  }

  paginate(event) {
    this.query.page = event.page;
    // this.stopTimer();
    if(this.selectedLocation != null || this.selectedClient != null || this.selectedCarrier != null || this.selectedExcludeCarrier != null || this.selectedType != null){
      this.getDetails1();
    }else if(this.selectedTimeInArea != null){
      let data = {
        value: this.selectedTimeInArea
      };
      this.sortTime(data,"client");
    }else if(this.selectedTimeInSpot != null){
      let data = {
        value: this.selectedTimeInSpot
      };
      this.sortTime(data,"spot");
    }else if(this.selectedTimeInYard != null){
      let data = {
        value: this.selectedTimeInYard
      };
      this.sortTime(data,"location");
    }else if(this.searchText != "" && this.searchText != null){
      this.search(this.searchText);
    }else{
      this.getDetails(null);
    }
  }

  getClientList(query) {
    this.manageClientsService.viewClients(query).subscribe(response => {
      this.clientList = response.list;

      this.getLocations(this.clientList[0].clientId);

    }, (error) => {
      this.errorService.handleError(error, true);
    });
  }

  filterByClient(event, check:any = 1) {
    this.loader.show();
    if(check == 0){
      this.query.page = 0;
    }
    // this.selectedCarrier = null;
    // this.selectedType = null;
    // this.selectedExcludeCarrier = null;
    this.selectedTimeInArea = null;
    this.selectedTimeInSpot = null;
    this.selectedTimeInYard = null;
    this.searchText = "";
    this.selectedLocation = null;
    // this.selectedLocation = null;
    if(event.value != null && event.value != undefined){
      this.locationListsIfAdmin = true;
    }else{
      this.locationListsIfAdmin = false;
    }
    this.getLocations(event.value);
    if(this.selectedLocation != null || this.selectedClient != null || this.selectedCarrier != null || this.selectedExcludeCarrier != null || this.selectedType != null){
      this.getDetails1();
    }else{
      this.getDetails(null);
    }
    // if(event.value != null){
    //   this.overviewService.filterByClient(event.value,this.query)
    //   .subscribe(response=>{
    //   this.data = response.content;
    //   this.totalRecords = response.totalElements;
      
    //   this.data.forEach(item => {
    //     if(item.locationOccupiedTime != "" && item.locationOccupiedTime != null){
    //       item.timeOnYard = this.parseTimeToSeconds(item.locationOccupiedTime);
    //     }
    //     if(item.clientOccupiedTime != "" && item.clientOccupiedTime != null){
    //       item.timeInArea = this.parseTimeToSeconds(item.clientOccupiedTime);
    //     }
    //     if(item.spotOccupiedTime != "" && item.spotOccupiedTime != null){
    //       item.timeInSpot = this.parseTimeToSeconds(item.spotOccupiedTime);
    //     }
    //   });

    //     this.loader.hide();
    //   },(error) => {
    //     this.loader.hide();
    //     this.errorService.handleError(error, true);
    // });
    // }else{
    //   this.getDetails(null);
    // }
  }

  filterByType(event,check:any=1) {
    if(check == 0){
      this.query.page = 0;
    }
    // this.selectedCarrier = null;
    this.searchText = null;
    // this.selectedExcludeCarrier = null;
    this.selectedTimeInArea = null;
    this.selectedTimeInSpot = null;
    this.selectedTimeInYard = null;
    // this.selectedClient = null;
    // this.selectedLocation = null;
    // if(event.value != null){
    //   this.getDetails(event.value.toUpperCase());
    // }else{
    //   this.getDetails(null);
    // }
    if(this.selectedLocation != null || this.selectedClient != null || this.selectedCarrier != null || this.selectedExcludeCarrier != null || this.selectedType != null){
      this.getDetails1();
    }else{
      this.getDetails(null);
    }
  }

  getCarrier(event) {
  
      this.manageFleetsService.getCarrier(event.query)
      .subscribe(response=>{
        this.uniqueCarrierList = response.map((label, index) => ({ label, value: index + 1 }));
       
      },(error) => {
        this.errorService.handleError(error, true);
    });
  
  }

  getLocations(clientId) {

    if(this.userType == "ROLE_ADMIN" && !this.locationListsIfAdmin){
      this.overviewService.getLocations(this.query)
        .subscribe(response=>{
          this.locationLists = response.map((locationName, locationId) => ({ locationName, locationId: locationId + 1 }));
        
        },(error) => {
          this.errorService.handleError(error, true);
      });
    }else{
      this.manageLocationsService.viewLocations(this.query,clientId).subscribe(response=>{
        this.locationLists = response.list;
        this.loader.hide();
      },(error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      })
    }
  
  

 

}

// getClientLocations(query,clientId) {
//   this.loader.show();
//   this.subscription.add(
//       this.manageLocationsService.viewLocations(query,clientId).subscribe(response=>{
//           this.clientsLocationsList = response.list;
//           this.loader.hide();
//       },(error) => {
//         this.loader.hide();
//         this.errorService.handleError(error, true);
//     })
//   )
// }
 
  filterByCarrier(event,excl_incl:any,check: any=1){
    if(excl_incl == 0){
      if(this.selectedExcludeCarrier.length == 0){
        this.selectedExcludeCarrier = null;
      }
      
    }
    // console.log(this.selectedExcludeCarrier);
    this.loader.show();
    if(check == 0){
      this.query.page = 0;
    }
    this.searchText = null;
    this.selectedTimeInArea = null;
    this.selectedTimeInSpot = null;
    this.selectedTimeInYard = null;
    // this.selectedClient = null;
    // this.selectedType = null;
    // this.selectedLocation = null;
    if(this.selectedLocation != null || this.selectedClient != null || this.selectedCarrier != null || this.selectedExcludeCarrier != null || this.selectedType != null){
      this.getDetails1();
    }else{
      this.getDetails(null);
    }
    // if(event.value != null){
    //   let data = {};
    //   if(excl_incl == 1){
    //     this.selectedExcludeCarrier = null;
    //     data = {
    //       includeCarriers: event.value,
    //       excludeCarriers: []
    //     }
    //   }else{
    //     this.selectedCarrier = null;
    //     data = {
    //       includeCarriers: [],
    //       excludeCarriers: event.value
    //     }
    //   }
      
    //   this.overviewService.filterCarrier(data,this.query)
    //   .subscribe(response=>{
    //     this.data = response.content;
    //     this.totalRecords = response.totalElements;

    //     this.data.forEach(item => {
    //       if(item.locationOccupiedTime != "" && item.locationOccupiedTime != null){
    //         item.timeOnYard = this.parseTimeToSeconds(item.locationOccupiedTime);
    //       }
    //       if(item.clientOccupiedTime != "" && item.clientOccupiedTime != null){
    //         item.timeInArea = this.parseTimeToSeconds(item.clientOccupiedTime);
    //       }
    //       if(item.spotOccupiedTime != "" && item.spotOccupiedTime != null){
    //         item.timeInSpot = this.parseTimeToSeconds(item.spotOccupiedTime);
    //       }
    //     });

    //     this.loader.hide();
    //   },(error) => {
    //     this.loader.hide();
    //     this.errorService.handleError(error, true);
    // });
    // }else{
    //   this.getDetails(null);
    // }
    
  }

  filterByLocation(event,check: any=1){
    this.loader.show();
    if(check == 0){
      this.query.page = 0;
    }
    this.searchText = null;
    this.selectedTimeInArea = null;
    this.selectedTimeInSpot = null;
    this.selectedTimeInYard = null;
    // this.selectedClient = null;
    // this.selectedType = null;
    // this.selectedExcludeCarrier = null;
    // this.selectedCarrier = null;

    if(this.selectedLocation != null || this.selectedClient != null || this.selectedCarrier != null || this.selectedExcludeCarrier != null || this.selectedType != null){
      this.getDetails1();
    }else{
      this.getDetails(null);
    }
    // if(event.value != null){
    //   this.overviewService.filterLocations(event.value,this.query)
    //   .subscribe(response=>{
    //     this.data = response.content;
    //     this.totalRecords = response.totalElements;

    //     this.data.forEach(item => {
    //       if(item.locationOccupiedTime != "" && item.locationOccupiedTime != null){
    //         item.timeOnYard = this.parseTimeToSeconds(item.locationOccupiedTime);
    //       }
    //       if(item.clientOccupiedTime != "" && item.clientOccupiedTime != null){
    //         item.timeInArea = this.parseTimeToSeconds(item.clientOccupiedTime);
    //       }
    //       if(item.spotOccupiedTime != "" && item.spotOccupiedTime != null){
    //         item.timeInSpot = this.parseTimeToSeconds(item.spotOccupiedTime);
    //       }
    //     });

    //     this.loader.hide();
    //   },(error) => {
    //     this.loader.hide();
    //     this.errorService.handleError(error, true);
    // });
    // }else{
    //   if(this.selectedClient != null && this.selectedClient != ""){
    //     let data = {
    //       value: this.selectedClient
    //     };
    //     this.filterByClient(data.value)
    //   }else{
    //     this.getDetails(null);
    //   }
      
    // }
    
  }

  search(event,check:any=1){
    this.loader.show();
    
    if(check == 0){
      this.query.page = 0;
    }
    this.selectedCarrier = null;
    this.selectedType = null;
    this.selectedExcludeCarrier = null;
    this.selectedTimeInArea = null;
    this.selectedTimeInSpot = null;
    this.selectedTimeInYard = null;
    this.selectedClient = null;
    this.selectedLocation = null;
   if(event != null && event != ""){
      this.overviewService.search(event,this.query)
      .subscribe(response=>{
        this.data = response.content;
        this.totalRecords = response.totalElements;

        this.data.forEach(item => {
          if(item.locationOccupiedTime != "" && item.locationOccupiedTime != null){
            item.timeOnYard = this.parseTimeToSeconds(item.locationOccupiedTime);
          }
          if(item.clientOccupiedTime != "" && item.clientOccupiedTime != null){
            item.timeInArea = this.parseTimeToSeconds(item.clientOccupiedTime);
          }
          if(item.spotOccupiedTime != "" && item.spotOccupiedTime != null){
            item.timeInSpot = this.parseTimeToSeconds(item.spotOccupiedTime);
          }
        });
        this.loader.hide();
      },(error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      });
   }else{
      this.getDetails(null);
   }
  // this.getDetails1();
  }

  sortTime(event: any, op: string, check:any=1){
    this.loader.show();
    if(check == 0){
      this.query.page = 0;
    }
    this.selectedCarrier = null;
    this.searchText = null;
    this.selectedExcludeCarrier = null;
    this.selectedType = null;
    this.selectedClient = null;
    this.selectedLocation = null;
    if(op == "client"){
      this.selectedTimeInYard = null;
      this.selectedTimeInSpot = null;
    }
    if(op == "location"){
      this.selectedTimeInArea = null;
      this.selectedTimeInSpot = null;
    }
    if(op == "spot"){
      this.selectedTimeInYard = null;
      this.selectedTimeInArea= null;
    }
    
    if(event.value != null){
      this.overviewService.sortTime(op, event.value.code,this.query)
      .subscribe(response=>{
        this.data = response.content;
          this.totalRecords = response.totalElements;

          this.data.forEach(item => {
            if(item.locationOccupiedTime != "" && item.locationOccupiedTime != null){
              item.timeOnYard = this.parseTimeToSeconds(item.locationOccupiedTime);
            }
            if(item.clientOccupiedTime != "" && item.clientOccupiedTime != null){
              item.timeInArea = this.parseTimeToSeconds(item.clientOccupiedTime);
            }
            if(item.spotOccupiedTime != "" && item.spotOccupiedTime != null){
              item.timeInSpot = this.parseTimeToSeconds(item.spotOccupiedTime);
            }
          });
        
        this.loader.hide();
      },(error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      });
  }else{
    this.getDetails(null);
  }
    
  }

  calcDateDiff(date: any) {
    const dDay = new Date(date).getTime();
    const milliSecondsInASecond = 1000;
    const hoursInADay = 24;
    const minutesInAnHour = 60;
    const secondsInAMinute = 60;

    const timeDifference = Date.now() - dDay;

    const daysToDday = Math.floor(
      timeDifference /
      (milliSecondsInASecond * minutesInAnHour * secondsInAMinute * hoursInADay)
    );

    const hoursToDday = Math.floor(
      (timeDifference /
        (milliSecondsInASecond * minutesInAnHour * secondsInAMinute)) %
      hoursInADay
    );

    const minutesToDday = Math.floor(
      (timeDifference / (milliSecondsInASecond * minutesInAnHour)) %
      secondsInAMinute
    );

    const secondsToDday =
      Math.floor(timeDifference / milliSecondsInASecond) % secondsInAMinute;
    const finalDate = daysToDday + "D " + hoursToDday + "H " + minutesToDday + "M " + secondsToDday + "S";
    return finalDate;
  }

  show(item: any) {
    this.ref = this.dialogService.open(AddSpotsPopupComponent, {
      header: 'Add Spot',
      width: '800px',
      contentStyle: {
        'max-height': '700px',
        overflow: 'auto'
      },
      baseZIndex: 10000,
      data: item,
    });
//   this.ref.onDestroy.subscribe(
//     ()=>{
//         this.stopTimer();
//         this.getDetails(null);
//         console.log("Sssssssssssssssssssssssss")
//     }
//  )  
  }

  ngOnDestroy() {
    if (this.ref) {
      this.ref.close();
    }
    this.stopTimer();
  }

  // timer run
  private parseTimeToSeconds(timeString: string): number {
   
    const timeComponents = timeString.split(' ');
    let days = 0, hours = 0, minutes = 0, seconds = 0;

    for (const component of timeComponents) {
        const value = parseInt(component.slice(0, -1));
        const unit = component.slice(-1);

        switch (unit) {
            case 'D':
                days = value;
                break;
            case 'H':
                hours = value;
                break;
            case 'M':
                minutes = value;
                break;
            case 'S':
                seconds = value;
                break;
            default:
                console.error('Invalid time unit:', unit);
        }
    }
    return days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60 + seconds;
    
  }

  private startTimer() {
    this.timerSubscription = interval(1000).subscribe(() => {
      this.updateTimers();
    });
  }

  private updateTimers() {
    this.data.forEach(item => {
      if(item.locationOccupiedTime != "" && item.locationOccupiedTime != null){
        item.timeOnYard++;
        item.locationOccupiedTime = this.formatSecondsToTime(item.timeOnYard);
      }

      if(item.clientOccupiedTime != "" && item.clientOccupiedTime != null){
        item.timeInArea++;
        item.clientOccupiedTime = this.formatSecondsToTime(item.timeInArea);
      }

      if(item.spotOccupiedTime != "" && item.spotOccupiedTime != null){
        item.timeInSpot++;
        item.spotOccupiedTime = this.formatSecondsToTime(item.timeInSpot);
      }

    });
  }

  private formatSecondsToTime(seconds: number): string {
    const days = Math.floor(seconds / (24 * 60 * 60));
    const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((seconds % (60 * 60)) / 60);
    const remainingSeconds = seconds % 60;

    let format = "";
    if(days > 0){
       format = format + days + "D ";
    }
    if(hours > 0){
      format = format + hours + "H ";
    }
    if(minutes > 0){
      format = format + minutes + "M ";
    }
    if(remainingSeconds > 0){
      format = format + remainingSeconds + "S";
    }

    return format;
  }

  private stopTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

}
