<div class="block-section">
	<div class="block-content">
		<div class="pt-8 flex align-items-center justify-content-center">
			<img class="logo" src="../../../assets/logo/spoton_vector.svg" alt="logo">
		</div>
		<div class="surface-ground px-4 pt-6 md:px-6 lg:px-8 flex align-items-center justify-content-center">
			<div class="surface-card p-4 shadow-0 border-round w-full lg:w-6">
				<div *ngIf="token==false" class="text-center mb-5">
					<div class="text-900 text-3xl font-medium mb-3 text-white">Forgot Password</div>
					<div class="text-900 text-sm font-medium mb-3 text-white">A token will be send to the following email</div>
				</div>
                <div *ngIf="token==true" class="text-center mb-5">
					<div class="text-900 text-3xl font-medium mb-3 text-white">Reset Password</div>
				</div>
				<div *ngIf="token==false">
					<form [formGroup]="emailForm">
						<label for="email" class="block text-900 mb-2 text-white">Email Address</label>
						<input id="email" type="email" formControlName="email" pInputText class="w-full mb-3" placeholder="Email Address">
						<span
                            style="color: red;"
                            *ngIf="
                                emailForm.controls.email.touched &&
                                emailForm.controls.email.invalid
                            "
                            >Email is required</span
                        >
                        <div class="flex align-items-center justify-content-between mb-4">
							<div class="flex align-items-center">
							</div>
							<a class="font-medium no-underline ml-2 text-blue-500 text-white text-right cursor-pointer" (click)="showPasswordForm()">Already have a token?</a>
						</div>
                        <div class="flex align-items-center justify-content-center mb-4">
							<button (click)="sendOtp()" pButton pRipple label="Submit" class="flex w-6 p-button-rounded text-800 custom-button"></button>
						</div>
					</form>
				</div>
                <div *ngIf="token==true">
					<form [formGroup]="passwordForm">
						<label for="pwd" class="block text-900 mb-2 text-white">New Password</label>
						<input id="pwd" type="password" formControlName="newPassword" pInputText class="w-full mb-3" placeholder="New Password">
                        <span
                            style="color: #ff7355;margin-bottom: 4px;"
                            *ngIf="
                            passwordForm.controls.newPassword.touched &&
                            passwordForm.controls.newPassword.invalid
                            "
                            >Password is required</span
                        >
                        <label for="cpwd" class="block text-900 mb-2 text-white">Confirm New Password</label>
						<input id="cpwd" type="password" formControlName="confirmNewPassword" pInputText class="w-full mb-3" placeholder="Confirm new password">
						<div style='color:#ff7355;margin-bottom: 4px;' *ngIf="passwordForm.get('newPassword').value != passwordForm.get('confirmNewPassword').value && passwordForm.get('confirmNewPassword').value != ''">
                            Password does not match</div>
                        <label for="token" class="block text-900 mb-2 text-white">Token</label>
						<input id="token" type="text" formControlName="token" pInputText class="w-full mb-3" placeholder="Enter token here">
						<span
                            style="color: #ff7355;"
                            *ngIf="
                            passwordForm.controls.token.touched &&
                            passwordForm.controls.token.invalid
                            "
                            >Token is required</span
                        >
                        <div class="flex align-items-center justify-content-between mb-4">
							<div class="flex align-items-center">
							</div>
							<a class="font-medium no-underline ml-2 text-blue-500 text-white text-right cursor-pointer" (click)="hidePasswordForm()">Forgot Password?</a>
						</div>
                        <div class="flex align-items-center justify-content-center mb-4">
							<button (click)="resetPassword()" pButton pRipple label="Submit" class="flex w-6 p-button-rounded custom-button"></button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
  