import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';

@Injectable({
  providedIn: 'root'
})
export class ManageMobileAppVersionService {

  constructor(private http: HttpClient) { }

  updateMobileVersion(data): Observable<any> {
    return this.http.post(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/mobile/version`, data);
  }

  getMobileVersions(): Observable<any> {
    return this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/mobile/version`);
  }

}
