import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { ManageLocationsService } from './manage-locations.service';
import { IpagedQuery } from 'src/app/model/IpagedQuery';
import { map, Subscription } from 'rxjs';
import { AppLoaderService } from 'src/app/app-loader/service/app-loader.service';
import { AppAlertService } from 'src/app/app-alert/service/app-alert.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ManageFleetsService } from '../manage-fleets/manage-fleets.service';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';
import { TokenService } from 'src/app/security/token.service';
import { ErrorService } from 'src/app/error-handling/error.service';

@Component({
  selector: 'app-manage-locations',
  templateUrl: './manage-locations.component.html',
  providers: [MessageService, ConfirmationService],
  styleUrls: ['../../../assets/demo/badges.scss','./manage-locations.component.scss']
})
export class ManageLocationsComponent implements OnInit, OnDestroy {

    deleteLocationDialog: boolean = false;
    cols: any[];
    breadcrumbItems: MenuItem[];
    clientId: any;
    location: boolean;
    loading: boolean;
    query:IpagedQuery;
    totalRecords: any;
    locationList = [];
    subscription = new Subscription();
    clientName: any;
    entryExitDialog: boolean;
    entryExitForm: FormGroup;
    fleetList: any[];
    fleetQuery: { isActive: boolean; size: number; page: number; };
    locationId: any;
    type: { name: string; code: string; }[];
    userRoles: any;
    userType: any;
    accessToken: string;
    items: MenuItem[];
    hideButtonsIfDriver:boolean = false;
    hideButtonsIfSupervisor:boolean = false;
    hideButtonsIfGuard:boolean = false;
    hideButtonsIfSpotter:boolean = false;

    constructor(
        private router:Router,
        private activatedRoute:ActivatedRoute,
        private manageLocationsService:ManageLocationsService,
        private loader:AppLoaderService,
        private alertService:AppAlertService,
        private fb:FormBuilder,
        private manageFleetsService:ManageFleetsService,
        private tokenService:TokenService,
        private errorService:ErrorService
    ) {

        this.entryExitForm = this.fb.group({
            fleetId: ['',Validators.required],
            notes: [''],
            type: ['',Validators.required]
        });

        this.activatedRoute.queryParams.subscribe(qparams=>{
            if(qparams["clientId"]){
                this.clientId = qparams["clientId"];
            }
            if( qparams["clientName"]){
                this.clientName = qparams["clientName"];
            }
        })

        this.items = [
            {
                label: 'Excel',
                icon: 'pi pi-download',
                command: () => {
                    this.exportExcel();
                }
            },
            {
                label: 'CSV',
                icon: 'pi pi-download',
                command: () => {
                    this.exportCsv();
                }
            }
        ];

    }

    ngOnInit() {

        this.userRoles = this.tokenService.getUserRoles();
        if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_CLIENT && role !== APP_CONSTANTS.USER_ROLES.ROLE_ADMIN && role !== APP_CONSTANTS.USER_ROLES.ROLE_IT)){
            this.userType = APP_CONSTANTS.USER_ROLES.ROLE_CLIENT;
        }
        if(this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_DRIVER))
        {
            this.hideButtonsIfDriver = true;
        }
        if(this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_SUPERVISOR))
        {
            this.hideButtonsIfSupervisor = true;
        }
        if(this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_GUARD))
        {
            this.hideButtonsIfGuard = true;
        }
        if(this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_SPOTTER))
        {
            this.hideButtonsIfSpotter = true;
        }
        this.accessToken = this.tokenService.getAccessToken();

        this.query = {isActive:true,size:10,page:0};
        this.fleetQuery = {isActive:true,size:1000,page:0};

        this.cols = [
            { field: 'locationName', header: 'Location Name' },
            { field: 'street', header: 'Street' },
            { field: 'city', header: 'City' },
            { field: 'state', header: 'State' },
            { field: 'country', header: 'Country' }
        ];

        this.type = [
            { name: 'Entry', code:'ENTRY'},
            { name: 'Exit', code:'EXIT'},
        ]

        this.breadcrumbItems = [];
        this.breadcrumbItems.push({ label: 'Clients',routerLink:'../manage-clients'});
        this.breadcrumbItems.push({ label: 'Locations'});

        if(this.clientId){
            this.getClientLocations(this.query,this.clientId);
            this.getFleetList(this.fleetQuery,this.clientId);
        }
    }


    getClientLocations(query,clientId) {
        this.loading = true;
        this.subscription.add(
            this.manageLocationsService.viewLocations(query,clientId).subscribe(response=>{
                this.locationList = response.list;
                this.totalRecords = response.totalElements;
                this.loading = false;
            },(error) => {
                this.loader.hide();
                this.errorService.handleError(error, true);
            })
        )
    }

    getFleetList(query: IpagedQuery,clientId?: any) {
        // this.loading = true;
        this.subscription.add(
          this.manageFleetsService.viewFleets(query,clientId)
          .pipe(
            map(res=>{
              let fleets = [];
              for(let fleet of res.list){
                let obj = {
                  ...fleet,
                  plateNumber_unitNumber : fleet.plateNumber+"-"+`(${fleet.unitNumber})`
                };
                fleets.push(obj);
              }
              return { 
                list : fleets,
                totalElements : res.totalElements
              }
            })
          )
          .subscribe(response=>{
            this.fleetList = response.list;
            // this.totalRecords = response.totalElements;
            // this.loading = false;
          },(error) => {
            this.loader.hide();
            this.errorService.handleError(error, true);
        })
        ) 
      }

    
    
    deleteProduct(location) {
        this.deleteLocationDialog = true;
        this.location = location;
    }

    confirmDelete(locationId){
        this.loader.show();
        this.subscription.add(
        this.manageLocationsService.deleteLocation(this.clientId,locationId).subscribe(res=>{
            this.deleteLocationDialog = false;
            this.alertService.alertSuccess(['Location Deleted/Deactivate Successfully']);
            this.getClientLocations(this.query,this.clientId);
            this.loader.hide();
        },(error) => {
            this.loader.hide();
            this.errorService.handleError(error, true);
        })
        )
    }

    routeToAddLocation(){
        this.router.navigate(['/main/add-location'],{queryParams:{clientId:this.clientId,clientName:this.clientName}})
    }

    routeToEditLocation(locationId){
        this.router.navigate(['/main/add-location'],{queryParams:{clientId:this.clientId,locationId,clientName:this.clientName}})
    }

    routeToSpots(locationId,locationName){
        this.router.navigate(['/main/manage-spots'],{queryParams:{clientId:this.clientId,clientName:this.clientName,locationId,locationName}})
    }

    routeToDetails(clientId,locationId){
        this.router.navigate(['/main/client-details'],{queryParams:{clientId,locationId}});
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    paginate(event){
        this.query.page = event.page;
        this.getClientLocations(this.query,this.clientId);
    }

    openEntryExitDialog(locationId){
        this.entryExitDialog = true;
        this.locationId = locationId;
    }

    hideDialog() {
        this.entryExitDialog = false;
    }
    
    createEntryExit(){
        if(this.entryExitForm.invalid){
            this.entryExitForm.markAllAsTouched();
        }else{
            this.loader.show();
            this.manageLocationsService.createEntryExit(this.locationId,this.clientId,this.entryExitForm.value).subscribe(res=>{
                this.entryExitDialog = false;
                this.entryExitForm.reset();
                this.locationId=null;
                this.alertService.alertSuccess(['Entry/Exit Record created successfully']);
                this.loader.hide();
            },(error) => {
                this.loader.hide();
                this.errorService.handleError(error, true);
            })
        }
    }

    exportCsv(){
        window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/clients/${this.clientId}/locations/export/csv?access_token=${this.accessToken}`, '_blank');
    }

    exportExcel(){
        window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/clients/${this.clientId}/locations/export/excel?access_token=${this.accessToken}`, '_blank');
    }
    makeDefault(clientId,locationId){
        this.loader.show();
        this.manageLocationsService.makeDefault(clientId,locationId).subscribe(res=>{
            this.getClientLocations(this.query,clientId);
            this.alertService.alertSuccess(['Location marked as defaults']);
            this.loader.hide();
        },(error) => {
            this.loader.hide();
            this.errorService.handleError(error, true);
        })
    }
}
