import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';
import { IpagedQuery } from 'src/app/model/IpagedQuery';

@Injectable({
  providedIn: 'root'
})
export class ManageFleetsService {

  constructor(private http: HttpClient) { }

  viewFleets(query:IpagedQuery,clientId?:any,unitNumber?:any,sort?:any,fleetStatus?:string): Observable<any> {
    console.log("isActive",query.isActive)
    var params = new HttpParams({
      fromObject:{
        isActive:query.isActive != null ? query.isActive.valueOf() : '',
        page:query.page.toString(),
        size:query.size.toString(),
        
      }
    })
    if(fleetStatus)
    {
      params = params.append("fleetStatus",fleetStatus);
    }
    if(unitNumber){
      params = params.append("unitNumber",unitNumber.toString())
    }
    if(sort){
      params = params.append('sort','unitNumber,ASC');
    }else{
      params = params.append('sort','type,desc');
    }
    return clientId ? this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/clients/${clientId}/fleets`,{params})  :  this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/fleets`,{params});
  }


 


  assignFleetToClient(assignObj,clientId): Observable<any> {
    return this.http.post(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/clients/${clientId}/fleets`,assignObj);
  }

  activateFleet(fleetId){
    return this.http.patch(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/fleets/${fleetId}/activate`,null);
  }

  mergeUnitNumber(assignObj1):Observable<any> {
    return this.http.post(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/clients/fleets/merge`,assignObj1);
  }

  getCarrier(carrier):Observable<any>{
    return carrier ? this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/carrier/${carrier}`) : this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/carrier`);
  }

  mergeCarrier(data):Observable<any> {
    return this.http.patch(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/carrier/update`,data);
  }

}
