import { Component, OnInit } from '@angular/core';
import { Product } from '../../api/product';
import { ProductService } from '../../service/productservice';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map, Subscription } from 'rxjs';
import { ManageFleetsService } from '../manage-fleets/manage-fleets.service';
import { IpagedQuery } from 'src/app/model/IpagedQuery';
import { ManageClientsService } from '../manage-clients/manage-clients.service';
import { AppLoaderService } from 'src/app/app-loader/service/app-loader.service';
import { ManageLocationsService } from '../manage-locations/manage-locations.service';
import { AppAlertService } from 'src/app/app-alert/service/app-alert.service';
import { ManageEntryExitService } from './manage-entry-exit.service';
import { TokenService } from 'src/app/security/token.service';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';
import * as moment from 'moment';
import { ErrorService } from 'src/app/error-handling/error.service';

@Component({
  selector: 'app-manage-entry-exit',
  templateUrl: './manage-entry-exit.component.html',
  providers: [MessageService, ConfirmationService],
  styleUrls: ['../../../assets/demo/badges.scss','./manage-entry-exit.component.scss']
})
export class ManageEntryExitComponent implements OnInit {

  breadcrumbItems: MenuItem[];

  productDialog: boolean;

  deleteProductDialog: boolean = false;

    deleteProductsDialog: boolean = false;

    entryExitList = [
      // {
      //     location:"Dynacraft, 123 W. Main St.Lousiville",
      //     trailer: '12NZ4566',
      //     carrier: 'FedEx',
      //     notes: 'Pull out empty trailer first',
      //     entryTime: '1/1/2022, 10:42PM',
      //     exitTime: '1/1/2022, 11:30PM',
      //     reportBy: 'John Doe',
      //     typeOfEntry:"Entry",
      //     typeOfTrailerTruck:"Truck"
      // },
      // {
      //   location:"Dynacraft, 123 W. Main St.Lousiville",
      //   trailer: '12NZ4566',
      //   carrier: 'FedEx',
      //   notes: 'Pull out empty trailer first',
      //   entryTime: '1/1/2022, 10:42PM',
      //   exitTime: '1/1/2022, 11:30PM',
      //   reportBy: 'John Doe',
      //   typeOfEntry:"Exit",
      //   typeOfTrailerTruck:"Trailer"
      // },
      // {
      //   location:"Dynacraft, 123 W. Main St.Lousiville",
      //   trailer: '12NZ4566',
      //   carrier: 'FedEx',
      //   notes: 'Pull out empty trailer first',
      //   entryTime: '1/1/2022, 10:42PM',
      //   exitTime: '1/1/2022, 11:30PM',
      //   reportBy: 'John Doe',
      //   typeOfEntry:"Entry",
      //   typeOfTrailerTruck:"Truck"
      // },
      // {
      //   location:"Dynacraft, 123 W. Main St.Lousiville",
      //   trailer: '12NZ4566',
      //   carrier: 'FedEx',
      //   notes: 'Pull out empty trailer first',
      //   entryTime: '1/1/2022, 10:42PM',
      //   exitTime: '1/1/2022, 11:30PM',
      //   reportBy: 'John Doe',
      //   typeOfEntry:"Exit",
      //   typeOfTrailerTruck:"Trailer"
      // }
  ];

    product: Product;

    selectedProducts: Product[];

    submitted: boolean;

    cols: any[];

    statuses: any[];

 

  rowsPerPageOptions = [5, 10, 20];
  modalNotes: any;
  clients: { name: string; value: string; }[];
  entryExitDialog: boolean;
  locationId: any;
  entryExitForm: FormGroup;
  fleetList: any[];
  totalRecords: any;
  clientId: any;
  loading: boolean;
  subscription = new Subscription();
  query : IpagedQuery;
  fleetQuery: { isActive: boolean; size: number; page: number; };
  clientList = [];
  clientsLocationsList = [];
  type: { name: string; code: string; }[];
  maximumSizequery : IpagedQuery;
  activeInactivequery : IpagedQuery;
  filterByClient: any;
  userRoles: any;
  userType: any;
  accessToken: string;
  deleteEntryExitDialog: boolean;
  entryExitId: any;
  entryExitClientId: any;
  items: MenuItem[];
  hideButtonIfGuard:boolean = false;
  filterFleetId:any;
  selectedFleetId:string;
  isTrailerClicked:boolean = false;  
  notAdminIT: boolean = false;

    constructor(private manageFleetsService:ManageFleetsService,
      private loader:AppLoaderService,
      private fb : FormBuilder, 
      private manageClientsService : ManageClientsService,
      private manageLocationsService : ManageLocationsService, 
      private alertService : AppAlertService,
      private manageEntryExitService:ManageEntryExitService,
      private tokenService:TokenService,
      private errorService:ErrorService) {
                  this.entryExitForm = this.fb.group({
                    clientId: ['',Validators.required],
                    locationId:['',Validators.required],
                    fleetId: ['',Validators.required],
                    notes: [''],
                    type: ['',Validators.required]
                });

                this.items = [
                  {
                      label: 'Excel',
                      icon: 'pi pi-download',
                      command: () => {
                          this.exportExcel();
                      }
                  },
                  {
                      label: 'CSV',
                      icon: 'pi pi-download',
                      command: () => {
                          this.exportCsv();
                      }
                  }
              ];


                }

    ngOnInit() {

        this.userRoles = this.tokenService.getUserRoles();
      if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_CLIENT && role !== APP_CONSTANTS.USER_ROLES.ROLE_ADMIN && role !== APP_CONSTANTS.USER_ROLES.ROLE_IT)){
        this.userType = APP_CONSTANTS.USER_ROLES.ROLE_CLIENT;
      } 
      if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_GUARD))
      {
        this.hideButtonIfGuard = true;
      } 

      if (this.userRoles.some(role => role !== APP_CONSTANTS.USER_ROLES.ROLE_ADMIN && role !== APP_CONSTANTS.USER_ROLES.ROLE_IT)) {
        this.notAdminIT = true;
      }

      this.accessToken = this.tokenService.getAccessToken();
        this.query = {isActive:true,size:10,page:0}
    this.maximumSizequery = {isActive:true,size:10000,page:0};
    this.activeInactivequery = {size:10000,page:0};
        this.viewClients(this.maximumSizequery);
    this.getClientList(this.maximumSizequery);
    this.getEntryExit(this.query);


        this.cols = [
            {field: 'name', header: 'Name'},
            {field: 'type', header: 'Type'},
            {field: 'status', header: 'Status'},
            {field: 'latitude', header: 'Latitude'},
            {field: 'longitude', header: 'Longitude'}
        ];

        this.type = [
          { name: 'Entry', code:'ENTRY'},
          { name: 'Exit', code:'EXIT'},
      ]

      this.statuses = [
        {name: 'Active', value: 'Active'},
        {name: 'Disable', value: 'Disable'}
    ];

    this.clients = [
      {name: 'Dynacraft ', value: 'Pegasus'},
      {name: 'Pegasus  ', value: 'Dynacraft'},
    ];

        this.breadcrumbItems = [];
        this.breadcrumbItems.push({ label: 'Entry / Exit Detail'})
}

getEntryExit(query:IpagedQuery,clientId?:any){
  this.loading = true;
  this.manageEntryExitService.getEntryExitList(query,clientId)
  .pipe(
    map(res=>{
      let entryExitList = [];
      for(let entryExit of res.list){
          let obj = {
              ...entryExit,
              dateOfEntry : moment.utc(entryExit.audit.createdDate).format("MM/DD/YYYY"),
              entryTime : moment.utc(entryExit.audit.createdDate).format("hh:mm A")
            };
            entryExitList.push(obj);
      }
      return { 
        list : entryExitList,
        totalElements : res.totalElements
      }
    })
      
  )
  .subscribe(response=>{
    this.entryExitList = response.list;
    this.totalRecords = response.totalElements;
    this.loading = false;
  },(error) => {
    this.loader.hide();
    this.errorService.handleError(error, true);
  })
}

    getClientLocations(query,clientId) {
      this.loader.show();
      this.subscription.add(
          this.manageLocationsService.viewLocations(query,clientId).subscribe(response=>{
              this.clientsLocationsList = response.list;
              this.loader.hide();
          },(error) => {
            this.loader.hide();
            this.errorService.handleError(error, true);
        })
      )
    }

    viewClients(query:IpagedQuery){
      this.loading = true;
      this.manageClientsService.viewClients(query).subscribe(response=>{
        this.clients = response.list;
        this.totalRecords = response.totalElements;
        this.loading = false;
      },(error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
    })
  }

  getClientList(query) {
    this.loader.show()
    this.subscription.add(
      this.manageClientsService.viewClients(query).subscribe(response=>{
        this.clientList = response.list;
        if(this.notAdminIT){
          this.entryExitForm.patchValue({
            clientId: this.clientList[0].clientId
          });
          this.getClientLocations(this.query, this.clientList[0].clientId);
          this.clientId = this.clientList[0].clientId;
          this.isTrailerClicked = false;
        }       
        this.loader.hide();
      },(error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
    })
    )
  }



    getFleetList(event?: any) {

      if(this.entryExitForm.controls.clientId.invalid)
      {
        this.isTrailerClicked = true;
      }
      else
      {
        
        console.log("event",event.query);
        this.loading = true;
        this.subscription.add(
          this.manageFleetsService.viewFleets(this.activeInactivequery,this.clientId,event.query)
          .pipe(
            map(res=>{
              let fleets = [];
              for(let fleet of res.list){
                let obj = {
                  ...fleet,
                  plateNumber_unitNumber : fleet.plateNumber+"-"+`(${fleet.unitNumber})`,
                  fleetAndHotTrailer : this.checkIfHotTrailer(fleet)
                };
                fleets.push(obj);
              }
              return { 
                list : fleets,
                totalElements : res.totalElements
              }
            })
          )
          .subscribe(response=>{
            this.fleetList = response.list;
            this.totalRecords = response.totalElements;
            this.loading = false;
          },(error) => {
            this.loader.hide();
            this.errorService.handleError(error, true);
        })
        ) 


      }
      
    }

    openEntryExitDialog(locationId){
      this.entryExitDialog = true;
      this.locationId = locationId;

    }

    openNew() {
        this.product = {};
        this.submitted = false;
        this.productDialog = true;
    }

    deleteSelectedProducts() {
        this.deleteProductsDialog = true;
    }

    editProduct(product: Product) {
        this.product = {...product};
        this.productDialog = true;
    }

    viewNotes(notes) {
        this.deleteProductDialog = true;
        this.modalNotes = notes;
    }

    hideDialog() {
      this.entryExitDialog = false;
      this.deleteProductDialog = false;
    }

    saveProduct() {
        this.submitted = true;
    }
   
    // createId(): string {
    //     let id = '';
    //     const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    //     for (let i = 0; i < 5; i++) {
    //         id += chars.charAt(Math.floor(Math.random() * chars.length));
    //     }
    //     return id;
    // }
    onClientSelect(event){
      this.getClientLocations(this.query,event.value);
      // this.getFleetList(this.activeInactivequery,event.value);
      this.clientId = event.value;
      this.isTrailerClicked = false;
   }


   forLocationId(event){
      this.locationId = event.value
   }


    createEntryExit(){
      if(this.entryExitForm.invalid){
          this.entryExitForm.markAllAsTouched();
      }else{
          this.loader.show();
          this.entryExitForm.patchValue({
            fleetId:this.selectedFleetId
          });
          this.manageLocationsService.createEntryExit(this.locationId,this.clientId,this.entryExitForm.value).subscribe(res=>{
              this.entryExitDialog = false;
              this.entryExitForm.reset();
              this.locationId=null;
              this.alertService.alertSuccess(['Entry/Exit Record created successfully']);
              this.getEntryExit(this.query);
              this.loader.hide();
          },(error) => {
            this.loader.hide();
            this.errorService.handleError(error, true);
        })
      }
    }

    paginate(event){
      this.query.page = event.page;
      this.filterByClient ? this.getEntryExit(this.query,this.filterByClient) :this.getEntryExit(this.query);
    }

    filterFleetsByClient(event){
      this.filterByClient = event.value;
      this.getEntryExit(this.query,event.value);
    }

    exportCsv(){
      if(this.filterByClient){
        window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/fleets/entryExits/export/csv?access_token=${this.accessToken}&location.client.uuid=${this.filterByClient}`, '_blank');
      }else{
        window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/fleets/entryExits/export/csv?access_token=${this.accessToken}`, '_blank');
      }
    }

    exportExcel(){
      if(this.filterByClient){
        window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/fleets/entryExits/export/excel?access_token=${this.accessToken}&location.client.uuid=${this.filterByClient}`, '_blank');
      }else{
        window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/fleets/entryExits/export/excel?access_token=${this.accessToken}`, '_blank');
      }
    }
    checkIfHotTrailer(fleet) {
      if(fleet.isHotTrailer){
        return `${fleet.unitNumber} - (Hot Trailer)`
      }else{
        return `${fleet.unitNumber}`
      }
    }

    deleteEntryExit(clientId,entryExitId) {
      this.deleteEntryExitDialog = true;
      this.entryExitId = entryExitId;
      this.entryExitClientId = clientId;
    }

    confirmDelete(){
      this.loader.show();
      this.subscription.add(
      this.manageEntryExitService.deleteEntryExit(this.entryExitClientId,this.entryExitId).subscribe(res=>{
          this.deleteEntryExitDialog = false;
          this.entryExitId=undefined;
          this.entryExitClientId=undefined;
          this.alertService.alertSuccess([`Deleted Successfully`]);
          this.getEntryExit(this.query);
          this.loader.hide();
      },(error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
    })
      )
    }


    onFleetSelect(event:any)
    {
      this.selectedFleetId = event.fleetId;
       this.entryExitForm.patchValue({
        fleetId:event.unitNumber
      });
      
    }


    clearFilter(event:any)
    {
      this.entryExitForm.patchValue({
        fleetId:''
      });
    }
}
