import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { BadgeModule } from 'primeng/badge';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { GalleriaModule } from 'primeng/galleria';
import { ImageModule } from 'primeng/image';
import { InplaceModule } from 'primeng/inplace';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KnobModule } from 'primeng/knob';
import { LightboxModule } from 'primeng/lightbox';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ScrollTopModule } from 'primeng/scrolltop';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SplitterModule } from 'primeng/splitter';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { TerminalModule } from 'primeng/terminal';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeSelectModule } from 'primeng/treeselect';
import { TreeTableModule } from 'primeng/treetable';
import { VirtualScrollerModule } from 'primeng/virtualscroller';

import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { AppTopBarComponent } from './app.topbar.component';
import { AppFooterComponent } from './app.footer.component';
import { AppConfigComponent } from './app.config.component';
import { AppMenuComponent } from './app.menu.component';
import { AppMenuitemComponent } from './app.menuitem.component';

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { EmptyComponent } from './components/empty/empty.component';

import { CountryService } from './service/countryservice';
import { CustomerService } from './service/customerservice';
import { EventService } from './service/eventservice';
import { IconService } from './service/iconservice';
import { NodeService } from './service/nodeservice';
import { PhotoService } from './service/photoservice';
import { ProductService } from './service/productservice';
import { MenuService } from './service/app.menu.service';
import { ConfigService } from './service/app.config.service';
import { ManageClientsComponent } from './components/manage-clients/manage-clients.component';
import { ManageLocationsComponent } from './components/manage-locations/manage-locations.component';
import { ManageFleetsComponent } from './components/manage-fleets/manage-fleets.component';
import { AddLocationComponent } from './components/add-location/add-location.component';
import { AddFleetComponent } from './components/add-fleet/add-fleet.component';
import { ManageRolesComponent } from './components/manage-roles/manage-roles.component';
import { ManageUsersComponent } from './components/manage-users/manage-users.component';
import { AddUserComponent } from './components/add-user/add-user.component';
import { AddClientComponent } from './components/add-client/add-client.component';
import { ManageSpotsComponent } from './components/manage-spots/manage-spots.component';
import { LoginComponent } from './components/login/login.component';
import { ClientDetailsComponent } from './components/client-details/client-details.component';

import { AgmCoreModule } from '@agm/core';
import { ManageJobsComponent } from './components/manage-jobs/manage-jobs.component';
import { AddJobComponent } from './components/add-job/add-job.component';
import { ManageMessagesComponent } from './components/manage-messages/manage-messages.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ManageEntryExitComponent } from './components/manage-entry-exit/manage-entry-exit.component';
import { AppAlertModule } from './app-alert/app-alert.module';
import { AppLoaderModule } from './app-loader/app-loader.module';
import { ErrorModule } from './error-handling/error.module';
import { ErrorRouterModule } from './error-handling/error-router.module';
import { AppErrorHandler } from './error-handling/app-error.handler';
import { SecurityModule } from './security/security.module';
import { AppHttpInterceptor } from './security/app-http.interceptor';
import { CanvasToolComponent } from './canvas-tool/canvas-tool.component';
import { PreviewCanvasMapComponent } from './preview-canvas-map/preview-canvas-map.component';
import { PhoneMaskDirective } from './model/phone-mask.directive';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { NewUserComponent } from './components/new-user/new-user.component';
import { WebSocketService } from './web-socket.service';
import { ManageTrailerAuditComponent } from './components/manage-trailer-audit/manage-trailer-audit.component';
import { AddTrailerAuditComponent } from './components/add-trailer-audit/add-trailer-audit.component';
import { TermsComponent } from './components/terms/terms.component';
import { UserTermsComponent } from './components/user-terms/user-terms.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { UserprivacyPolicyComponent } from './components/userprivacy-policy/userprivacy-policy.component';
import { RedirectPageComponent } from './components/redirect-page/redirect-page.component';
import { OverviewComponent } from './components/overview/overview.component';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import { AddSpotsPopupComponent } from './components/add-spots-popup/add-spots-popup.component';
import { ManageMobileAppVersionComponent } from './components/manage-mobile-app-version/manage-mobile-app-version.component';
import { ManageUserAvailabilityComponent } from './components/manage-user-availability/manage-user-availability.component';
import { ManageOvertimeUsersComponent } from './components/manage-overtime-users/manage-overtime-users.component';
// import { ManageTrailerTrackingComponent } from './components/manage-trailer-tracking/manage-trailer-tracking.component';


@NgModule({
    imports: [
        BrowserModule,
        FontAwesomeModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        AvatarModule,
        AvatarGroupModule,
        BadgeModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        CascadeSelectModule,
        ChartModule,
        CheckboxModule,
        ChipsModule,
        ChipModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ConfirmPopupModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DividerModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        GalleriaModule,
        ImageModule,
        InplaceModule,
        InputNumberModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        KnobModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        RippleModule,
        ScrollPanelModule,
        ScrollTopModule,
        SelectButtonModule,
        SidebarModule,
        SkeletonModule,
        SlideMenuModule,
        SliderModule,
        SplitButtonModule,
        SplitterModule,
        StepsModule,
        TagModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TerminalModule,
        TieredMenuModule,
        TimelineModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeSelectModule,
        TreeTableModule,
        VirtualScrollerModule,
        // AgmCoreModule.forRoot({
        //     apiKey: 'AIzaSyCLgoYG8HtgxRoc3eiI0OrFMJyywRT_FlU',
        //     libraries: ['places']
        // }),
        AppAlertModule,
        AppLoaderModule,
        ErrorModule,
        ErrorRouterModule,
        SecurityModule,
        DynamicDialogModule
        
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppTopBarComponent,
        AppFooterComponent,
        AppConfigComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        DashboardComponent,
        EmptyComponent,
        ManageClientsComponent,
        ManageLocationsComponent,
        ManageFleetsComponent,
        AddLocationComponent,
        AddFleetComponent,
        ManageRolesComponent,
        ManageUsersComponent,
        AddUserComponent,
        AddClientComponent,
        ManageSpotsComponent,
        LoginComponent,
        ClientDetailsComponent,
        ManageJobsComponent,
        AddJobComponent,
        ManageMessagesComponent,
        ManageEntryExitComponent,
        CanvasToolComponent,
        PreviewCanvasMapComponent,
        PhoneMaskDirective,
        ForgotPasswordComponent,
        NewUserComponent,
        ManageTrailerAuditComponent,
        AddTrailerAuditComponent,
        TermsComponent,
        UserTermsComponent,
        PrivacyPolicyComponent,
        UserprivacyPolicyComponent,
        RedirectPageComponent,
        OverviewComponent,
        AddSpotsPopupComponent,
        ManageMobileAppVersionComponent,
        ManageUserAvailabilityComponent,
        ManageOvertimeUsersComponent,
        // ManageTrailerTrackingComponent,
        
       
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppHttpInterceptor,
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: AppErrorHandler
        },
        {
            provide: LocationStrategy, 
            useClass: HashLocationStrategy
        },
        CountryService,
        CustomerService, 
        EventService, 
        IconService, 
        NodeService,
        PhotoService, 
        ProductService, 
        MenuService, 
        ConfigService,
        WebSocketService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
