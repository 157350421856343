import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ManageMobileAppVersionService } from './manage-mobile-app-version.service';
import { AppLoaderService } from 'src/app/app-loader/service/app-loader.service';
import { ErrorService } from 'src/app/error-handling/error.service';
import { AppAlertService } from 'src/app/app-alert/service/app-alert.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-manage-mobile-app-version',
  templateUrl: './manage-mobile-app-version.component.html',
  styleUrls: ['./manage-mobile-app-version.component.scss']
})
export class ManageMobileAppVersionComponent implements OnInit {

  breadcrumbItems: MenuItem[];
  mobileDetails: any;
  editDialogOpen: boolean = false;
  updatedData: any = {};
  form: FormGroup;


  constructor(
    private manageMobileService: ManageMobileAppVersionService,
    private loader: AppLoaderService,
    private errorService: ErrorService,
    private alertService: AppAlertService,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      currentVersion: ['', [Validators.required, Validators.pattern('^[0-9]+(\.[0-9]+)?$')]],
      os: ['', Validators.required]

    });
  }

  ngOnInit(): void {

    this.breadcrumbItems = [];
    this.breadcrumbItems.push({ label: 'Manage Mobile App' });
    this.getAndroidDetails();
  }

  getAndroidDetails() {
    this.loader.show();
    this.manageMobileService.getMobileVersions().subscribe(response => {
      this.mobileDetails = response;
      this.loader.hide();
    }, (error) => {
      this.loader.hide();
      this.errorService.handleError(error, true);
    })
  }

  updateMobileVersions() {
    if (this.form.valid) {
      this.manageMobileService.updateMobileVersion(this.form.value).subscribe(response => {
        this.alertService.alertSuccess(['Succesfully updated.']);
        this.getAndroidDetails();
        this.cancelMobileDialog();
      }, (error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      })
    } else {
      this.form.markAllAsTouched();
    }

  }

  updateMobileDetails(item: any) {
    this.editDialogOpen = true;
    this.form.patchValue({
      currentVersion: "",
      os: item.os
    });
  }

  cancelMobileDialog() {
    this.editDialogOpen = false;
    this.form.reset();

  }

}
