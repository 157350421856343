import {Component, OnDestroy} from '@angular/core';
import {AppAlertService} from '../../service/app-alert.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-alert',
  templateUrl: './app-alert.component.html',
  styleUrls: ['./app-alert.component.css']
})
export class AppAlertComponent implements OnDestroy {

  /**
   * Alerts Type
   */
  type = 'toast';

  life = 7000;

  /**
   * Alert subscription
   */
  alertsSubscription: Subscription;

  constructor(private alertService: AppAlertService) {
    // Subscribing to the alert subject
    this.alertsSubscription = alertService.getType().subscribe(type => {
      this.type = type;
    });
  }

  ngOnDestroy() {
    this.alertsSubscription.unsubscribe();
  }
}
