import {  Component} from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent{
  
   
    menuMode = 'static';

    constructor(private primengConfig: PrimeNGConfig)
     {
  
      }

     

    ngOnInit() {
        this.primengConfig.ripple = true;
        document.documentElement.style.fontSize = '14px';
    }

    finalJson:any=[];

    //Library For Canvas 

    drawImg(context:any,x:any,y:any,w:any,h:any,url:any,which:any=0,uidname:any){
      
      if(String(x)!="" && String(y)!="" && String(w)!="" && String(h)!="" && String(url)!=""){
        
        var json = {"component":"IMAGE","left":x,"top":y,"w":w,"h":h,"url":url,"uidname":uidname};
        
        var img = new Image();
        img.src = url//'https://www.industrialempathy.com/img/remote/ZiClJf-1920w.jpg';

        img.onload = () => {
          //console.log("adsfasd");
          
            context.drawImage(img, x, y, w, h);  
        }


        if(which==0)this.finalJson.push(json);
        //console.log(which);

        //console.log(this.finalJson);


    }


    }

    rect(context:any,x:any,y:any,w:any,h:any,borderx:any,borderColor:any,bgColor:any,which:any=0,uidname:any){
      //////console.log(x+"-"+y+"-"+w+"-"+h+"-"+borderx+"-"+borderColor+"-"+bgColor);
      
  
      if(x!="" && y!="" && w!="" && h!=""){
        var json = {"component":"RECT","left":x,"top":y,"w":w,"h":h,"borderWidth":borderx,"borderColor":borderColor,"bgColor":bgColor,"uidname":uidname};
  
        if(bgColor!="") {
          context.fillStyle=bgColor;
          context.fillRect(x, y, w, h);
        }
    
        if(borderx!="" && parseInt(borderx)>0){
          context.lineWidth = borderx;
          if(borderColor!="") context.strokeStyle=borderColor;
          context.strokeRect(x, y, w, h);
        }
  
        if(which==0)this.finalJson.push(json);
  
      }
  
  
    }
  
    arc(context:any,x:any,y:any,r:any,deg:any,borderx:any,borderColor:any,bgColor:any,which:any=0,uidname:any){
      //////console.log("Arc-"+x+"-"+y+"-"+r+"-"+deg);
      if(x!="" && y!="" && r!="" && deg!=""){
        var json = {"component":"ARC","left":x,"top":y,"r":r,"deg":deg,"borderWidth":borderx,"borderColor":borderColor,"bgColor":bgColor,"uidname":uidname};
  
  
        context.beginPath();
        context.arc((parseInt(x)+parseInt(r)),(parseInt(y)+parseInt(r)),r,0,((deg*Math.PI)/180));
  
        if(bgColor!="") {
          context.fillStyle=bgColor;
          context.fill();
        }
  
        if(borderx!="" && parseInt(borderx)>0){
          context.lineWidth = borderx;
          if(borderColor!="") context.strokeStyle=borderColor;
          context.stroke();
        }
      
        if(which==0)this.finalJson.push(json);
      }
  
    }
  
    line(context:any,x1:any,y1:any,x2:any,y2:any,borderWidth:any,borderColor:any,which:any,uidname:any){
      ////////console.log(borderColor);
      if(x1!="" && y1!="" && x2!="" && y2!=""){
        var json = {"component":"LINE","left1":x1,"top1":y1,"left2":x2,"top2":y2,"borderWidth":borderWidth,"borderColor":borderColor,"uidname":uidname};
  
        if(borderWidth!="")context.lineWidth = 5;
  
        //console.log(uidname+"="+borderColor);
        if(borderColor!="") context.strokeStyle=borderColor;
  
        context.beginPath()
  
        context.moveTo(x1,y1);
        context.lineTo(x2,y2);
        context.stroke();
  
        if(which==0)this.finalJson.push(json);
  
      }
    }
  
  
    text(context:any,x:any,y:any,text:any,fontSize:any,fontFamily:any,color:any,which:any=0,uidname:any){
      fontFamily="Nunito Sans";
      //////console.log(x+"-"+y+"-"+fontSize);
      if(x!="" && y!=""){
  
        var a:any=7;
        if(color!="") context.fillStyle = color;
  
  
        if(fontSize!="" || fontFamily!="") 
        {
          var ab:any ="";
          if(fontSize!="" && parseInt(fontSize)>0) ab=fontSize+"px";
          //////console.log(ab);
          if(fontFamily!=""){
            ab += (ab!="") ? " "+fontFamily : fontFamily; 
          }else{
            ab+=" Arial";
          }
          //////console.log(ab);
          context.font=ab;
          a=parseInt(fontSize)-5;
        }else{
          context.font="10px Arial";
        }   
        context.textAlign = "center"; 

  

        context.fillText(text,(parseInt(x)+parseInt(fontSize)-2),(parseInt(y)+parseInt(a)/2));
       
        ////console.log(y+"-"+(parseInt(y)+parseInt(a)));

        

////console.log(text);
  //      //console.log(context.measureText(text));
  var metrics = context.measureText(text);
  //    //console.log(metrics.width);
      let actualHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
    //  //console.log(actualHeight);

      var whsize = this.fontSize(text,fontSize);
      


      var json = {"component":"TEXT","left":x,"top":y,"w":metrics.width, "h":actualHeight,"text":text,"fontSize":fontSize,"fontFamily":fontFamily,"color":color,"uidname":uidname};

        
        if(which==0)this.finalJson.push(json);
  
      }
    }
  
    parkingLot(context:any,x:any,y:any,w:any,h:any,borderx:any,borderColor:any,bgColor:any,text:any,fontSize:any,fontFamily:any,color:any,which:any=0,uidname:any){
      fontFamily="Nunito Sans";
      var json = {"component":"SPOT","left":x,"top":y,"w":w,"h":h,"borderWidth":borderx,"borderColor":borderColor,"bgColor":bgColor,"text":text,"fontSize":fontSize,"fontFamily":fontFamily,"color":color,"uidname":uidname};
  
      this.rect(context,x,y,w,h,borderx,borderColor,bgColor,1,uidname);
    //  //console.log("----");
     // //console.log((parseInt(x)+(parseInt(w)/2)-(text.length*fontSize)/2));
    //  //console.log(parseInt(x)+"="+(parseInt(w)/2)+"="+(text.length*fontSize)/2);
    //  //console.log(this.fontSize(text,fontSize));
      var whsize = this.fontSize(text,fontSize);
     // //console.log((parseInt(x)+(parseInt(w)/2)-(parseInt(whsize.width)/2)));
      
//      this.text(context,(parseInt(x)+(parseInt(w)/2)-(text.length*fontSize)/2),(parseInt(y)+(parseInt(h)/2)-(fontSize/2))+5,text,fontSize,fontFamily,color,1,uidname);/
//this.text(context,Math.abs((parseInt(x)+(parseInt(w)/2))-whsize.width),(parseInt(y)+(parseInt(h)/2)-(fontSize/2))+5,text,fontSize,fontFamily,color,1,uidname);
  
////console.log(((parseInt(w))+"-"+(whsize.width))+"-"+((parseInt(w)-whsize.width)/2));
      this.text(context,(parseInt(x)+((parseInt(w)-whsize.width)/2))-10,(parseInt(y)+(parseInt(h)/2)-(fontSize/2))+5,text,fontSize,fontFamily,color,1,uidname);
  
      if(which==0)this.finalJson.push(json);
    }
  

    fontSize(a:any,fontSize:any){
      ////console.log("sdf");
      var test:any = document.getElementById("Test");
      test.innerHTML=a;
      test.style.fontSize = fontSize;
      var height = (test.clientHeight + 1);
      var width = (test.clientWidth + 1);

      return {width:width,height:height};
    }

  
}
