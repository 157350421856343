<div class="grid">
    <div class="col-12">
        <div class="card card-w-title">
            <p-breadcrumb [model]="breadcrumbItems" [home]="{icon: 'pi pi-home',routerLink:'../'}"></p-breadcrumb>
        </div>
    </div>
    <div class="col-12">
        <div class="card">
            <p-toast></p-toast>
            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="left">
                    <div class="my-2">
                        <h5 class="m-0">Manage Mobile app version</h5>
                    </div>
                </ng-template>



            </p-toolbar>

            <p-table #dt [value]="mobileDetails" [loading]=""
                styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" [responsive]="true" [columns]=""
                [rows]="2" [rowHover]="true" dataKey="id">

                <ng-template pTemplate="header">
                    <tr>
                        <th></th>
                        <th pSortableColumn="currentVersion">Current Mobile App Version</th>
                        <th pSortableColumn="os">OS</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                    <tr>
                        <td>
                            <button pButton pRipple icon="pi pi-pencil" pTooltip="Edit"
                                class="p-button-rounded p-button-warning mr-2"
                                (click)="updateMobileDetails(item)"></button>

                        </td>
                        <td><span class="p-column-title">Current Version</span>
                            {{item.appVersion}}
                        </td>
                        <td><span class="p-column-title">OS</span>
                            {{item.os}}
                        </td>

                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="3">No data found.</td>
                    </tr>
                </ng-template>
            </p-table>


        </div>

        <p-dialog [(visible)]="editDialogOpen" header="Update Version" [modal]="true" (onHide)="cancelMobileDialog()"
            [style]="{width:'450px'}">
            <form [formGroup]="form">
                <div class="field">
                    <label class="col-6"><b>OS:</b></label>
                    <span class="col-6">{{ form.get('os').value }}</span>

                </div>
                <div class="field">
                    <label class="col-6"><b>Current Version</b><span class="text-danger mr-2">*</span></label>
                    <input class="col-6" type="text" pInputText formControlName="currentVersion" />
                    <div
                        *ngIf="form.get('currentVersion').invalid && (form.get('currentVersion').dirty || form.get('currentVersion').touched)">
                        <div class="text-danger" *ngIf="form.get('currentVersion').errors?.required">
                            App version is required.
                        </div>
                        <div class="text-danger" *ngIf="form.get('currentVersion').errors?.pattern">
                            Please enter a valid number.
                        </div>
                    </div>
                </div>
            </form>
            <ng-template pTemplate="footer">
                <button pButton pRipple icon="pi pi-times" class="p-button-text" label="Cancel"
                    (click)="cancelMobileDialog()"></button>
                <button pButton pRipple icon="pi pi-check" class="p-button-text" label="Submit"
                    (click)="updateMobileVersions()"></button>
            </ng-template>
        </p-dialog>


    </div>
</div>