import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {MessageService} from 'primeng/api';

// export type ALERT_TYPE = 'message' | 'toast';
export type ALERT_TYPE = 'toast';
export type ALERT_SEVERITY = 'error' | 'info' | 'warn' | 'success';

/**
 * Service to help publish alerts on application
 */
@Injectable()
export class AppAlertService {

  /**
   * Subject a type of alert message
   * This can be subscribed to start receiving alert events
   */
  private type: Subject<string> = new Subject();

  constructor(private messageService: MessageService) {
  }

  /**
   * Return the type subject that can be sbscribed upon
   */
  public getType(): Subject<string> {
    return this.type;
  }

  /**
   * Alerts Error
   * @param messages messages to be displayed inside alert
   * @param type type of alert 'message or growl'
   */
  public alertError(messages: Array<string>, type?: ALERT_TYPE) {
    this.showAlert(messages, 'error', 'Error', type);
  }

  /**
   * Alerts Info
   * @param messages messages to be displayed inside alert
   * @param type type of alert 'message or growl'
   */
  public alertInfo(messages: Array<string>, type?: ALERT_TYPE) {
    this.showAlert(messages, 'info', 'Info', type);
  }

  /**
   * Alerts Warning
   * @param messages messages to be displayed inside alert
   * @param type type of alert 'message or growl'
   */
  public alertWarning(messages: Array<string>, type?: ALERT_TYPE) {
    this.showAlert(messages, 'warn', 'Warning', type);
  }

  /**
   * Alerts Success
   * @param messages messages to be displayed inside alert
   * @param type type of alert 'message or growl'
   */
  public alertSuccess(messages: Array<string>, type?: ALERT_TYPE) {
    this.showAlert(messages, 'success', 'Success', type);
  }

  /**
   * Emits an event to set type of alert
   * @param type type of alert
   */
  private setType(type: string) {
    this.type.next(type);
  }

  /**
   * Add Message(s) to messageService to display on alert.
   * @param {Array<string>} messages message(s) to be displayed
   * @param {ALERT_TYPE} type type of alert 'message or growl'
   * @param {string} severity kind of message : error | info | warn | success
   * @param {string} summary string to be displayed as heading
   */
  private showAlert(messages: Array<string>, severity: ALERT_SEVERITY, summary: string, type?: ALERT_TYPE) {
    type = type != null ? type : 'toast';
    this.setType(type);
    setTimeout(() => {
      this.messageService.clear();
      if (messages.length === 1) {
        this.messageService.add({severity: severity, summary: summary, detail: messages[0]});

      } else {
        this.messageService.addAll(messages.map(message => {
          return {severity: severity, summary: summary, detail: message};
        }));
      }
    }, 100);
  }
}
