<div class="grid">
	<div class="col-12">
        <div class="card card-w-title">
            <p-breadcrumb [model]="breadcrumbItems" [home]="{icon: 'pi pi-home',routerLink:'../'}"></p-breadcrumb>
        </div>
    </div>
	<div class="col-12">
		<div class="card">
			<h4>Create User</h4>
			<form [formGroup]="userForm">
				<div class="p-fluid p-formgrid grid">
					<div class="field col-12 md:col-6">
						<label htmlFor="firstName">First Name<span class="text-danger">*</span></label>
						<input pInputText id="firstName" formControlName="firstName" type="text" />
						<span
                            class="text-danger"
                            *ngIf="
                                userForm.controls.firstName.touched &&
								userForm.controls.firstName.invalid
                            "
                            >First Name is required</span
                        >
					</div>
					<div class="field col-12 md:col-6">
						<label htmlFor="lastName">Last Name<span class="text-danger">*</span></label>
						<input pInputText id="lastName" formControlName="lastName" type="text" />
						<span
                            class="text-danger"
                            *ngIf="
                                userForm.controls.lastName.touched &&
								userForm.controls.lastName.invalid
                            "
                            >Last Name is required</span
                        >
					</div>
					<div class="field col-12 md:col-6">
						<label htmlFor="email">Email<span class="text-danger">*</span></label>
						<input pInputText id="email" formControlName="email" type="email" />
						<span
                            class="text-danger"
                            *ngIf="
                                userForm.controls.email.touched &&
								userForm.controls.email.invalid
                            "
                            >Email is required</span
                        >
					</div>
					<div class="field col-12 md:col-6">
						<label htmlFor="role">Role<span class="text-danger">*</span></label>
						<p-dropdown [options]="roleList" formControlName="roleId" (onChange)="toggleClient()" placeholder="Select Role" optionLabel="name" optionValue="code" [disabled]="loggedInUserId == userId"></p-dropdown>
						<!-- <p-multiSelect [options]="roleList" formControlName="roleIds" optionLabel="name" optionValue="code"></p-multiSelect> -->
						<span
                            class="text-danger"
                            *ngIf="
                                userForm.controls.roleId.touched &&
								userForm.controls.roleId.invalid
                            "
                            >Role is required</span
                        >
					</div>

					<div class="field col-12 md:col-6">
						<label htmlFor="phone">Phone</label>
						<input pInputText id="phone" type="text" appPhoneMask formControlName="phone" maxlength="14" />
						<span
                            class="text-danger"
                            *ngIf="
                                userForm.controls.phone.touched &&
								userForm.controls.phone.invalid
                            "
                            >Phone is required</span
                        >
					</div>
					<div class="field col-12 md:col-6" *ngIf="!hideClient">
						
							<label htmlFor="client">Client<span class="text-danger">*</span></label>
							<p-multiSelect [options]="clientList" formControlName="clientIds" optionLabel="clientName" optionValue="clientId" [disabled]="loggedInUserId == userId"></p-multiSelect>
							<span
								class="text-danger"
								*ngIf="
									userForm.controls.clientIds.touched &&
									userForm.controls.clientIds.invalid
								"
								>Client is required</span
							>
						
					</div>
					<div class="field col-12 md:col-6" *ngIf="hideClient">
						<label htmlFor="timeZone">Time Zone<span class="text-danger">*</span></label>
						<p-dropdown
							[options]="timeZoneList"
							formControlName="timeZone"
							placeholder="Select Time Zone"
							optionLabel="zone"
							optionValue="zone" [disabled]="loggedInUserId == userId"
						></p-dropdown>
						<span
							class="text-danger"
							*ngIf="
								userForm.controls.timeZone.touched &&
								userForm.controls.timeZone.invalid
							"
							>Time Zone is required</span
						>
					</div>
					<button *ngIf="!userId" pButton type="button" (click)="onSubmit()" label="Create User"></button>
					<button style="width: fit-content !important;" *ngIf="userId" pButton type="button" (click)="onSubmit()" label="Update User"></button>
					<button style="width: fit-content !important;" *ngIf="userId" pButton class="p-button-danger mx-2" type="button" (click)="deleteUser()" label="Deactivate" [disabled]="loggedInUserId == userId"></button>
					<button style="width: fit-content !important;" *ngIf="userId" pButton class="p-button-secondary mx-2" type="button" (click)="confirmResetPasswordDialog = true;" label="Reset Password"></button>
				</div>
			</form>
		</div>
	</div>
</div>

<p-dialog [(visible)]="deleteUserDialog" header="Confirm" [modal]="true" [style]="{width:'450px'}">
	<div class="flex align-items-center justify-content-start">
		<i class="pi pi-exclamation-triangle mr-3 my-4" style="font-size: 2rem"></i>
		<span *ngIf="userId">Are you sure you want to deactivate this user ?</span>
	</div>
	<ng-template pTemplate="footer">
		<button pButton pRipple icon="pi pi-times" class="p-button-text" label="No" (click)="deleteUserDialog = false"></button>
		<button pButton pRipple icon="pi pi-check" class="p-button-text" label="Yes" (click)="confirmDelete()"></button>
	</ng-template>
</p-dialog>


<p-dialog [(visible)]="confirmResetPasswordDialog" header="Confirm" [modal]="true" [style]="{width:'450px'}">
	<div class="flex align-items-center justify-content-start">
		<i class="pi pi-exclamation-triangle mr-3 my-4" style="font-size: 2rem"></i>
		<span *ngIf="userId">Are you sure you want to reset Password of this user ?</span>
	</div>
	<ng-template pTemplate="footer">
		<button pButton pRipple icon="pi pi-times" class="p-button-text" label="No" (click)="confirmResetPasswordDialog = false"></button>
		<button pButton pRipple icon="pi pi-check" class="p-button-text" label="Yes" (click)="resetPassword(); confirmResetPasswordDialog = false"></button>
	</ng-template>
</p-dialog>


<p-dialog [(visible)]="passwordDialog" header="Activate account" [modal]="true" [style]="{width:'450px',height:'250px'}">
	
		<div class="flex align-items-center justify-content-center">
			<h4 class="mb-0">Account activation link has been sent to {{email}}</h4>
		</div>
		<ng-template pTemplate="footer">
			<button pButton pRipple icon="pi pi-times" class="p-button-text" label="Ok" (click)="routeToManageUsers()"></button>
		</ng-template>
	
	
</p-dialog>

<p-dialog [(visible)]="resetPasswordModal" [style]="{width: '450px'}" header="New Password" [modal]="true" class="p-fluid">
	<ng-template pTemplate="content">
		<div class="flex align-items-center justify-content-center">
			<h4 class="mb-0">{{password}}</h4>
		</div>
	</ng-template>

	<ng-template pTemplate="footer">
		<button pButton pRipple label="Ok" icon="pi pi-check" class="p-button-text" type="button" (click)="resetPasswordModal=false"></button>
	</ng-template>
</p-dialog>
