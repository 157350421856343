<div class="grid">
	<div class="col-12">
        <div class="card card-w-title">
            <p-breadcrumb [model]="breadcrumbItems" [home]="{icon: 'pi pi-home',routerLink:'../'}"></p-breadcrumb>
        </div>
    </div>

    <div class="col-12">
        <div class="card">
            <div class="flex align-items-center justify-content-between mb-0">
                <h5>Client Locations  <p-tag *ngIf="userType !== 'ROLE_CLIENT' && hideButtonsIfDriver !== true && hideButtonsIfSupervisor === false && hideButtonsIfGuard === false && hideButtonsIfSpotter === false" class="tag-hover" severity="warning" (click)="routeToEditClient()" value="Edit" icon="pi pi-pencil"></p-tag></h5>
            </div>
            <p class="mb-2"><span class="font-medium text-500">Client Name:</span> {{client?.clientName}}</p>
            <p class="mb-2"><span class="font-medium text-500">Address:</span>  {{client?.street}}, {{client?.city}}, {{client?.state}}, {{client?.zip}}</p>
            <p class="mb-2"><span class="font-medium text-500">Contact Person:</span> {{client?.contactPerson}}</p>
            <p class="mb-2"><span class="font-medium text-500">Contact Email:</span> {{client?.contactEmail}}</p>
            <p class="mb-2"><span class="font-medium text-500">Contact Phone:</span> {{client?.contactPhone}}</p>
            <br>
            <div class="flex flex-column md:flex-row md:justify-content-between align-items-end">
                <div>
                    <h5>Default Location</h5>
                    <p class="mb-2"><span class="font-medium text-500">Location Name:</span> {{defaultLocations?.locationName}}</p>
                    <p class="mb-2"><span class="font-medium text-500">Address:</span> {{defaultLocations?.street}},
                        {{defaultLocations?.city}}, {{defaultLocations?.state}}, {{defaultLocations?.zip}}</p>
                </div>
                <div>
                    <button *ngIf="userType == 'ROLE_SUPERVISOR' || userType == 'ROLE_IT' || userType == 'ROLE_ADMIN'" pButton pRipple label="New Spot" icon="pi pi-plus"
                        class="p-button-success mr-2" (click)="routeToAddJob()"></button>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12">
		<div class="card">
			<h5>Locations <p-tag *ngIf="userType !== 'ROLE_CLIENT' && hideButtonsIfDriver !== true && hideButtonsIfSupervisor === false && hideButtonsIfGuard === false && hideButtonsIfSpotter === false" class="tag-hover" severity="success" (click)="routeToAddLocation()" value="Add" icon="pi pi-plus"></p-tag></h5>
			<p-dataView #dv [value]="locations" [paginator]="false" [rows]="9" layout="grid">
				<ng-template let-location pTemplate="gridItem">
					<div class="col-12 md:col-4">
						<div *ngIf="location && selectedLocation" [ngClass]="location.locationId === selectedLocation.locationId ? 'highlight' : ''" (click)="showSpotsForLocation(location,scrollToMap)" class="card m-3 border-1 surface-border location-hover">
							<div class="flex align-items-center justify-content-between">
								<div class="flex align-items-center">
									<i class="pi pi-map-marker mr-2"></i>
									<span class="font-semibold">{{location.locationName}}</span>
								</div>
                                <i *ngIf="userType !== 'ROLE_CLIENT' && hideButtonsIfDriver !== true && hideButtonsIfSupervisor === false && hideButtonsIfGuard === false && hideButtonsIfSpotter === false" class="pi pi-pencil" (click)="routeToEditLocation(location.locationId)"></i>
							</div>
							<div class="mt-3">
								<div class="mb-2">{{location.street}}, {{location.city}}, {{location.state}}, {{location.country}}</div>
								<!-- <div class="mb-2">{{location.remarks}}</div> -->
                                <!-- <div class="mb-2"  (click)="routeToSpots(location.locationId,location.locationName)"><i class="pi pi-directions pr-2 "></i>Route To Spots</div> -->
							</div>
						</div>
					</div>
				</ng-template>
			</p-dataView>
		</div>
	</div>

    <!-- <div class="col-12">
		<div class="card">
            <h5 class="mb-4">Docks/Parking Spots for {{selectedLocation?.locationName}}</h5>
            <div #scrollToMap>
            <agm-map [zoom]="zoom" [latitude]="selectedLocation?.latitude" [longitude]="selectedLocation?.longitude" [mapTypeId]="mapType" [mapTypeControl]='true'>
                <agm-marker [latitude]="selectedLocation?.latitude" [longitude]="selectedLocation?.longitude"></agm-marker>
                <agm-marker *ngFor="let spot of spotsArray; let i = index"
                (mouseOver)="onMouseOver(infoWindow,$event)" 
                (mouseOut)="onMouseOut(infoWindow,$event)" 
                [latitude]="spot.latitude" 
                [longitude]="spot.longitude"
                [iconUrl] ="{url: spot.status == 'OCCUPIED' ?  '../../../assets/demo/images/map-icons/green-marker.png' : '../../../assets/demo/images/map-icons/red-marker.png'}"
                >
                    <agm-info-window [disableAutoPan]="false" #infoWindow>
                        <p><b>Docks/Spots:</b> {{spot.spotName}}</p>
                        <p><b>Status:</b> {{spot.status}}</p>
                        <p><b>Trailer/Truck:</b> {{spot.trailer ? spot.trailer : '-' }}</p>
                        <p><b>Carrier:</b> {{spot.carrier ? spot.carrier : '-'}}</p>
                        <p><b>Notes:</b> {{spot.remarks}}</p>
                    </agm-info-window>
                </agm-marker>
            </agm-map>
            </div>
        </div>
    </div> -->


    <div *ngIf="selectedLocation?.mapImageUrl" class="col-12">
		<div class="card">
            <h5 class="mb-4">{{selectedLocation?.locationName}}</h5>
            <img [src]="selectedLocation?.mapImageUrl" alt="Location Image" width="100%">
        </div>
    </div>


    
    <div *ngIf="showChildren" class="col-12">
		<div class="card" style="position:relative;">
            <h5 class="mb-4">Docks/Parking Spots for {{selectedLocation?.locationName}}</h5>
            <div class="scrollMap">
            <app-preview-canvas-map  [locationMapJson]="selectedLocation?.locationMapJson" [locations]="selectedLocation"></app-preview-canvas-map>
            </div>
            <div style="background-color: #f2f2f2;height: 80px;border:15px;width: 100%;margin-top:15px;display: flex;align-items: center; ">
                <div *ngFor="let arr of mapArr" style="display: flex;align:items;margin-left:20px;font-weight: 700;font-family: Nunito Sans;">
                    <div style="width:20px;height:20px;border:1px solid #000;background-color: {{arr.color}};"></div>
                    <div style="margin-left:7px;margin-top:2px;">{{arr.statusVal}}</div>
                </div>
                
            </div>
        </div>
    </div>


    <div class="col-12">
        <div class="card">
            <h5 class="mb-4">Docks/Parking Spot-Trailer/Container Status</h5>
            <br>
            <div class="flex flex-column md:flex-row md:justify-content-between align-items-end">
                <div>
                    <p class="mb-2"><span class="font-medium text-500">Occupied Full Trailer:</span>
                        {{tallyObj?.totalOccupiedFullTrailer}}</p>
                    <p class="mb-2"><span class="font-medium text-500">Occupied Empty Trailer:</span>
                        {{tallyObj?.totalOccupiedEmptyTrailer}}</p>
                    <p class="mb-2"><span class="font-medium text-500">Location Reserved:</span> {{tallyObj?.totalToBeOccupied}}</p>
                    <p class="mb-2"><span class="font-medium text-500">Scheduled for Pick-Up:</span> {{tallyObj?.totalToBeEmpty}}
                    </p>
                    <p class="mb-2"><span class="font-medium text-500">Empty:</span> {{tallyObj?.totalEmpty}}</p>
                </div>
                <div>
                    <button *ngIf="userType == 'ROLE_SUPERVISOR' || userType == 'ROLE_IT' || userType == 'ROLE_ADMIN'" pButton pRipple label="New Spot"
                        icon="pi pi-plus" class="p-button-success mr-2" (click)="routeToAddJob()"></button>
                </div>
            </div>
            <br>
			<p-table #dt [value]="spotsFilteredArray" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" [responsive]="true" [rows]="10" [globalFilterFields]="['fleet.unitNumber']" [rowHover]="true" dataKey="id">
                <ng-template pTemplate="caption">
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <span>
                            <p-dropdown [showClear]="true" class="ml-2" placeholder="Location Status" (onChange)="filterSpotsByStatus($event)" [options]="statuses" optionLabel="name" optionValue="code"></p-dropdown>
                            <p-dropdown [showClear]="true" class="ml-2" placeholder="Select Trailer Status" (onChange)="filterSpotsByTrailerStatus($event)" [options]="trailerStatus" optionLabel="name" optionValue="code"></p-dropdown>
                        </span>
                        <span class="block mt-2 md:mt-0 p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
                            
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <!-- <th></th> -->
                        <th pSortableColumn="spotName">Location</th>
                        <!-- <th pSortableColumn="type">Dock/Parking Spot</th> -->
                        <th pSortableColumn="status">Location Status</th>
                        <th pSortableColumn="carrier">Trailer Name</th>
                        <th pSortableColumn="unitNumber">Unit #</th>
                        <th pSortableColumn="trailerStatus">Trailer Status</th>
                        <th pSortableColumn="elapsedTime">Time Empty(dd:hh:mm:ss)</th>
                        <th pSortableColumn="elapsedTime">Time Occupied(dd:hh:mm:ss)</th>
                        <!-- <th pSortableColumn="latitude">Latitude</th>
                        <th pSortableColumn="longitude">Longitude</th> -->
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-spot>
                    <tr>
                        <!-- <td>
                            <button pButton pRipple icon="pi pi-pencil" pTooltip="Edit" class="p-button-rounded p-button-warning mr-2" (click)="editSpot(spot)"></button>
                            <button pButton pRipple icon="pi pi-trash" pTooltip="Delete" class="p-button-rounded p-button-danger mt-2" (click)="deleteSpot(spot)"></button>
                        </td> -->
                        <td ><span class="p-column-title">Name</span>
                            {{spot.spotName}}
                        </td>
                        <!-- <td >
                            <span class="p-column-title">Dock/Spot</span>
                            {{spot.type}}
                        </td> -->
                        <td >
                            <span class="p-column-title">Location Status</span>
                            <p-tag *ngIf="spot.status == 'EMPTY' && spot?.fleet == null"  rounded="true" severity="danger" value="Empty"></p-tag>
                            <p-tag *ngIf="spot.status == 'OCCUPIED' && spot?.fleet?.fleetStatus == 'FULL' " rounded="true" severity="success" value="Occupied Full Trailer"></p-tag>
                            <p-tag *ngIf="spot.status == 'OCCUPIED' && spot?.fleet?.fleetStatus == 'EMPTY'" rounded="true" severity="primary" value="Occupied Empty Trailer"></p-tag>
                            <p-tag *ngIf="spot.status == 'TO_BE_EMPTY'" rounded="true" severity="info" value="Scheduled for Pick-Up"></p-tag>
                            <p-tag *ngIf="spot.status == 'TO_BE_OCCUPIED'" rounded="true" severity="warning" value="Location Reserved"></p-tag>
                        </td>
                        <td >
                            <span class="p-column-title">Trailer Name</span>
                            {{spot?.fleet?.carrier ? spot?.fleet?.carrier : '-'}}
                        </td>
                        <td >
                            <span class="p-column-title">Unit #</span>
                            {{spot?.fleet?.unitNumber ? spot?.fleet?.unitNumber : '-' }}
                        </td>
                        
                        <td >
                            <span class="p-column-title">Trailer Status</span>
                            {{spot?.fleet?.fleetStatus ? spot?.fleet?.fleetStatus : '-'}}
                        </td>
                        <td >
                            <span class="p-column-title">Elapsed Time</span>
                            {{spot.emptiedSinceSeconds ? spot.emptiedSinceSeconds : '-'}} 
                            <!-- <span *ngIf="spot.emptiedSinceSeconds">(hh:mm:ss)</span> -->
                        </td>
                        <td>
                            <span class="p-column-title">Elapsed Time</span>
                            {{spot.occupiedSinceSeconds ? spot.occupiedSinceSeconds : '-'}}
                        </td>
                        <!-- <td >
                            <span class="p-column-title">Latitude</span>
                            {{spot.latitude}}
                        </td>
                        <td >
                            <span class="p-column-title">Longitude</span>
                            {{spot.longitude}}
                        </td> -->
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="6">No parking spot found.</td>
                    </tr>
                </ng-template>
            </p-table>
		</div>
	</div>

    
</div>
