import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {APP_CONSTANTS} from '../constants/app.constants';
import {map, Observable} from 'rxjs';
import {TokenService} from './token.service';

/**
 * Houses Authentication related methods
 */
@Injectable()
export class AuthenticationService {

  constructor(private http: HttpClient, private tokenService: TokenService) {
  }

  /**
   * Authenticate the user
   * @param userInfo user information
   */
  authenticate(userObj: any): Observable<string> {
    let body = new URLSearchParams();
    body.set('grant_type',userObj.grant_type);
    body.set('username',userObj.username);
    body.set('password',userObj.password);
    return this.http.post(`${APP_CONSTANTS.BASE_API_URL}/oauth/token`, body.toString(), {
      headers: {
        'Authorization': 'Basic c3BvdG9uLWNsaWVudHM6c3BvdG9uLWNsaWVudHMtc2VjcmV0QDIwMjI=',
        'Content-Type': 'application/x-www-form-urlencoded'
      }
      // These params can be passed based on your need
    }).pipe(
      map(loginResp => {
        return this.strigifyToken(loginResp);
      })
    )
  }

  /**
   * Checks if user is authenticated
   */
  isAuthenticated(): boolean {
    // Dummy implementation can be improved upon to check for expiry/validity of the token
    const token = this.tokenService.getAccessToken();
    return token ? true : false;
  }

  /**
   * Strigify the login response object
   * @param loginResp login respose json
   */
  private strigifyToken(loginResp: any): string {
    return JSON.stringify(loginResp);
  }


  sendOtp(email):Observable<any>{
    return this.http.put(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/profile/forgotPassword`,email);
  }

  resetPassword(obj):Observable<any>{
    return this.http.put(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/profile/resetPassword`,obj);
  }

}
