import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';
import { IpagedQuery } from 'src/app/model/IpagedQuery';

@Injectable({
  providedIn: 'root'
})
export class ManageEntryExitService {

  constructor(private http: HttpClient) { }

  getEntryExitList(query:IpagedQuery,clientId?:any): Observable<any> {
    let params = new HttpParams({
      fromObject:{
        isActive:query.isActive.valueOf(),
        page:query.page.toString(),
        size:query.size.toString(),
        sort:'createdDate,desc'
      }
    })
    if(clientId){
      params = params.append("location.client.uuid",clientId.toString())
    }
    return this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/fleets/entryExits`,{params});
  }

  deleteEntryExit(clientId,entryExitId): Observable<any> {
    return this.http.delete(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/clients/${clientId}/locations/entryExits/${entryExitId}`);
  }
}
