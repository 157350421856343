import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-canvas-tool',
  templateUrl: './canvas-tool.component.html',
  styleUrls: ['./canvas-tool.component.scss']
})
export class CanvasToolComponent implements OnInit {

  title = 'CanvasTool';

  canvas:any="";
  context:any="";
  componentArea:any="";
  sidebar:any="";

  rectOverlay:any = "";
  arcOverlay:any = "";
  lineOverlay:any = "";
  textOverlay:any = "";
  spotOverlay:any = "";
  imgOverlay:any = "";


  spotLeftOverlay:any="";
  spotTopOverlay:any="";
  spotWidthOverlay:any="";
  spotHeightOverlay:any="";
  spotBorWidthOverlay:any="";
  spotBorColorOverlay:any="";
  spotTextOverlay:any="";
  spotFontSizeOverlay:any="";
  spotFontColorOverlay:any="";
  spotFontFamilyOverlay:any="";
  spotBackgroundOverlay:any="";

  spotVerHorOverlay:any="";

  lineStartLeftOverlay:any="";
  lineStartTopOverlay:any="";
  lineEndLeftOverlay:any="";
  lineEndTopOverlay:any="";
  lineLineWidthOverlay:any="";
  lineLineColorOverlay:any="";

  arcLeftOverlay:any="";
  arcTopOverlay:any="";
  arcRadiusOverlay:any="";
  arcDegOverlay:any="";
  arcBorWidthOverlay:any="";
  arcBorColOverlay:any="";
  arcBackgroundOverlay:any="";

  textInpOverlay:any="";
  textLeftOverlay:any="";
  textTopOverlay:any="";
  textFontSizeOverlay:any="";
  textFontColorOverlay:any="";
  textFontFamilyOverlay:any="";

  rectLeftOverlay:any="";
  rectTopOverlay:any="";
  rectWidthOverlay:any="";
  rectHeightOverlay:any="";
  rectBorWidthOverlay:any="";
  rectBorColOverlay:any="";
  rectBackgroundOverlay:any="";    
  
  imgLeftOverlay:any="";
  imgTopOverlay:any="";
  imgWidthOverlay:any="";
  imgHeightOverlay:any="";
  imgUrlOverlay:any="";

  uidname:any="";
  errorOverlay:any="";
  componentNameOverlay:any="";
  editArea:any="";

  constructor(public appMethods:AppComponent,
    private router:Router){
    
  }

  ngOnInit(){
    this.componentArea = document.getElementById("componentArea");

    this.sidebar = document.getElementById("sidebar");


    this.canvas=document.getElementById("canvas");
    this.context=this.canvas.getContext('2d');

    this.componentNameOverlay=document.getElementById("componentNameOverlay");

    this.errorOverlay=document.getElementById("errorOverlay");

    this.uidname=document.getElementById("uidname");

    this.rectOverlay = document.getElementById("rectOverlay");
    this.arcOverlay =  document.getElementById("arcOverlay");
    this.lineOverlay= document.getElementById("lineOverlay");
    this.textOverlay =  document.getElementById("textOverlay");
    this.spotOverlay =  document.getElementById("spotOverlay");
    this.imgOverlay = document.getElementById("imgOverlay");



    this.spotLeftOverlay=document.getElementById("spotLeftOverlay");
    this.spotTopOverlay=document.getElementById("spotTopOverlay");
    this.spotWidthOverlay=document.getElementById("spotWidthOverlay");
    this.spotHeightOverlay=document.getElementById("spotHeightOverlay");
    this.spotBorWidthOverlay=document.getElementById("spotBorWidthOverlay");
    this.spotBorColorOverlay=document.getElementById("spotBorColorOverlay");
    this.spotTextOverlay=document.getElementById("spotTextOverlay");
    this.spotFontSizeOverlay=document.getElementById("spotFontSizeOverlay");
    this.spotFontColorOverlay=document.getElementById("spotFontColorOverlay");
    this.spotFontFamilyOverlay=document.getElementById("spotFontFamilyOverlay");
    this.spotBackgroundOverlay=document.getElementById("spotBackgroundOverlay");

    this.spotVerHorOverlay=document.getElementById("spotVerHorOverlay");


    this.lineStartLeftOverlay=document.getElementById("lineStartLeftOverlay");
    this.lineStartTopOverlay=document.getElementById("lineStartTopOverlay");
    this.lineEndLeftOverlay=document.getElementById("lineEndLeftOverlay");
    this.lineEndTopOverlay=document.getElementById("lineEndTopOverlay");
    this.lineLineWidthOverlay=document.getElementById("lineLineWidthOverlay");
    this.lineLineColorOverlay=document.getElementById("lineLineColorOverlay");



    
    this.arcLeftOverlay=document.getElementById("arcLeftOverlay");
    this.arcTopOverlay=document.getElementById("arcTopOverlay");
    this.arcRadiusOverlay=document.getElementById("arcRadiusOverlay");
    this.arcDegOverlay=document.getElementById("arcDegOverlay");
    this.arcBorWidthOverlay=document.getElementById("arcBorWidthOverlay");
    this.arcBorColOverlay=document.getElementById("arcBorColOverlay");
    this.arcBackgroundOverlay=document.getElementById("arcBackgroundOverlay");



    this.textInpOverlay=document.getElementById("textInpOverlay");
    this.textLeftOverlay=document.getElementById("textLeftOverlay");
    this.textTopOverlay=document.getElementById("textTopOverlay");
    this.textFontSizeOverlay=document.getElementById("textFontSizeOverlay");
    this.textFontColorOverlay=document.getElementById("textFontColorOverlay");
    this.textFontFamilyOverlay=document.getElementById("textFontFamilyOverlay");


    this.rectLeftOverlay=document.getElementById("rectLeftOverlay");
    this.rectTopOverlay=document.getElementById("rectTopOverlay");
    this.rectWidthOverlay=document.getElementById("rectWidthOverlay");
    this.rectHeightOverlay=document.getElementById("rectHeightOverlay");
    this.rectBorWidthOverlay=document.getElementById("rectBorWidthOverlay");
    this.rectBorColOverlay=document.getElementById("rectBorColOverlay");
    this.rectBackgroundOverlay=document.getElementById("rectBackgroundOverlay"); 

    
    this.imgLeftOverlay=document.getElementById("imgLeftOverlay");
    this.imgTopOverlay=document.getElementById("imgTopOverlay");
    this.imgWidthOverlay=document.getElementById("imgWidthOverlay");
    this.imgHeightOverlay=document.getElementById("imgHeightOverlay");
    this.imgUrlOverlay=document.getElementById("imgUrlOverlay"); 


    this.editArea=document.getElementById("editArea");

    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.appMethods.finalJson=[];

  }

  canvasSize(a:boolean=false){
    var width:any = document.getElementById("canvasWidth");
    var height:any = document.getElementById("canvasHeight");
    if(width.value.trim()!=""){
      this.canvas.setAttribute("width",width.value.trim());
    }

    if(height.value.trim()!=""){
      this.canvas.setAttribute("height",height.value.trim());
    }

    if(a){
      this.previewNew(this.appMethods.finalJson,1);
    }
  }





  countElem:any=0;

  
  overlayStart(a:any,b:any=0){
    var overlay:any = document.getElementById("overlay");
    overlay.style.display = (a==1) ? "block" : "none";

    this.errorOverlay.innerHTML="";

    this.componentNameOverlay.style.display="flex";

    if(b==1){
      this.editingElem=false; 
      this.editingElemNo="";
    }else{
      this.uidname.value="";
      this.spotLeftOverlay.value="";
      this.spotTopOverlay.value="";
      this.spotWidthOverlay.value="30";
      this.spotHeightOverlay.value="70";
      this.spotBorWidthOverlay.value="1";
      this.spotBorColorOverlay.value="";
      this.spotTextOverlay.value="";
      this.spotFontSizeOverlay.value="20";
      this.spotFontColorOverlay.value="";
      this.spotFontFamilyOverlay.value="Nunito Sans";
      this.spotBackgroundOverlay.value="#ffffff";

      this.spotVerHorOverlay.value="vertical";



      this.lineStartLeftOverlay.value="";
      this.lineStartTopOverlay.value="";
      this.lineEndLeftOverlay.value="";
      this.lineEndTopOverlay.value="";
      this.lineLineWidthOverlay.value="";
      this.lineLineColorOverlay.value="";
       


      this.arcLeftOverlay.value="";
      this.arcTopOverlay.value="";
      this.arcRadiusOverlay.value="";
      this.arcDegOverlay.value="";
      this.arcBorWidthOverlay.value="";
      this.arcBorColOverlay.value="";
      this.arcBackgroundOverlay.value="#ffffff";



      this.textInpOverlay.value="";
      this.textLeftOverlay.value="";
      this.textTopOverlay.value="";
      this.textFontSizeOverlay.value="";
      this.textFontColorOverlay.value="";
      this.textFontFamilyOverlay.value="";


      
      this.rectLeftOverlay.value="";
      this.rectTopOverlay.value="";
      this.rectWidthOverlay.value="";
      this.rectHeightOverlay.value="";
      this.rectBorWidthOverlay.value="";
      this.rectBorColOverlay.value="";
      this.rectBackgroundOverlay.value="#ffffff";


      this.imgLeftOverlay.value="";
      this.imgTopOverlay.value="";
      this.imgWidthOverlay.value="";
      this.imgHeightOverlay.value="";
      this.imgUrlOverlay.value="";

      this.errorOverlay.innerHTML="";
    }

  }



  exportJson(){
    if(this.appMethods.finalJson.length>0){

      var width:any = document.getElementById("canvasWidth");
      var height:any = document.getElementById("canvasHeight");

      var zoom:any = document.getElementById("canvasZoom");

      if(String(this.selectedUId)!="" && String(this.selectedColor)!=""){
        this.appMethods.finalJson[this.selectedUId].borderWidth=this.selectedColor;
      }


      var json  = {"canvas":{"width":width.value,"height":height.value,"zoom":zoom.value},"components":this.appMethods.finalJson};


      var textFile:any = null,
      makeTextFile = function (text:any) {
        
        var data = new Blob([text], {type: 'text/plain'});
        
        if (textFile !== null) {
          window.URL.revokeObjectURL(textFile);
        }
    
        textFile = window.URL.createObjectURL(data);
        window.open(textFile,'_blank');
        return textFile;
      };
    
      makeTextFile(JSON.stringify(json));
    }

  }


  file:any;
fileChanged(e:any) {
    this.file = e.target.files[0];
    this.importJson();
  }

  import(){
   document.getElementById("selectJson")?.click(); 
  }


  mapArr:any=[{color:'#057203',statusVal:'Occupied'},{color:'#e68a00',statusVal:'To Be Occupied'},{color:'#ffff00',statusVal:'To Be Empty'},{color:'#ffffff',statusVal:'Empty'}];

  importedJson:any="";
  importJson(){
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

    this.appMethods.finalJson=[];
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.importedJson=JSON.parse(JSON.stringify(fileReader.result));
      var json = JSON.parse(this.importedJson);
      var width:any = document.getElementById("canvasWidth");
      var height:any = document.getElementById("canvasHeight");
      width.value=json.canvas.width;
      height.value=json.canvas.height;

      this.countElem=0;
      for(var i=0;i<json.components.length;i++){


        this.countElem++;
      }
      
      

      this.canvasSize();
       this.previewNew(json.components,0);
    }
    fileReader.readAsText(this.file);

    

  }


  
  
  canvasScale(a:any){
    var scaleInput:any = document.getElementById("scaleInput");
    var intScaleInput = parseFloat(scaleInput.value);

    
    if(a==1){
      if(intScaleInput < 1){
        intScaleInput+=0.2;
        this.canvas.style.transform="scale("+intScaleInput+","+intScaleInput+")";
        scaleInput.value=intScaleInput;
      }
    }
    else{
      if(intScaleInput>0.4){
        intScaleInput-=0.2;
        this.canvas.style.transform="scale("+intScaleInput+","+intScaleInput+")";

        scaleInput.value=intScaleInput;
      }
    }
  }

  keyPressPrevent(event:any){
    event.preventDefault();
    return false;
  }




  

keyPressNumbers(event:any) {
  var charCode = (event.which) ? event.which : event.keyCode;
  // Only Numbers 0-9
  if ((charCode < 48 || charCode > 57)) {
    event.preventDefault();
    return false;
  } else {
    return true;
  }
}

  components:any=[{name:"Spot",img:"./assets/canvastool/rect"},
                  {name:"Line",img:"./assets/canvastool/line"},
                  {name:"Arc",img:"./assets/canvastool/arc"},
                  {name:"Text",img:"./assets/canvastool/text"},
                  {name:"Rect",img:"./assets/canvastool/rect"},
                  {name:"Image",img:"./assets/canvastool/rect"}];



  selectedBtn:any="";               
  addComponentNew(a:any){

    var whichDiv:any="";
         
    if(this.selectedBtn!=""){

      if(this.editingElem){
        this.componentNameOverlay.style.display="none";
      }
       

      var preSelBtn:any= document.getElementById(this.selectedBtn+"-selBtn");
      preSelBtn.style.backgroundColor="#eee";
      preSelBtn.style.color="#000";

      if(this.selectedBtn.toLowerCase()=="spot") whichDiv=document.getElementById("spotOverlay");
      else if(this.selectedBtn.toLowerCase()=="line") whichDiv=document.getElementById("lineOverlay");
      else if(this.selectedBtn.toLowerCase()=="arc") whichDiv=document.getElementById("arcOverlay");
      else if(this.selectedBtn.toLowerCase()=="text") whichDiv=document.getElementById("textOverlay");
      else if(this.selectedBtn.toLowerCase()=="rect") whichDiv=document.getElementById("rectOverlay");
      else if(this.selectedBtn.toLowerCase()=="image") whichDiv=document.getElementById("imgOverlay");
      whichDiv.style.display="none";

      var selBtnImg:any = document.getElementById(this.selectedBtn+"-selBtn-img");
      selBtnImg.src="./assets/canvastool/"+this.selectedBtn.toLowerCase()+".png";
    }


    
      if(a.toLowerCase()=="spot") whichDiv=document.getElementById("spotOverlay");
      else if(a.toLowerCase()=="line") whichDiv=document.getElementById("lineOverlay");
      else if(a.toLowerCase()=="arc") whichDiv=document.getElementById("arcOverlay");
      else if(a.toLowerCase()=="text") whichDiv=document.getElementById("textOverlay");
      else if(a.toLowerCase()=="rect") whichDiv=document.getElementById("rectOverlay");      
      else if(a.toLowerCase()=="image") whichDiv=document.getElementById("imgOverlay");


    whichDiv.style.display="block";

    this.selectedBtn=a;
    this.uidname.value="";

    var selBtn:any= document.getElementById(a+"-selBtn");
    selBtn.style.backgroundColor="#363636";
    selBtn.style.color="#fff";

    var selBtnImg:any = document.getElementById(a+"-selBtn-img");
    selBtnImg.src="./assets/canvastool/"+a.toLowerCase()+"-white.png";
  }



  deleteComponent(){
    let text = "Are you sure to Delete the item named!";
      if (confirm(text) == true) {
        if (this.appMethods.finalJson.length > 0) {
          this.appMethods.finalJson.splice(0, 1); 
          this.previewNew(this.appMethods.finalJson,1);
          this.overlayStart(0);
        }
      }
  }

  nameCheck(){
    var matched:any="0";
    if(this.appMethods.finalJson.length>0){
      for(var i=0;i<this.appMethods.finalJson.length;i++){
        if(this.appMethods.finalJson[i].uidname==this.uidname.value){
          matched="1";
          break;
        }
      }  
    }
    
    return (matched!="0") ? true : false;
  }

  countElement:any=0;
  saveBtnOverlay(edit:boolean=false){
    
    if(!this.editingElem){
      if(this.nameCheck()){
        this.errorOverlay.innerHTML="Every Id should be different!";
        return;
      }
  
    }
    
    if(this.selectedBtn.toLowerCase()=="spot"){

      
      if(edit==false){
        this.countElement++;
        var width=(this.spotVerHorOverlay.value=="vertical") ? this.spotWidthOverlay.value : this.spotHeightOverlay.value ;
        var height=(this.spotVerHorOverlay.value=="vertical") ? this.spotHeightOverlay.value : this.spotWidthOverlay.value;


        this.appMethods.parkingLot(this.context,this.spotLeftOverlay.value,this.spotTopOverlay.value, width, height,this.spotBorWidthOverlay.value,this.spotBorColorOverlay.value,this.spotBackgroundOverlay.value,this.spotTextOverlay.value,this.spotFontSizeOverlay.value,this.spotFontFamilyOverlay.value,this.spotFontColorOverlay.value,0,this.uidname.value);  
      }else{
        var a = this.appMethods.finalJson[this.editingElemNo];
        a.left=this.spotLeftOverlay.value;
        a.top=this.spotTopOverlay.value;
        a.w=(this.spotVerHorOverlay.value=="vertical") ? "30" : "70";
        a.h=(this.spotVerHorOverlay.value=="vertical") ? "70" : "30";                
        a.borderWidth=this.spotBorWidthOverlay.value;
        a.borderColor=this.spotBorColorOverlay.value;
        a.text=this.spotTextOverlay.value;
        a.fontSize=this.spotFontSizeOverlay.value;
        a.color=this.spotFontColorOverlay.value;
        a.fontFamily=this.spotFontFamilyOverlay.value;
        a.bgColor=this.spotBackgroundOverlay .value;
        a.uidname=this.uidname.value;
        
        this.previewNew(this.appMethods.finalJson,1);
      }

    }
    else if(this.selectedBtn.toLowerCase()=="line"){

  
      if(edit==false){
        this.countElement++;
        this.appMethods.line(this.context,this.lineStartLeftOverlay.value,this.lineStartTopOverlay.value,this.lineEndLeftOverlay.value,this.lineEndTopOverlay.value,this.lineLineWidthOverlay.value,this.lineLineColorOverlay.value,0,this.uidname.value)
      }else{
        var a = this.appMethods.finalJson[this.editingElemNo];
        a.left1=this.lineStartLeftOverlay.value;
        a.top1=this.lineStartTopOverlay.value;
        a.left2=this.lineEndLeftOverlay.value;
        a.top2=this.lineEndTopOverlay.value;
        a.borderWidth=this.lineLineWidthOverlay.value;
        a.borderColor=this.lineLineColorOverlay.value;
        a.uidname=this.uidname.value;

        this.previewNew(this.appMethods.finalJson,1);
      }
      

    }
    else if(this.selectedBtn.toLowerCase()=="arc"){


      if(edit==false){
        this.countElement++;
        this.appMethods.arc(this.context,this.arcLeftOverlay.value,this.arcTopOverlay.value,this.arcRadiusOverlay.value,this.arcDegOverlay.value,this.arcBorWidthOverlay.value,this.arcBorColOverlay.value,this.arcBackgroundOverlay.value,0,this.uidname.value);  
      }
      else{
        var a = this.appMethods.finalJson[this.editingElemNo];
        a.left=this.arcLeftOverlay.value;
        a.top=this.arcTopOverlay.value;
        a.r=this.arcRadiusOverlay.value;
        a.deg=this.arcDegOverlay.value;
        a.borderWidth=this.arcBorWidthOverlay.value;
        a.borderColor=this.arcBorColOverlay.value;
        a.bgColor=this.arcBackgroundOverlay.value;
        a.uidname=this.uidname.value;

        this.previewNew(this.appMethods.finalJson,1);
      }
    }
    else if(this.selectedBtn.toLowerCase()=="text"){


      if(edit==false){
        this.countElement++;
        this.appMethods.text(this.context,this.textLeftOverlay.value,this.textTopOverlay.value,this.textInpOverlay.value,this.textFontSizeOverlay.value,this.textFontFamilyOverlay.value,this.textFontColorOverlay.value,0,this.uidname.value);
      }else{
        var a = this.appMethods.finalJson[this.editingElemNo];
        a.text=this.textInpOverlay.value;
        a.left=this.textLeftOverlay.value;
        a.top=this.textTopOverlay.value;
        a.fontSize=this.textFontSizeOverlay.value;
        a.color=this.textFontColorOverlay.value;
        a.fontFamily=this.textFontFamilyOverlay.value;
        a.uidname=this.uidname.value;
 
        this.previewNew(this.appMethods.finalJson,1);
      }

    }
    else if(this.selectedBtn.toLowerCase()=="rect"){


      if(edit==false){
        this.countElement++;
        this.appMethods.rect(this.context,this.rectLeftOverlay.value,this.rectTopOverlay.value,this.rectWidthOverlay.value,this.rectHeightOverlay.value,this.rectBorWidthOverlay.value,this.rectBorColOverlay.value,this.rectBackgroundOverlay.value,0,this.uidname.value);

      }else{
        var a = this.appMethods.finalJson[this.editingElemNo];
        a.left=this.rectLeftOverlay.value;
        a.top=this.rectTopOverlay.value;
        a.w=this.rectWidthOverlay.value;
        a.h=this.rectHeightOverlay.value;
        a.borderWidth=this.rectBorWidthOverlay.value;
        a.borderColor=this.rectBorColOverlay.value;
        a.bgColor=this.rectBackgroundOverlay.value;
        a.uidname=this.uidname.value;

        this.previewNew(this.appMethods.finalJson,1);
      }
      

    }
    else if(this.selectedBtn.toLowerCase()=="image"){

      if(edit==false){
        this.countElement++;
        this.appMethods.drawImg(this.context,this.imgLeftOverlay.value,this.imgTopOverlay.value,this.imgWidthOverlay.value,this.imgHeightOverlay.value,this.imgUrlOverlay.value,0,this.uidname.value);
 
      }else{
        var a = this.appMethods.finalJson[this.editingElemNo];
        a.left=this.imgLeftOverlay.value;
        a.top=this.imgTopOverlay.value;
        a.w=this.imgWidthOverlay.value;
        a.h=this.imgHeightOverlay.value;
        a.url=this.imgUrlOverlay.value;
        a.uidname=this.uidname.value;
        
        this.previewNew(this.appMethods.finalJson,1);
      }
      

    }

    this.overlayStart(0);
  }




  previewNew(json:any,a:any){
    
    this.countElem=0;
    this.canvas.width+=0;
    for(var i=0;i<json.length;i++){

      if(json[i].component=="RECT")  this.appMethods.rect(this.context,json[i].left,json[i].top,json[i].w,json[i].h,json[i].borderWidth,json[i].borderColor,json[i].bgColor,a,json[i].uidname);

      else if(json[i].component=="LINE") this.appMethods.line(this.context,json[i].left1,json[i].top1,json[i].left2,json[i].top2,"1",json[i].borderColor,a,json[i].uidname);

      else if(json[i].component=="ARC") this.appMethods.arc(this.context,json[i].left,json[i].top,json[i].r,json[i].deg,json[i].borderWidth,json[i].borderColor,json[i].bgColor,a,json[i].uidname);
      else if(json[i].component=="TEXT") this.appMethods.text(this.context,json[i].left,json[i].top,json[i].text,json[i].fontSize,json[i].fontFamily,json[i].color,a,json[i].uidname);
      else if(json[i].component=="SPOT") this.appMethods.parkingLot(this.context,json[i].left,json[i].top,json[i].w,json[i].h,json[i].borderWidth,json[i].borderColor,json[i].bgColor,json[i].text,json[i].fontSize,json[i].fontFamily,json[i].color,a,json[i].uidname);
      else if(json[i].component=="IMAGE") this.appMethods.drawImg(this.context,json[i].left,json[i].top,json[i].w,json[i].h,json[i].url,a,json[i].uidname);
      
      this.countElem++;
    }

  }

  editingElemNo:any="";
  editingElem:boolean=false;
  editElement(a:any){

    this.overlayStart(1);
    this.editingElem=true;
    var whichElem = this.appMethods.finalJson[a];

    this.editingElemNo=a;

    let first = whichElem.component.toLowerCase().substr(0,1).toUpperCase();
    first += whichElem.component.toLowerCase().substr(1); 
  
    this.addComponentNew(first);

    if(whichElem.component.toLowerCase()=="spot"){


      this.spotLeftOverlay.value=(whichElem.left==undefined) ? "" : whichElem.left;
      this.spotTopOverlay.value=(whichElem.top==undefined) ? "" : whichElem.top;
      this.spotWidthOverlay.value=(whichElem.w==undefined) ? "" : whichElem.w;
      this.spotHeightOverlay.value=(whichElem.h==undefined) ? "" : whichElem.h;
      
      this.spotBorWidthOverlay.value=(whichElem.borderWidth==undefined) ? "" : (parseInt(this.selectedUId)==parseInt(a) && this.selectedColor!="") ? this.selectedColor : whichElem.borderWidth;


      this.spotBorColorOverlay.value=(whichElem.borderColor==undefined) ? "" : whichElem.borderColor;
      this.spotTextOverlay.value=(whichElem.text==undefined) ? "" : whichElem.text;
      this.spotFontSizeOverlay.value=(whichElem.fontSize==undefined) ? "" : whichElem.fontSize;
      this.spotFontColorOverlay.value=(whichElem.color==undefined) ? "" : whichElem.color;
      this.spotFontFamilyOverlay.value=(whichElem.fontFamily==undefined) ? "" : whichElem.fontFamily;
      
      this.spotBackgroundOverlay.value=(whichElem.bgColor==undefined) ? "" :  whichElem.bgColor;
      this.uidname.value=whichElem.uidname;

      this.spotVerHorOverlay.value= (whichElem.w=="70") ? "horizontal" : "vertical";

      
    }else if(whichElem.component.toLowerCase()=="line"){
 
      this.lineStartLeftOverlay.value=whichElem.left1;
      this.lineStartTopOverlay.value=whichElem.top1;
      this.lineEndLeftOverlay.value=whichElem.left2;
      this.lineEndTopOverlay.value=whichElem.top2;
      this.lineLineWidthOverlay.value=whichElem.borderWidth;
      this.lineLineColorOverlay.value=whichElem.borderColor;
      this.uidname.value=whichElem.uidname;

    }
    else if(whichElem.component.toLowerCase()=="arc"){


      this.arcLeftOverlay.value=whichElem.left;
      this.arcTopOverlay.value=whichElem.top;
      this.arcRadiusOverlay.value=whichElem.r;
      this.arcDegOverlay.value=whichElem.deg;
      this.arcBorWidthOverlay.value=whichElem.borderWidth;
      this.arcBorColOverlay.value=whichElem.borderColor;
      this.arcBackgroundOverlay.value= (parseInt(this.selectedUId)==parseInt(a) && this.selectedColor!="") ? this.selectedColor : whichElem.bgColor;
      this.uidname.value=whichElem.uidname;


    } else if(whichElem.component.toLowerCase()=="text"){


      this.textInpOverlay.value=whichElem.text;
      this.textLeftOverlay.value=whichElem.left;
      this.textTopOverlay.value=whichElem.top;
      this.textFontSizeOverlay.value=whichElem.fontSize;
      this.textFontColorOverlay.value=whichElem.color;
      this.textFontFamilyOverlay.value=whichElem.fontFamily;
      this.uidname.value=whichElem.uidname;


    }
    else if(whichElem.component.toLowerCase()=="rect"){
      
      this.rectLeftOverlay.value=whichElem.left;
      this.rectTopOverlay.value=whichElem.top;
      this.rectWidthOverlay.value=whichElem.w;
      this.rectHeightOverlay.value=whichElem.h;
      this.rectBorWidthOverlay.value=whichElem.borderWidth;
      this.rectBorColOverlay.value=whichElem.borderColor;
      this.rectBackgroundOverlay.value= (parseInt(this.selectedUId)==parseInt(a) && this.selectedColor!="") ? this.selectedColor : whichElem.bgColor;
      this.uidname.value=whichElem.uidname;


    }
    else if(whichElem.component.toLowerCase()=="image"){
      
      this.imgLeftOverlay.value=whichElem.left;
      this.imgTopOverlay.value=whichElem.top;
      this.imgWidthOverlay.value=whichElem.w;
      this.imgHeightOverlay.value=whichElem.h;
      this.imgUrlOverlay.value=whichElem.url;
      this.uidname.value=whichElem.uidname;


    }




  }



  hit(rect:any, x:any, y:any) {
    return (x >= parseInt(rect.left) && x <= parseInt(rect.left) + parseInt(rect.w) && y >= parseInt(rect.top) && y <= parseInt(rect.top) + parseInt(rect.h));
  }


selectedUId:any="";

selectedColor:any="";

handleMouseClick(e:any) {
  
  if(this.appMethods.finalJson.length>0){

    var offsetX:number = this.canvas.offsetLeft;
      var offsetY:any = this.canvas.offsetTop;

      e.preventDefault();
      

      var mouseX:number = e.offsetX;//e.clientX - offsetX;
      var mouseY = e.offsetY;//e.clientY - offsetY;

      
        if(String(this.selectedUId)!=""){
          
          this.appMethods.finalJson[this.selectedUId]['borderWidth']=this.selectedColor;

          this.selectedUId="";
          this.selectedColor="";

          this.editArea.style.display="none";
          
        }
      for(var i=0;i<this.appMethods.finalJson.length;i++){
        var locJson=this.appMethods.finalJson[i];

        var fontSizeJson=this.appMethods.fontSize(this.appMethods.finalJson[i].text,this.appMethods.finalJson[i].fontSize);

        if(this.appMethods.finalJson[i].component.toLowerCase()=="text"){
          locJson={left:this.appMethods.finalJson[i].left,top:this.appMethods.finalJson[i].top,w:fontSizeJson.width,h:fontSizeJson.height};
        }

        
        if(this.hit(locJson,mouseX,mouseY)){
          if(String(this.selectedUId)!=""){

            if(parseFloat(this.appMethods.finalJson[i].w)<parseFloat(this.appMethods.finalJson[this.selectedUId].w) || parseFloat(this.appMethods.finalJson[i].h)<parseFloat(this.appMethods.finalJson[this.selectedUId].h)){
            
              
              this.appMethods.finalJson[this.selectedUId].borderWidth=this.selectedColor;
              
              
              this.selectedUId=i;
              
              this.selectedColor=this.appMethods.finalJson[i].borderWidth;
              this.appMethods.finalJson[i].borderWidth="5";


            }else if(parseFloat(this.appMethods.finalJson[i].w)==parseFloat(this.appMethods.finalJson[this.selectedUId].w) && parseFloat(this.appMethods.finalJson[i].h)==parseFloat(this.appMethods.finalJson[this.selectedUId].h)){
              if(i>this.selectedUId){
                this.appMethods.finalJson[this.selectedUId].borderWidth=this.selectedColor;

                this.selectedUId=i;
                
                this.selectedColor=this.appMethods.finalJson[i].borderWidth;
                this.appMethods.finalJson[i].borderWidth="5";
              }
            }
            
          }
          else if(this.selectedColor==""){
            
              this.selectedUId=i;

              this.selectedColor=this.appMethods.finalJson[i].borderWidth;
              this.appMethods.finalJson[i].borderWidth="5";
          }

          var uidnameShow:any = document.getElementById("uidnameShow");
          uidnameShow.innerHTML=this.appMethods.finalJson[i].uidname;
        }
        
        
      }
      
      if(String(this.selectedUId)!=""){
        this.editArea.style.display="flex";
      }

      this.previewNew(this.appMethods.finalJson,1);
    }
  }


  whichabn:any="";
  abn:boolean=false;
  handlekeyDown(e:any){
    if(this.appMethods.finalJson.length>0){

      this.abn=true;

      var offsetX:number = this.canvas.offsetLeft;
      var offsetY:any = this.canvas.offsetTop;
      e.preventDefault();
      var mouseX:number = e.offsetX;//e.clientX - offsetX;
      var mouseY = e.offsetY;//e.clientY - offsetY;

      for(var i=0;i<this.appMethods.finalJson.length;i++){

        var locJson=this.appMethods.finalJson[i];
        var fontSizeJson=this.appMethods.fontSize(this.appMethods.finalJson[i].text,this.appMethods.finalJson[i].fontSize);

        if(this.appMethods.finalJson[i].component.toLowerCase()=="text"){
          locJson={left:this.appMethods.finalJson[i].left,top:this.appMethods.finalJson[i].top,w:fontSizeJson.width,h:fontSizeJson.height};

        }

        if(this.hit(locJson,mouseX,mouseY)){
          

            if(this.whichabn!=""){
              if(parseFloat(this.appMethods.finalJson[i].w)<parseFloat(this.appMethods.finalJson[this.whichabn].w) || parseFloat(this.appMethods.finalJson[i].h)<parseFloat(this.appMethods.finalJson[this.whichabn].h)){

                this.whichabn=i;

              }else{
                  this.whichabn=i;
              
              }
            }else{
              this.whichabn=i;
            }
        }
        

      }
      this.previewNew(this.appMethods.finalJson,1);
    }
  }

  handlekeymove(e:any){
    if(this.appMethods.finalJson.length>0){

      if(this.abn && this.whichabn!=""){
        this.appMethods.finalJson[this.whichabn].left=String(e.offsetX-20);
        this.appMethods.finalJson[this.whichabn].top=String(e.offsetY-20);
      }  
      this.previewNew(this.appMethods.finalJson,1);
    }
  }

  handlekeyup(e:any){
    if(this.appMethods.finalJson.length>0){
      this.whichabn="";
      this.abn=false;
    }
  }


  above(){
    if(String(this.selectedUId)!=""){
      var abnn = this.appMethods.finalJson[this.selectedUId];
      this.appMethods.finalJson.splice(this.selectedUId, 1);

      this.selectedUId=this.appMethods.finalJson.length;
      this.appMethods.finalJson[this.appMethods.finalJson.length]=abnn;
      this.whichabn="";
      this.previewNew(this.appMethods.finalJson,1);
    }
  }

  
  below(){
    if(String(this.selectedUId)!=""){
      var abnn = this.appMethods.finalJson[this.selectedUId];
      this.appMethods.finalJson.splice(this.selectedUId, 1); 
      
      this.selectedUId=0;
      this.appMethods.finalJson.splice(0, 0, abnn);
      this.whichabn="";
      this.previewNew(this.appMethods.finalJson,1);
    } 
  }

  copyandPaste(){
    if(String(this.selectedUId)!=""){
      var abnn:any = JSON.stringify(this.appMethods.finalJson[this.selectedUId]);
      abnn = JSON.parse(abnn);
      var uid = abnn.uidname;
    
      var j=-1;
      for(var i =0;i<this.appMethods.finalJson.length;i++){
        if(this.appMethods.finalJson[i].uidname==uid){
          j+=1;
        }
      }

      
        if(j==0){
          uid+="-copy";

        }else{
          uid+="-copy"+String(j);
        }
      
      
      abnn.uidname=uid;
      abnn.left="0";
      abnn.top="0";
      abnn.borderWidth=this.selectedColor;


      this.appMethods.finalJson[this.appMethods.finalJson.length]=abnn;
      this.previewNew(this.appMethods.finalJson,1);

    }
  }

  delete(){
    if(String(this.selectedUId)!=""){
      this.appMethods.finalJson.splice(this.selectedUId, 1); 
      this.selectedUId="";
      this.selectedColor="";
      this.whichabn="";
      this.previewNew(this.appMethods.finalJson,1);

    }
  }

  edit(){
    this.editElement(this.selectedUId);
  }

  routeTodashboard(){
    this.router.navigate(['main']);
  }
}

