<div id="Test"></div>

<div id="overlay" style="">

  <div class="overlayBack">
    <div >
      <div class="closeOverlay" (click)="overlayStart(0,1)">x</div>
    </div>

    <div style="text-align: center; font-size: 25px; font-weight: bold;font-family:sans-serif;position:relative;top:20px;">
      Component
    </div>

    <div id="componentNameOverlay" style="display: flex;margin-top:30px;justify-content:center;">
      
      <div class="componentSelector pointer" *ngFor="let component of components" id="{{component.name}}-selBtn" (click)="addComponentNew(component.name)" style="background-color: #eee;">
          <img id="{{component.name}}-selBtn-img" src="{{component.img}}.png" style="width: 20px; height: 20px;position:relative;top:1px;"/>
          <label class="sidebarLabel" style="margin-left: 5px;">{{component.name}}</label>  
      </div>

    </div>

    <div class="mrgt-25 mrgl-25 mrgr-25">
      <div style="display: flex;align-items: center;justify-content: space-between;">
        <div>
          <label class="sidebarLabel" style="font-size:20px;position: relative;top:3px;">ID -</label>
          <input type="text" id="uidname" class="addComponentOverlay" style="width:100px;text-align: center;"/>
        </div>
        
        <div class="sidebarLabel" *ngIf="editingElem" (click)="deleteComponent()" style="display:flex;align-items: center;">
          <img  src="./assets/canvastool/delete.png" style="width: 20px; height: 20px;position:relative;top:1px;"/>
          <label class="sidebarLabel" style="margin-left: 5px;">Delete</label>  
        </div>
      </div> 
  

      <!--Spot Overlay-->
      <div id="spotOverlay" style="display: none;">
        <div class="mrgt-20" style="display:flex;justify-content: center">
          
          <div class="rectfArea">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Left</label></div>
            <div class="mrgt-5">
              <input type="text" id="spotLeftOverlay" class="addComponentOverlay" (keypress)="keyPressNumbers($event)" value="0" style="width:100px;text-align: center;"/>
            </div>
          </div>

          <div class="rectfArea">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Top</label></div>
            <div class="mrgt-5">
              <input type="text" id="spotTopOverlay" class="addComponentOverlay" (keypress)="keyPressNumbers($event)" value="0" style="width:100px;text-align: center;"/>
            </div>
          </div>

          <div class="rectfArea" style="display: none;">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Width</label></div>
            <div class="mrgt-5">
              <input type="text" id="spotWidthOverlay" class="addComponentOverlay" (keypress)="keyPressNumbers($event)" value="30" style="width:100px;text-align: center;" disabled/>
            </div>
          </div>

          <div class="rectfArea" style="display: none;">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Height</label></div>
            <div class="mrgt-5">
              <input type="text" id="spotHeightOverlay" class="addComponentOverlay" (keypress)="keyPressNumbers($event)" value="70" style="width:100px;text-align: center;" disabled/>
            </div>
          </div>

          <div  class="rectfArea">
            <div class="" style="margin-top:27px;">
              <select id="spotVerHorOverlay" class="addComponentOverlay" style="padding:5px;">
                <option value="vertical" selected="true">Vertical</option>
                <option value="horizontal">Horizontal</option>
              </select>
            </div>
          </div>


        </div>


        <div class="mrgt-20" style="display:flex;">

          <div class="rectsArea">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Border Width</label></div>
            <div class="mrgt-5">
              <input type="text" id="spotBorWidthOverlay" class="addComponentOverlay" (keypress)="keyPressNumbers($event)" value="1" style="width:100px;text-align: center;"/>
            </div>

          </div>
          <div class="rectsArea">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Border Color</label></div>
            <div class="mrgt-5">
              <input type="color" id="spotBorColorOverlay" class="addComponentOverlay"  style="width:100px;text-align: center;"/>
            </div>
          </div>

        </div>


        <div class="mrgt-20" >
            
          <div class="rectfArea">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Text</label></div>
            <div class="mrgt-5">
              <input type="text" id="spotTextOverlay" class="addComponentOverlay" style="text-align: center;width:100%;"/>
            </div>
          </div>
        </div>


        <div class="mrgt-20" style="display:flex;">

          <div class="rectsArea">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Background</label></div>
            <div class="mrgt-5">
              <input type="color" id="spotBackgroundOverlay" class="addComponentOverlay" style="width:100px;text-align: center;"/>
            </div>

          </div>

          <div class="rectsArea">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Font Color</label></div>
            <div class="mrgt-5">
              <input type="color" id="spotFontColorOverlay" class="addComponentOverlay" style="width:100px;text-align: center;"/>
            </div>
          </div>

        </div>


        <div class="mrgt-20" style="display:none;">

          <div class="rectsArea" style="display:none;">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Font Family</label></div>
            <div class="mrgt-5">
              <input type="text" id="spotFontFamilyOverlay" class="addComponentOverlay" value="Nunito Sans" style="width:100px;text-align: center;" disabled/>
            </div>
          </div>
          <div class="rectsArea" style="display:none;">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Font Size</label></div>
            <div class="mrgt-5">
              <input type="text" id="spotFontSizeOverlay" class="addComponentOverlay" (keypress)="keyPressNumbers($event)" value="20" style="width:100px;text-align: center;" disabled/>
            </div>

          </div>


        </div>
      </div>
      <!--/Spot Overlay-->

      
      <!--Line Overlay-->
      <div id="lineOverlay" style="display: none;">
        <div class="mrgt-20" style="display:flex;justify-content: center">
          
          <div class="rectfArea">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Start Left</label></div>
            <div class="mrgt-5">
              <input type="text" id="lineStartLeftOverlay" class="addComponentOverlay" (keypress)="keyPressNumbers($event)" style="width:100px;text-align: center;"/>
            </div>
          </div>

          <div class="rectfArea">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Start Top</label></div>
            <div class="mrgt-5">
              <input type="text" id="lineStartTopOverlay" class="addComponentOverlay" (keypress)="keyPressNumbers($event)" style="width:100px;text-align: center;"/>
            </div>
          </div>

          <div class="rectfArea">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">End Left</label></div>
            <div class="mrgt-5">
              <input type="text" id="lineEndLeftOverlay" class="addComponentOverlay" (keypress)="keyPressNumbers($event)" style="width:100px;text-align: center;"/>
            </div>
          </div>

          <div class="rectfArea">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">End Top</label></div>
            <div class="mrgt-5">
              <input type="text" id="lineEndTopOverlay" class="addComponentOverlay" (keypress)="keyPressNumbers($event)" style="width:100px;text-align: center;"/>
            </div>
          </div>


        </div>


        <div class="mrgt-20" style="display:flex;">

          <div class="rectsArea">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Line Width</label></div>
            <div class="mrgt-5">
              <input type="text" id="lineLineWidthOverlay" class="addComponentOverlay" (keypress)="keyPressNumbers($event)" value="1" style="width:100px;text-align: center;"/>
            </div>

          </div>
          <div class="rectsArea">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Line Color</label></div>
            <div class="mrgt-5">
              <input type="color" id="lineLineColorOverlay" class="addComponentOverlay" style="width:100px;text-align: center;"/>
            </div>
          </div>

        </div>


      </div>
      <!--/Line Overlay-->

    
      <!--Arc Overlay-->
      <div id="arcOverlay" style="display: none;">
        <div class="mrgt-20" style="display:flex;justify-content: center">
          
          <div class="rectfArea">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Left</label></div>
            <div class="mrgt-5">
              <input type="text" id="arcLeftOverlay" class="addComponentOverlay" (keypress)="keyPressNumbers($event)" value="0" style="width:100px;text-align: center;"/>
            </div>
          </div>

          <div class="rectfArea">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Top</label></div>
            <div class="mrgt-5">
              <input type="text" id="arcTopOverlay" class="addComponentOverlay" (keypress)="keyPressNumbers($event)" value="0" style="width:100px;text-align: center;"/>
            </div>
          </div>

          <div class="rectfArea">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Radius</label></div>
            <div class="mrgt-5">
              <input type="text" id="arcRadiusOverlay" class="addComponentOverlay" (keypress)="keyPressNumbers($event)" value="10" style="width:100px;text-align: center;"/>
            </div>
          </div>

          <div class="rectfArea">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Degree</label></div>
            <div class="mrgt-5">
              <input type="text" id="arcDegOverlay" class="addComponentOverlay" (keypress)="keyPressNumbers($event)" value="360" style="width:100px;text-align: center;"/>
            </div>
          </div>


        </div>


        <div class="mrgt-20" style="display:flex;">

          <div class="rectsArea">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Border Width</label></div>
            <div class="mrgt-5">
              <input type="text" id="arcBorWidthOverlay" class="addComponentOverlay" (keypress)="keyPressNumbers($event)" value="1" style="width:100px;text-align: center;"/>
            </div>

          </div>
          <div class="rectsArea">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Border Color</label></div>
            <div class="mrgt-5">
              <input type="color" id="arcBorColOverlay" class="addComponentOverlay" style="width:100px;text-align: center;"/>
            </div>
          </div>

        </div>


        <div class="mrgt-20" style="display:flex;">

          <div class="rectsArea">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Background</label></div>
            <div class="mrgt-5">
              <input type="color" id="arcBackgroundOverlay" class="addComponentOverlay" style="width:100px;text-align: center;"/>
            </div>

          </div>
          <div class="rectsArea"></div>

        </div>

      </div>
      <!--/Arc Overlay-->

      
      <!--Text Overlay-->
      <div id="textOverlay" style="display: none;">

          <div class="mrgt-20" >
            
            <div class="rectfArea">
              <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Text</label></div>
              <div class="mrgt-5">
                <input type="text" id="textInpOverlay" class="addComponentOverlay"  style="text-align: center;width:100%;"/>
              </div>
            </div>
          </div>

          <div class="mrgt-20" style="display:flex;justify-content: center">
            
            <div class="rectfArea">
              <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Left</label></div>
              <div class="mrgt-5">
                <input type="text" id="textLeftOverlay" class="addComponentOverlay" (keypress)="keyPressNumbers($event)" value="0" style="width:100px;text-align: center;"/>
              </div>
            </div>
  
            <div class="rectfArea">
              <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Top</label></div>
              <div class="mrgt-5">
                <input type="text" id="textTopOverlay" class="addComponentOverlay" (keypress)="keyPressNumbers($event)" value="0" style="width:100px;text-align: center;"/>
              </div>
            </div>
  
  
  
          </div>
  
  
          <div class="mrgt-20" style="display:flex;">
  
            <div class="rectsArea">
              <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Font Size</label></div>
              <div class="mrgt-5">
                <input type="text" id="textFontSizeOverlay" class="addComponentOverlay" (keypress)="keyPressNumbers($event)" value="12" style="width:100px;text-align: center;"/>
              </div>
  
            </div>
            <div class="rectsArea">
              <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Font Family</label></div>
              <div class="mrgt-5">
                <input type="text" id="textFontFamilyOverlay" class="addComponentOverlay" value="Nunito Sans" style="width:100px;text-align: center;"/>
              </div>
            </div>
  
          </div>
  
  
          <div class="mrgt-20" style="display:flex;">
  
            <div class="rectsArea">
              <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Font Color</label></div>
              <div class="mrgt-5">
                <input type="color" id="textFontColorOverlay" class="addComponentOverlay" style="width:100px;text-align: center;"/>
              </div>
  
            </div>
            <div class="rectsArea"></div>
  
          </div>
  
      </div>
      <!--/Text Overlay-->


      <!--Rect Overlay-->
      <div id="rectOverlay" style="display: none;">
        <div class="mrgt-20" style="display:flex;justify-content: center">
          
          <div class="rectfArea">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Left</label></div>
            <div class="mrgt-5">
              <input type="text" id="rectLeftOverlay" class="addComponentOverlay" (keypress)="keyPressNumbers($event)" value="0" style="width:100px;text-align: center;"/>
            </div>
          </div>

          <div class="rectfArea">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Top</label></div>
            <div class="mrgt-5">
              <input type="text" id="rectTopOverlay" class="addComponentOverlay" (keypress)="keyPressNumbers($event)" value="0" style="width:100px;text-align: center;"/>
            </div>
          </div>

          <div class="rectfArea">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Width</label></div>
            <div class="mrgt-5">
              <input type="text" id="rectWidthOverlay" class="addComponentOverlay" (keypress)="keyPressNumbers($event)" style="width:100px;text-align: center;"/>
            </div>
          </div>

          <div class="rectfArea">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Height</label></div>
            <div class="mrgt-5">
              <input type="text" id="rectHeightOverlay" class="addComponentOverlay" (keypress)="keyPressNumbers($event)" style="width:100px;text-align: center;"/>
            </div>
          </div>


        </div>


        <div class="mrgt-20" style="display:flex;">

          <div class="rectsArea">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Border Width</label></div>
            <div class="mrgt-5">
              <input type="text" id="rectBorWidthOverlay" class="addComponentOverlay" (keypress)="keyPressNumbers($event)" value="1" style="width:100px;text-align: center;"/>
            </div>

          </div>
          <div class="rectsArea">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Border Color</label></div>
            <div class="mrgt-5">
              <input type="color" id="rectBorColOverlay" class="addComponentOverlay" style="width:100px;text-align: center;"/>
            </div>
          </div>

        </div>



        <div class="mrgt-20" style="display:flex;">

          <div class="rectsArea">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Background</label></div>
            <div class="mrgt-5">
              <input type="color" id="rectBackgroundOverlay" class="addComponentOverlay" style="width:100px;text-align: center;"/>
            </div>

          </div>
          <div class="rectsArea"></div>

        </div>
      </div>
      <!--/Rect Overlay-->


      <!--Image Overlay-->
      <div id="imgOverlay" style="display: none;">
        <div class="mrgt-20" style="display:flex;justify-content: center">
          
          <div class="rectfArea">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Left</label></div>
            <div class="mrgt-5">
              <input type="text" id="imgLeftOverlay" class="addComponentOverlay" (keypress)="keyPressNumbers($event)" value="0" style="width:100px;text-align: center;"/>
            </div>
          </div>

          <div class="rectfArea">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Top</label></div>
            <div class="mrgt-5">
              <input type="text" id="imgTopOverlay" class="addComponentOverlay" (keypress)="keyPressNumbers($event)" value="0" style="width:100px;text-align: center;"/>
            </div>
          </div>

          <div class="rectfArea">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Width</label></div>
            <div class="mrgt-5">
              <input type="text" id="imgWidthOverlay" class="addComponentOverlay" (keypress)="keyPressNumbers($event)" style="width:100px;text-align: center;"/>
            </div>
          </div>

          <div class="rectfArea">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">Height</label></div>
            <div class="mrgt-5">
              <input type="text" id="imgHeightOverlay" class="addComponentOverlay" (keypress)="keyPressNumbers($event)" style="width:100px;text-align: center;"/>
            </div>
          </div>


        </div>




        <div class="mrgt-20" >

          <div class="">
            <div><label class="sidebarLabel" style="font-size:18px;position: relative;top:3px;">URL</label></div>
            <div class="mrgt-5">
              <input type="text" id="imgUrlOverlay" class="addComponentOverlay" style="width:100%;text-align: center;"/>
            </div>

          </div>
          <div class="rectsArea"></div>

        </div>
      </div>
      <!--/Image Overlay-->
      


      <div class="error" style="text-align: center;margin-top:10px;" id="errorOverlay"></div>

    </div>
    <div class="saveBtnOverlay" (click)="saveBtnOverlay(editingElem)">
      SAVE
    </div>

    <div>

    </div>



  </div>

</div>

<div style="position:fixed;bottom:0px;width:100%;display: none;">
  <div style="background-color: #ddd;height: 80px;border:15px;margin-left:250px;margin-top:15px;display: flex;align-items: center; ">
                
    <div *ngFor="let arr of mapArr" style="display: flex;align:items;margin-left:20px;font-weight: 700;">
        <div style="width:20px;height:20px;border:1px solid #000;background-color: {{arr.color}};"></div>
        <div style="margin-left:7px;margin-top:2px;">{{arr.statusVal}}</div>
    </div>
    
  </div>
</div>


<!--Header-->
<div class="header">
  <input type="file" class="componentAreaBtn" (change)="fileChanged($event)" id="selectJson" style="display: none;"/>

  <div style="font-size:30px;font-weight:bold;font-family:sans-serif">
    <img (click)="routeTodashboard()" src="./assets/logo/spoton_vector.svg" alt="logo" style="height:37px;margin-top:5px;cursor: pointer;"/>
  </div>   

  <div style="border:0px solid red;display:flex;justify-content: space-between;width:50%;align-items: center;">
    <div style="display: flex;">
      <div class="pointer" (click)="import()">
        <img src="./assets/canvastool/import.png" style="width: 20px;height: 20px;position:relative;top: 4px;" />
        <label class="sidebarLabel pointer" style="font-size: 16px;">Import</label>
      </div>
      <div class="pointer mrgl-50" (click)="exportJson()">
        <img src="./assets/canvastool/export.png" style="width: 20px;height: 20px;position:relative;top: 4px;"/>
        <label class="sidebarLabel pointer" style="font-size: 16px;">Export</label>
      </div>
    </div>
  
    <div>
      <label class="sidebarLabel" style="font-size: 16px;position:relative;top: 3px;">Zoom -</label>
      <input type="text" class="sidebarInput mrgt-5"  (keypress)="keyPressNumbers($event)" id="canvasZoom" placeholder="0.6" value="0.6"/>  
  
      <label class="sidebarLabel" style="font-size: 16px;position:relative;top: 3px;">Size -</label>
      <input type="text" class="sidebarInput mrgt-5" (keyup)="canvasSize(true)" (keypress)="keyPressNumbers($event)" id="canvasWidth" placeholder="Width"/>  
      <label class="sidebarLabel" style="font-size: 16px;position:relative;top: 3px;">X</label>
      <input type="text" class="sidebarInput mrgt-5" (keyup)="canvasSize(true)" (keypress)="keyPressNumbers($event)" id="canvasHeight" placeholder="Height"/>  
    
    </div>
  </div>
  


</div>
<!--/Header-->

<div class="editArea" id="editArea">
  <div style="display: flex;align-items: center;cursor: pointer;" (click)="edit()">

  <div class="mrgl-10"  id="uidnameShow"></div>
  &nbsp;<img src="./assets/canvastool/edit.png"   style="width: 15px;height:15px;"/>

  </div>
  <div style="display: flex;align-items: center;">
    <div style="display: flex;align-items: center;cursor: pointer;" (click)="copyandPaste()">Copy and Paste &nbsp;<img src="./assets/canvastool/copypaste.png"  style="width: 22px;height:22px;"/>
    </div>
  </div>
  <div style="display: flex;align-items: center;">
    <div style="display: flex;align-items: center;cursor: pointer;" (click)="delete()">Delete &nbsp;<img src="./assets/canvastool/delete_black.png"  style="width: 15px;height:18px;"/>
    </div>
  </div>
  <div class="mrgr-10" style="display: flex;align-items: center;">
      <div style="display: flex;align-items: center;cursor: pointer;" (click)="above()">Bring To Front &nbsp;<img src="./assets/canvastool/abovearrow.png"  style="width: 22px;height:22px;"/>
      </div>
        <span class="mrgr-20"></span>
        <div style="display: flex;align-items: center;cursor: pointer;" (click)="below()">
          Push To Back &nbsp;<img src="./assets/canvastool/belowarrow.png" class="" style="width: 22px;height:22px;"/>
        </div>
  </div>

</div>

<!--Sidebar-->
<div class="sidebar" >
     
  <div style="margin-top:60px;width:100%;height:45px;background-color: #56ffb5;display: flex;align-items: center;color:#363636;justify-content: center;font-family: sans-serif;font-size: 14px;" (click)="overlayStart(1,1)">
    <img src="./assets/canvastool/add.png" style="width: 20px;height:20px;"/>
    <div style="font-size:16px;    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    margin-left: 5px;">Add Component</div>
  </div>
  
  <div class="componentMainNDiv mrgt-20">  
    <div class="componentArea" id="componentArea" style="border:0px solid red;">
      

      <div  style="display: flex;align-items: center;justify-content:space-between;color: #000;width:100%;border:0px solid red;" *ngFor="let json of this.appMethods.finalJson;let i = index;" (click)="editElement(i)">
        <div style="display: flex;align-items: center;">
          <img src="./assets/canvastool/{{json.component.toLowerCase()}}.png" style="width: 20px; height: 20px;position:relative;top:1px;"/>
          <label class="sidebarLabel" style="color: #000;margin-left: 5px;">{{json.component}} - <span style="font-weight: bold;">{{json.uidname}}</span></label>  
        </div>

        <div>
          <img src="./assets/canvastool/sideicon.png" style="height:12px;"/>
        </div>

      </div>

      
    </div>


  </div>


</div>
<!--/Sidebar-->



<div class="canvasContainer mrgt-40"> 
  <canvas id="canvas" width="1000" height="500" class="canvas"  (click)="handleMouseClick($event)" (mousedown)="handlekeyDown($event)"  (mousemove)="handlekeymove($event)" (mouseup)="handlekeyup($event)"></canvas>
</div>

