<div class="layout-topbar">
    <a class="layout-topbar-logo" routerLink="/main">
        <img src="../../../assets/logo/spoton_vector.svg" style="width: 80%;" alt="logo">
    </a>

    <a class="p-link layout-menu-button layout-topbar-button" href="#" (click)="appMain.toggleMenu($event)">
        <i class="pi pi-bars"></i>
    </a>
    
    <!-- <a class="p-link layout-topbar-menu-button layout-topbar-button" href="#" (click)="appMain.toggleTopMenu($event)">
        <i class="pi pi-ellipsis-v"></i>
    </a> -->

    <!-- <div style="color: white;" class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active':appMain.topMenuActive}">
        <p class="mb-0 mt-3">
            {{username}} 
        </p>
        <p class="mb-0 mt-3 mx-2">
            (<span *ngFor="let role of userRoles;let i=index">
                <span *ngIf="role == 'ROLE_ADMIN'"> ADMIN</span>
                <span *ngIf="role == 'ROLE_IT'"> IT</span>
                <span *ngIf="role == 'ROLE_DRIVER'"> DRIVER</span>
                <span *ngIf="role == 'ROLE_CLIENT'"> CLIENT</span>
                <span *ngIf="role == 'ROLE_SPOTTER'"> YARD SPOTTER</span>
                <span *ngIf="role == 'ROLE_SUPERVISOR'"> SUPERVISOR</span>
                <span *ngIf="role == 'ROLE_GUARD'"> GUARD</span> {{i < userRoles.length - 1 ? ',' : ''}}
            </span>)
            
        </p>
        <a (click)="logout()" class="p-link layout-topbar-button">
            <i class="pi pi-sign-out"></i>
            <span>Logout</span>
        </a>
    </div> -->
</div>