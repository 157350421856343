import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';

@Injectable({
  providedIn: 'root'
})
export class AddJobService {

  constructor(private http: HttpClient) { }

  saveJob(jobObj): Observable<any> {
    return this.http.post(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/jobs`,jobObj);
  }

  updateJob(jobId,jobObj): Observable<any> {
    return this.http.put(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/jobs/${jobId}`,jobObj);
  }

  getJobById(jobId):Observable<any>{
    return this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/jobs/${jobId}`);
  }

  deleteJob(jobId): Observable<any> {
    return this.http.delete(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/jobs/${jobId}`);
  }
}
