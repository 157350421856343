import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';
import { IpagedQuery } from 'src/app/model/IpagedQuery';

@Injectable({
  providedIn: 'root'
})
export class ManageClientsService {

  constructor(private http: HttpClient) { }

  viewClients(query:IpagedQuery): Observable<any> {
    const params = new HttpParams({
      fromObject:{
        isActive:query.isActive.valueOf(),
        page:query.page.toString(),
        size:query.size.toString(),
      }
    })
    return this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/clients`,{params});
  }

  deleteClient(clientId): Observable<any> {
    return this.http.delete(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/clients/${clientId}`);
  }
  
  updateClientConfig(clientObj): Observable<any> {
    return this.http.post(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/clients/config`,clientObj);
  }

  getClientConfig():Observable<any> {
    return this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/clients/config`);
  }

}
