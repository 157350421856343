<div class="grid">
	<div class="col-12">
        <div class="card card-w-title">
            <p-breadcrumb [model]="breadcrumbItems" [home]="{icon: 'pi pi-home',routerLink:'../'}"></p-breadcrumb>
        </div>
    </div>
	<div class="col-12">
		<div class="card">
			<h5>Add Location</h5>
			<form [formGroup]="locationForm">
				<div class="p-fluid p-formgrid grid">
					<div class="field col-12">
						<label htmlFor="locationName">Location Name<span class="text-danger">*</span></label>
						<input pInputText id="locationName" formControlName="locationName" type="text" />
						<span
                            class="text-danger"
                            *ngIf="
                                locationForm.controls.locationName.touched &&
								locationForm.controls.locationName.invalid
                            "
                            >Location Name is required</span
                        >
					</div>
					<div class="field col-12">
						<label htmlFor="street">Street<span class="text-danger">*</span></label>
						<input pInputTextarea id="street" rows="4" formControlName="street" #search />
						<span
                            class="text-danger"
                            *ngIf="
                                locationForm.controls.street.touched &&
								locationForm.controls.street.invalid
                            "
                            >Street is required</span
                        >
					</div>
					<div class="field col-12 md:col-6">
						<label htmlFor="city">City<span class="text-danger">*</span></label>
						<input pInputText id="city" type="text" formControlName="city" />
						<span
                            class="text-danger"
                            *ngIf="
                                locationForm.controls.city.touched &&
								locationForm.controls.city.invalid
                            "
                            >City is required</span
                        >
					</div>
					<div class="field col-12 md:col-3">
						<label htmlFor="state">State<span class="text-danger">*</span></label>
						<p-dropdown [options]="stateList" placeholder="Select State" formControlName="state" optionLabel="name" optionValue="code"></p-dropdown>
						<span
                            class="text-danger"
                            *ngIf="
                                locationForm.controls.state.touched &&
								locationForm.controls.state.invalid
                            "
                            >State is required</span
                        >
					</div>
				<div class="field col-12 md:col-3">
					<label htmlFor="zip">Zip Code<span class="text-danger">*</span></label>
					<input pInputText id="zip" type="text" formControlName="zip" />
					<span
                            class="text-danger"
                            *ngIf="
                                locationForm.controls.zip.touched &&
								locationForm.controls.zip.invalid
                            "
                            >Zip code is required</span
                    	>
				</div>
                <!-- <div class="field col-12 md:col-6">
					<label htmlFor="latitude">Latitude</label>
					<input pInputText id="latitude" type="text" formControlName="latitude" />
					<span
                            class="text-danger"
                            *ngIf="
                                locationForm.controls.latitude.touched &&
								locationForm.controls.latitude.invalid
                            "
                            >Latitude is required</span
                        >
				</div>
				<div class="field col-12 md:col-6">
					<label htmlFor="longitude">Longitude</label>
					<input pInputText id="longitude" type="text" formControlName="longitude" />
					<span
                            class="text-danger"
                            *ngIf="
                                locationForm.controls.longitude.touched &&
								locationForm.controls.longitude.invalid
                            "
                            >Longitude is required</span
                        >
				</div> -->
				<div class="field col-12">
					<label htmlFor="locationMapJson">Location Map Json</label>
					<textarea pInputTextarea autoResize rows="3" cols="30" formControlName="locationMapJson"></textarea>
				</div>
				<div class="field col-12">
					<label htmlFor="remarks">Remarks</label>
					<textarea pInputTextarea autoResize rows="3" cols="30" formControlName="remarks"></textarea>
				</div>
				<div *ngIf="isTrailerAudit" class="field col-3">
                    <label htmlFor="pieChartColor">Pie chart color -HEX</label>
					<input pInputText id="pieChartColor" formControlName="pieChartColor" type="text" />
				</div>
				<div class="field col-12">
					<label htmlFor="remarks">Location Image</label>
					<div class="d-flex align-items-center">
						<input type="file" (change)="onImageUpload($event.target.files)">
						<button pButton pRipple icon="pi pi-trash" *ngIf="isAdmin && location?.mapImageUrl" pTooltip="Remove Image" class="p-button-rounded p-button-danger" (click)="removeLocationImage()"></button>
					</div>
				</div>
				<div *ngIf="location?.mapImageUrl" class="field col-3">
					<img [src]="location?.mapImageUrl" alt="Location Image" width="100%">
				</div>
                <button pButton type="button" (click)="onSubmit()" label="Submit"></button>
			</div>
			</form>
		</div>
	</div>
</div>
