/**
 * Http Error Statuses common to our application
 */
export const HTTP_STATUS = {
  OK: 200,
  CREATED: 201,
  NOT_AUTHENTICATED: 401,
  BAD_REQUEST: 400,
  NO_CONTENT:204
};
