<div>
<div class="layout-footer">
    <img src="assets/demo/images/blocks/logos/hyper.svg" alt="Logo" height="20" class="mr-2"/>
    by 
    <span class="font-medium ml-2">A.Blair Enterprises</span>
    
    <p class="terms" (click)="displayTerms()">Terms and Conditions</p>
    <p class="privacy" (click)="displayPrivacyPolicy()">Privacy Policy</p>
    
</div>
</div>