import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { faHome, faBuilding, faTruck, faUser, faComment, faBriefcase, faTools, faBell, faCircle, faPhone, faMobile } from '@fortawesome/free-solid-svg-icons';
import { DashboardService } from './components/dashboard/dashboard.service';
import { TokenService } from './security/token.service';
import { APP_CONSTANTS } from './constants/app.constants';
import { AccountService } from './security/account.service';
import { AppLoaderService } from './app-loader/service/app-loader.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { WebSocketService } from './web-socket.service';
import { IpagedQuery } from './model/IpagedQuery';
import * as moment from 'moment';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ManageClientsService } from './components/manage-clients/manage-clients.service';
import { ErrorService } from './error-handling/error.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-menu',
    template: `
        <div class="layout-menu-container">
            <ul class="layout-menu" role="menu" (keydown)="onKeydown($event)">
                <li app-menu class="layout-menuitem-category" *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true" role="none">
                    <ul role="menu">
                  
                      <li app-menuitem *ngFor="let child of item.items"  [item]="child"  [index]="i" role="none"></li>
                    
                    </ul>
                    
                </li>
                
            </ul>
            
            <div class="logout-section" (click)="logout()">
            <a class="layout-logout-button">
            <i class="pi pi-sign-out"></i>
            <span class="logout-text">Logout</span>
        </a>
        <div class="user-details">
        <p class="mb-0 mt-3">
            {{username}} 
        </p>
        <p class="mb-0 mt-3 mx-2">
            (<span *ngFor="let role of userRoles;let i=index">
                <span *ngIf="role == 'ROLE_ADMIN'"> ADMIN</span>
                <span *ngIf="role == 'ROLE_IT'"> IT</span>
                <span *ngIf="role == 'ROLE_DRIVER'"> DRIVER</span>
                <span *ngIf="role == 'ROLE_CLIENT'"> CLIENT</span>
                <span *ngIf="role == 'ROLE_SPOTTER'"> YARD SPOTTER</span>
                <span *ngIf="role == 'ROLE_SUPERVISOR'"> SUPERVISOR</span>
                <span *ngIf="role == 'ROLE_GUARD'"> GUARD</span> {{i < userRoles.length - 1 ? ',' : ''}}
            </span>)
            
        </p>
        </div>
            </div>
        </div>
    `
})
export class AppMenuComponent implements OnInit {

    model: any[];
    messageCount: number;
    userRoles = [];
    showCanvas: boolean;
    userType: string;
    showDashboard: boolean = true;
    username:string;
    showUserPage:boolean = true; 
    showEntryExit:boolean = true;
    todayQuery:IpagedQuery;
    messageStatistics = {
        newCount:0,
        readCount:0
    }
    unreadMessage:boolean = false;
    showTrailerAudit:boolean = true;
    isTrailerAudit:boolean = true ;

    private webSocket: WebSocket;
    constructor(
        private ngZone:NgZone,
        public appMain: AppMainComponent,
        private dashboardService:DashboardService,
        private tokenService:TokenService,
        private accountService:AccountService,
        private loader:AppLoaderService,
        private router:Router,
        private webSocketService:WebSocketService,
        private manageClientService:ManageClientsService,
        private errorService:ErrorService
        ) { 

            this.webSocket = new WebSocket(APP_CONSTANTS.SOCKET_URL);
            this.webSocket.onmessage = (event) => {
                
                this.getMessageStatics();
                console.log("sidebar");
                
               
            };

           
        }

    ngOnInit() {

        this.initializeData();
    }

      
    async initializeData() {

        let nowTime =  moment().format('yyyy-MM-DD HH:mm');
        let twentyFourHours =  moment().add(-24,'hours').format('yyyy-MM-DD HH:mm');
        this.todayQuery = {
            fromDateTime:twentyFourHours,
            toDateTime:nowTime
        }
        
        this.username = this.tokenService.getUserFullName();
        this.userRoles = this.tokenService.getUserRoles();
         await this.getConfig();

        if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_IT)){
            this.showCanvas = true;
        }
        if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_CLIENT && role !== APP_CONSTANTS.USER_ROLES.ROLE_ADMIN && role !== APP_CONSTANTS.USER_ROLES.ROLE_IT)){
            this.userType = APP_CONSTANTS.USER_ROLES.ROLE_CLIENT;
        } 
        this.dashboardService.$messageCount.subscribe(count=>{
            this.messageCount = count;
            if(this.messageCount > 0)
            {
                this.unreadMessage= true;
                this.model.forEach(category => {
                    if (category.label === 'Components') {
                      const messagesItem = category.items.find(item => item.label === 'Messages');
                      if (messagesItem) {
                        messagesItem['badge'] = ' '; 
                      }
                    }
                  });
            }
            else
            {
                this.unreadMessage= false;
                this.model.forEach(category => {
                    if (category.label === 'Components') {
                      const messagesItem = category.items.find(item => item.label === 'Messages');
                      if (messagesItem) {
                        messagesItem['badge'] = ''; 
                      }
                    }
                  });
            }
            console.log("messagecount111",this.messageCount);
        })
        this.model = [
            {
                label: 'Home',
                items:[
                    {label: 'Dashboard',icon: faHome, routerLink: ['/main']}
                ]
            },
            {
                label: 'Components',
                items: [
                    // {label: 'Overview', icon: faBuilding, routerLink: ['/main/overview'], newtab: false},
                    {label: 'Clients', icon: faBuilding, routerLink: ['/main/manage-clients'], newtab: false},
                    {label: 'Trailer Audit', icon: faBuilding, routerLink: ['/main/manage-trailer-audit'], newtab: false},
                    {label: 'Asset Inventory', icon: faTruck, routerLink: ['/main/manage-fleets'], newtab: false},
                    {label: 'Entry/ Exit Detail', icon: faTruck, routerLink: ['/main/manage-entry-exit'], newtab: false},
                    {label: 'Users', icon: faUser, routerLink: ['/main/manage-users'], newtab: false},
                    {label: 'Spots', icon: faBriefcase, routerLink: ['/main/manage-jobs'], newtab: false},
                    {label: `Messages`, icon: faComment , badge: '' ,routerLink: ['/main/manage-messages'], newtab: false,showRedDot: true},
                    {label: `Mobile App Updates`, icon: faMobile ,routerLink: ['/main/manage-mobile-app'], newtab: false}
                ]
            }
        ];
        
        if(this.userRoles.some(role => role != APP_CONSTANTS.USER_ROLES.ROLE_CLIENT && role != APP_CONSTANTS.USER_ROLES.ROLE_SUPERVISOR))
        {
               this.showTrailerAudit  = false;
        }
        if(this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_CLIENT || role === APP_CONSTANTS.USER_ROLES.ROLE_SUPERVISOR) && this.isTrailerAudit === false)
        {
         
            this.showTrailerAudit  = false;
        }

        if(this.showCanvas){
            this.model[1].items.push({label: 'Canvas Tool', icon: faTools , routerLink: ['/canvas-tool'], newtab: true});
        }
        if(this.userType == APP_CONSTANTS.USER_ROLES.ROLE_CLIENT){
            this.model[1].items[0] = {label: 'Client', icon: faBuilding, routerLink: ['/main/manage-clients'], newtab: false}
        }
        if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_DRIVER 
            || role === APP_CONSTANTS.USER_ROLES.ROLE_GUARD 
            || role === APP_CONSTANTS.USER_ROLES.ROLE_SPOTTER
            )){
            this.showDashboard = false;
        }
        
        if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_CLIENT))
        {
            //  this.showUserPage = false;
             this.model[1].items[0].label = 'Locations';
        }

        if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_SPOTTER || role === APP_CONSTANTS.USER_ROLES.ROLE_DRIVER))
        {
            this.showEntryExit = false;
        }
        if(this.showDashboard === false){
            this.model[0].items.pop();
        }
        
        if(this.showUserPage === false)
        {
            this.model = this.model.map(item => {
                if (item.label === 'Components') {
                    item.items = item.items.filter(subItem => subItem.label !== 'Users');
                }
                return item;
            });
        }

        if(this.showEntryExit === false)
        {
            this.model = this.model.map(item => {
                if (item.label === 'Components') {
                    item.items = item.items.filter(subItem => subItem.label !== 'Entry/ Exit Detail');
                }
                return item;
            });
        }

        if(this.showTrailerAudit === false)
        {
            this.model = this.model.map(item => {
                if (item.label === 'Components') {
                    item.items = item.items.filter(subItem => subItem.label !== 'Trailer Audit');
                }
                return item;
            });
        }

        // if(this.userRoles.some(role => role != APP_CONSTANTS.USER_ROLES.ROLE_CLIENT && role != APP_CONSTANTS.USER_ROLES.ROLE_SUPERVISOR && role != APP_CONSTANTS.USER_ROLES.ROLE_ADMIN))
        // {
        //     this.model = this.model.map(item => {
        //         if (item.label === 'Components') {
        //             item.items = item.items.filter(subItem => subItem.label !== 'Overview');
        //         }
        //         return item;
        //     });
        // }

        // mobile version force update
        if(this.userRoles.some(role => role != APP_CONSTANTS.USER_ROLES.ROLE_IT && role != APP_CONSTANTS.USER_ROLES.ROLE_ADMIN))
        {
            this.model = this.model.map(item => {
                if (item.label === 'Components') {
                    item.items = item.items.filter(subItem => subItem.label !== 'Mobile App Updates');
                }
                return item;
            });
        }
        
    }

    onKeydown(event: KeyboardEvent) {
        const nodeElement = (<HTMLDivElement> event.target);
        if (event.code === 'Enter' || event.code === 'Space') {
            nodeElement.click();
            event.preventDefault();
        }
    }

    logout()
    {
        this.loader.show();
        this.accountService.logout().subscribe((data: any) => {
          this.tokenService.removeToken();
          this.router.navigateByUrl('');
          this.loader.hide();
        }, error => {
          this.tokenService.removeToken();
          this.router.navigateByUrl('');
          this.loader.hide();
        });
    }


    getMessageStatics()
    {
      this.dashboardService.getMessagesCount(this.todayQuery).subscribe(response=>{
             this.messageStatistics = response;
             if(this.messageStatistics.newCount != 0)
             {
                this.unreadMessage= true;
             }
             
             this.dashboardService.sendMessageCount(this.messageStatistics.newCount);
        }
      )
    }


    // getConfig():Observable<any>
    // {
    //     this.manageClientService.getClientConfig().subscribe(response=>{
        
    //     this.isTrailerAudit = response.trailerAudit;
    //    console.log("trailerAudit111111",this.isTrailerAudit);
        
    //   },(error) => {
    //       this.loader.hide();
    //       this.errorService.handleError(error, true);
    //   })
    // // try {
    // //     const response = await this.manageClientService.getClientConfig().toPromise();
    
    // //     this.isTrailerAudit = response.trailerAudit;
    // //     console.log("trailerAudit111111", this.isTrailerAudit);
    // //   } catch (error) {
    // //     this.loader.hide();
    // //     this.errorService.handleError(error, true);
    // //   }
    //    return null;
       
    // }


    async getConfig() {
        return new Promise<void>((resolve, reject) => {
          this.manageClientService.getClientConfig().subscribe(
            (response) => {
                
                    this.isTrailerAudit =response?.trailerAudit;
                     resolve();
                
             
            },
            (error) => {
              this.loader.hide();
              this.errorService.handleError(error, true);
               reject();
            }
          );
        });
      }


  
}

