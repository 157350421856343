import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppAlertComponent } from './component/app-alert/app-alert.component';
import {AppAlertService} from './service/app-alert.service';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {ToastModule} from 'primeng/toast';
import {MessageService} from 'primeng/api';



@NgModule({
  declarations: [
    AppAlertComponent
  ],
  imports: [
    CommonModule,
    MessagesModule, 
    MessageModule, 
    ToastModule
  ],
  providers:[
    MessageService,
    AppAlertService
  ],
  exports:[
    AppAlertComponent, 
    MessagesModule, 
    MessagesModule, 
    ToastModule
  ]
})
export class AppAlertModule { }
