import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IpagedQuery } from 'src/app/model/IpagedQuery';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';

@Injectable({
  providedIn: 'root'
})
export class ManageMessagesService {

  constructor(private http: HttpClient) { }

  viewClients(query:IpagedQuery): Observable<any> {
    const params = new HttpParams({
      fromObject:{
        isActive:query.isActive.valueOf(),
        page:query.page.toString(),
        size:query.size.toString(),
      }
    })
    return this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/clients`,{params});
  }

  viewMessages(query:IpagedQuery,filter?:any,inboxOutbox?:any): Observable<any> {
    let params = new HttpParams({
      fromObject:{
        isActive:query.isActive.valueOf(),
        page:query.page.toString(),
        size:query.size.toString(),
        sort:'createdDate,desc'
      }
    })
    if(filter){
      params = params.append("status",filter.toString())
    }
    if(inboxOutbox){
      params = params.append("mode",inboxOutbox.toString())
    }
    return this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/messages`,{params});
  }

  viewLocations(query:IpagedQuery,clientId): Observable<any> {
    const params = new HttpParams({
      fromObject:{
        isActive:query.isActive.valueOf(),
        page:query.page.toString(),
        size:query.size.toString(),
      }
    })
    return this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/clients/${clientId}/locations`,{params});
  }


  viewSpots(query:IpagedQuery,clientId,locationId): Observable<any> {
    const params = new HttpParams({
      fromObject:{
        'isActive':query.isActive.valueOf(),
        'page':query.page.toString(),
        'size':query.size.toString(),
        'location.uuid':locationId.toString()
      }
    })
    return this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/clients/${clientId}/spots`,{params});
  }

  sendMessage(messageObj): Observable<any>{
    return this.http.post(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/messages`,messageObj);
  }

  changeMessageStatus(messageId,status): Observable<any>{
    return this.http.patch(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/messages/${messageId}/status`,status)
  }
}
