/**
 * Error message constants
 */
export const ERROR_MESSAGES = {
  'error.database': 'Database error occured, message: {0}',
  'error.internal.server': 'Internal server error occured. Server Message : {0}',
  'error.phone.invalid': 'Phone is invalid {0}',
  'error.email.empty': 'Email is required!',
  'error.email.invalid': 'Please provide valid email address!',
  'error.all.email.invalid': 'All provided emails are invalid!',
  'error.forgot.password.user.not.found': 'This email is not registered with us, please provide email you use to Login.',
  'error.reset.password.not.found': 'You have not initiated forgot password process.',
  'error.forgot.password.send.email': 'Error occurred while sending mail to your email address. Please try again.',
  'error.reset.password': 'Error occurred while resetting your password. Please try again.',
  'error.user.not.found': 'User not found.',
  'error.update.account.new.password.empty': 'Please enter New and Confirm New password to update your password.',
  'error.update.account.current.password.incorrect': 'Provided current password is incorrect.',
  'error.update.account.current.password.empty': 'Please enter your Current password to change your password.',
  'error.update.account.details': 'Error occurred while updating Account Details. Please try again.',
  'error.update.account.password': 'Error occurred while updating Account Password. Please try again.',
  'error.invalid.page': 'Provided Page is Invalid : {0}. Valid page should be greater than 0.',
  'error.invalid.page.size': 'Provided Page Size is Invalid : {0}. Valid Page Size is in range {1} to {2}',
  'NotBlank.registrationRequest.firstName': 'First Name is required.',
  'Size.registrationRequest.firstName': 'First Name can be of maximum {0} characters long.',
  'NotBlank.registrationRequest.lastName': 'Last Name is required.',
  'Size.registrationRequest.lastName': 'Last Name can be of maximum {0} characters long.',
  'NotBlank.registrationRequest.email': 'Email is required.',
  'Size.registrationRequest.email': 'Email can be of maximum {0} characters long.',
  'Email.registrationRequest.email': 'Provided email address is invalid.',
  'NotBlank.registrationRequest.password': 'Password is required.',
  'NotBlank.registrationRequest.confirmPassword': 'Confirm Password is required.',
  'NotBlank.registrationRequest.timeZone': 'Time Zone is required.',
  'Size.registrationRequest.timeZone': 'Time Zone can be of maximum {0} characters long.',
  'NotValid.registrationRequest.timeZone': 'Provided Time Zone is invalid.',
  'NotMatch.registrationRequest.password': 'Password and Confirm Password are not matching.',
  'NotNull.registrationRequest.levelId': 'Level is required.',
  'NotBlank.forgotPasswordRequest.email': 'Email is required.',
  'Email.forgotPasswordRequest.email': 'Provided email is invalid.',
  'NotBlank.resetPasswordRequest.newPassword': 'New Password is required.',
  'NotBlank.resetPasswordRequest.confirmNewPassword': 'Confirm New Password is required.',
  'NotMatch.resetPasswordRequest.newPassword': 'New Password and Confirm New Password are not matching.',
  'NotBlank.resetPasswordRequest.token': 'Token is required to reset your password.',
  'NotBlank.accountRequest.firstName': 'First Name is required.',
  'NotBlank.accountRequest.lastName': 'Last Name is required.',
  'Size.accountRequest.firstName': 'First Name can be of maximum {0} characters long.',
  'Size.accountRequest.lastName': 'Last Name can be of maximum {0} characters long.',
  'NotBlank.accountRequest.timeZone': 'Time Zone is required.',
  'Size.accountRequest.timeZone': 'Time Zone can be of maximum {0} characters long.',
  'NotValid.accountRequest.timeZone': 'Provided Time Zone is invalid.',
  'NotNull.accountRequest.levelId': 'Level is Required.',
  'NotBlank.updatePasswordRequest.newPassword': 'New Password is required.',
  'NotBlank.updatePasswordRequest.confirmNewPassword': 'Confirm New Password is required.',
  'NotBlank.updatePasswordRequest.currentPassword': 'Current Password is required.',
  'NotMatch.updatePasswordRequest.newPassword': 'New Password and Confirm New Password are not matching.',
  'NotValid.accountRequest.profilePhoto': 'Provided Profile Image is not valid. Allowed image formats are : {0}',
  'Large.accountRequest.profilePhoto': 'Provided Profile Image is too large. Allowed image size is : {0}',
  'error.email.already.exists': 'Email : {0} is already registered, please provide different Email or Login.',
  'error.create.account': 'Error occurred while creating new Account. Please try again.',
  'error.send.signup.email': 'Error occurred while Sending Sign Up Email. Please check if your email is valid.',
  'error.user.not.authenticated': 'You have not logged in to Application, please login and try again.',
  'error.access.denied': 'You are not authorized to access this API.',
  'error.forgot.password': 'Error occurred while initiating forgot password process. Please try again.',
  'error.send.reset_password.email': 'Error occurred while Sending Reset Password Email. Please check if your email is valid.',
  'error.account.archived': 'Your account is Archived. Please contact system administrator.',
  'error.level.not.found': 'No Level found by ID {0}.',
  'NotBlank.fileUploadRequest.base64Data': 'File Base64 data is required.',
  'NotBlank.fileUploadRequest.fileExtension': 'File Extension is required.',
  'NotValid.fileUploadRequest.imageFile': 'Provided File data is not a valid Image. Valid formats are : {0}',
  'NotValid.fileUploadRequest.audioFile': 'Provided File data is not a valid Audio. Valid formats are : {0}',
  'NotValid.fileUploadRequest.videoFile': 'Provided File data is not a valid Video. Valid formats are : {0}',
  'Large.fileUploadRequest.imageFile': 'Provided Image file is too large. Allowed Image Size is : {0}',
  'Large.fileUploadRequest.audioFile': 'Provided Audio file is too large. Allowed Audio Size is : {0}',
  'Large.fileUploadRequest.videoFile': 'Provided Video file is too large. Allowed Video Size is : {0}',
  'Large.fileUploadRequest.docFile': 'Provided Document file is too large. Allowed Document Size is : {0}',
  'error.create.category': 'Error occurred while creating new Category. Please try again.',
  'error.duplicate.category': 'Category with this Label already exists. Please provide different Label.',
  'NotBlank.categoryRequest.englishLabel': 'English Label is required.',
  'NotBlank.categoryRequest.swahiliLabel': 'Swahili Label is required.',
  'Size.categoryRequest.englishLabel': 'English Label can be of maximum {0} characters long.',
  'Size.categoryRequest.swahiliLabel': 'Swahili Label can be of maximum {0} characters long.',
  'error.category.not.found': 'No Category found by ID : {0}.',
  'error.update.category': 'Error occurred while updating Category. Please try again.',
  'error.delete.category.associated.lesson': 'This Category is used in Lessons, hence cannot be deleted. If you no longer need this, just deactivate this Category.',
  'error.delete.category': 'Error occurred while deleting Category. Please try again.',
  'error.category.already.at.top': 'This Category is already at top.',
  'error.category.already.at.bottom': 'This Category is already at bottom.',
  'error.category.archived': 'Category with ID : {0} is not ACTIVE.',
  'error.sort.category': 'Error occurred while sorting Category. Please try again.',
  'error.archive.category': 'Error occurred while archiving Category. Please try again.',
  'error.category.already.active': 'This Category is already ACTIVE.',
  'error.unarchive.category': 'Error occurred while Unarchiving Category. Please try again.',
  'NotBlank.lessonRequest.englishLabel': 'English Label is required.',
  'NotBlank.lessonRequest.swahiliLabel': 'Swahili Label is required.',
  'Size.lessonRequest.englishLabel': 'English Label can be of maximum {0} characters long.',
  'Size.lessonRequest.swahiliLabel': 'Swahili Label can be of maximum {0} characters long.',
  'NotNull.lessonRequest.categoryId': 'Category is required.',
  'NotNull.lessonRequest.minimumLevelId': 'Minimum Level is required.',
  'error.level.not.active': 'Level with ID : {0} is not ACTIVE.',
  'error.duplicate.lesson': 'Lesson with this Label already exists. Please provide different Label.',
  'error.create.lesson': 'Error occurred while Creating new Lesson. Please try again.',
  'error.lesson.archived': 'Lesson with ID : {0} is not ACTIVE.',
  'error.lesson.not.found': 'No Lesson found by ID {0}.',
  'error.lesson.item.archived': 'Lesson Item with ID : {0} is not ACTIVE.',
  'error.lesson.item.not.found': 'No Lesson Item found by ID {0}.',
  'error.update.lesson': 'Error occurred while updating Lesson. Please try again.',
  'error.delete.lesson.associated.items': 'This Lesson is used in Lesson Items, hence cannot be deleted. If you no longer need this, just deactivate this Lesson.',
  'error.archive.lesson': 'Error occurred while archiving Lesson. Please try again.',
  'error.lesson.already.active': 'This Lesson is already ACTIVE.',
  'error.unarchive.lesson': 'Error occurred while Unarchiving Lesson. Please try again.',
  'error.lesson.already.at.top': 'This Lesson is already at top.',
  'error.lesson.already.at.bottom': 'This Lesson is already at bottom.',
  'error.sort.lesson': 'Error occurred while sorting Lesson. Please try again.',
  'NotNull.lessonItemRequest.lessonId': 'Lesson is required.',
  'NotBlank.lessonItemRequest.englishText': 'English Text is required.',
  'NotBlank.lessonItemRequest.swahiliText': 'Swahili Text is required.',
  'NotNull.lessonItemRequest.image': 'Image is required.',
  'error.create.lesson.item': 'Error occurred while Creating new Lesson Item. Please try again.',
  'error.duplicate.lesson.item': 'Lesson Item already exists.',
  'error.update.lesson.item': 'Error occurred while updating Lesson Item. Please try again.',
  'error.delete.lesson.item.associated.user.status': 'This Lesson Item is used in user status, hence cannot be deleted. If you no longer need this, just deactivate this Lesson Item.',
  'error.delete.lesson.item': 'Error occurred while deleting Lesson Item. Please try again.',
  'error.lesson.item.already.at.top': 'This Lesson Item is already at top.',
  'error.lesson.item.already.at.bottom': 'This Lesson Item is already at bottom.',
  'error.sort.direction.invalid': 'Sort Direction can be either UP or DOWN.',
  'error.sort.lesson.item': 'Error occurred while sorting Lesson Item. Please try again.',
  'error.archive.lesson.item': 'Error occurred while archiving Lesson Item. Please try again.',
  'error.lesson.item.already.active': 'This Lesson Item is already ACTIVE.',
  'error.unarchive.lesson.item': 'Error occurred while Unarchiving Lesson Item. Please try again.',
  'error.lesson.access.denied': 'You are not authorized to access this Lesson or it\'s Items.',
  'error.category.required': 'Category is Required.',
  'error.lesson.test.archived': 'Lesson Test with ID : {0} is not ACTIVE.',
  'error.lesson.test.not.found': 'No Lesson Test found by ID {0}.',
  'NotNull.lessonTestRequest.lessonId': 'Lesson is required.',
  'NotBlank.lessonTestRequest.question': 'Question is required.',
  'error.create.lesson.test': 'Error occurred while Creating new Lesson Test. Please try again.',
  'error.update.lesson.test': 'Error occurred while updating Lesson Test. Please try again.',
  'error.delete.lesson.test': 'Error occurred while deleting Lesson Test. Please try again.',
  'error.delete.lesson.test.associated.answer': 'This Lesson Test is used in Answer, hence cannot be deleted. If you no longer need this, just deactivate this Lesson Test.',
  'error.lesson.test.already.at.top': 'This Lesson Test is already at top.',
  'error.lesson.test.already.at.bottom': 'This Lesson Test is already at bottom.',
  'error.sort.lesson.test': 'Error occurred while sorting Lesson Test. Please try again.',
  'error.archive.lesson.test': 'Error occurred while archiving Lesson Test. Please try again.',
  'error.lesson.test.already.active': 'This Lesson Test is already ACTIVE.',
  'error.unarchive.lesson.test': 'Error occurred while Unarchiving Lesson Test. Please try again.',
  'NotNull.lessonTestAnswerRequest.lessonTestId': 'Lesson Test is required.',
  'NotBlank.lessonTestAnswerRequest.answer': 'Answer is required.',
  'NotNull.lessonTestAnswerRequest.isCorrect': 'Is Correct field is required.',
  'NotNull.lessonTestAnswerRequest.image': 'Answer Image is required.',
  'error.duplicate.lesson.test.answer': 'Lesson Test Answer already exists.',
  'error.create.lesson.test.answer': 'Error occurred while Creating new Lesson Test Answer. Please try again.',
  'error.lesson.test.answer.not.found': 'No Lesson Test Answer found by ID {0}.',
  'error.update.lesson.test.answer': 'Error occurred while updating Lesson Test Answer. Please try again.',
  'error.delete.lesson.test.answer': 'Error occurred while deleting Lesson Test Answer. Please try again.',
  'error.complete.lesson.item': 'Error occurred while completing Lesson Item. Please try again.',
  'error.location.availability.duplicate':'Provided location availability already exists in the system.',
  'error.add.update.medical.test.duplicate.test.id':'Provided test ID already exists in the system.',
  'error.availability.exception.date.invalid':'Exception date should fall on same day as Availability Day!',
  'error.location.availability.exception.duplicate':'Availability exception for Provided date and time slot already exists in the system.',
  'invalid_grant':'Bad credentials',
  'error.duplicate.fleet.unit.number':'Unit number already exists'
};
