import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';

@Injectable({
  providedIn: 'root'
})
export class RedirectPageService {

  constructor(private http: HttpClient) { }

  loginSsoUser(temporaryId: string): Observable<any> {

    const params = new HttpParams({
      fromObject: {
        temporaryId: temporaryId

      }
    });

    return this.http.post(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/users/auth`, {id: temporaryId})
                          .pipe(
                              map(token => {
                                return this.strigifyToken(token);
                              })
                            );
  }
 
  private strigifyToken(token: any): string {
    return JSON.stringify(token);
  }
}