<div class="grid">
    <div class="col-12">
        <div class="card card-w-title">
            <p-breadcrumb [model]="breadcrumbItems" [home]="{icon: 'pi pi-home',routerLink:'../'}"></p-breadcrumb>
        </div>
    </div>
    <div class="col-12">
        <div class="card">
            <p-toast></p-toast>
            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="left">
                    <div class="my-2">
                        <h5 class="m-0">Docks/Parking Spots ({{clientName}}-{{locationName}})</h5>
                    </div>
                </ng-template>

                <ng-template pTemplate="right">
                    <button *ngIf="userType !== 'ROLE_CLIENT' && hideButtonsIfDriver !== true && hideButtonsIfSupervisor === false && hideButtonsIfGuard ===false && hideButtonsIfSpotter === false" pButton pRipple label="New" icon="pi pi-plus" class="p-button-success mr-2" (click)="openNew()"></button>
                    <!-- <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help" (click)="exportExcel()"></button> -->
                    <p-splitButton label="Export" [model]="items" (onClick)="exportExcel()" styleClass="p-button-raised p-button-help mr-2 "></p-splitButton>
                        
                </ng-template>
            </p-toolbar>

            <p-table #dt [value]="spotList" [loading]="loading" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" [responsive]="true" [columns]="cols" [rows]="10" [globalFilterFields]="['spotName']" [rowHover]="true" dataKey="id">
                <ng-template pTemplate="caption">
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <p-dropdown [options]="dropdownStatus" placeholder="Select Trailer Status" optionLabel="name" optionValue="code"
                                (onChange)="filterFleetsByStatus($event)" [showClear]="true"></p-dropdown>
                        <span></span>
                        <span class="block mt-2 md:mt-0 p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th></th>
                        <th pSortableColumn="spotName">Location</th>
                        <!-- <th pSortableColumn="type">Type</th> -->
                        <th pSortableColumn="status">Location Status</th>
                        <th pSortableColumn="fleetName">Trailer Name</th>
                        <th pSortableColumn="unitNumber">Unit #</th>
                        <th pSortableColumn="fleetStatus">Trailer Status</th>
                        <!-- <th pSortableColumn="latitude">Latitude</th>
                        <th pSortableColumn="longitude">Longitude</th> -->
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-spot>
                    <tr>
                        <td>
                            <button *ngIf="userType !== 'ROLE_CLIENT' && hideButtonsIfDriver !== true && hideButtonsIfSupervisor === false && hideButtonsIfGuard === false && hideButtonsIfSpotter === false" pButton pRipple icon="pi pi-pencil" pTooltip="Edit" class="p-button-rounded p-button-warning mr-2" (click)="editSpot(spot)"></button>
                            <button *ngIf="userType !== 'ROLE_CLIENT' && hideButtonsIfDriver !== true && hideButtonsIfSupervisor === false && hideButtonsIfGuard === false && hideButtonsIfSpotter === false && spot.isActive === true" 
                                    pButton pRipple icon="pi pi-trash" pTooltip="Delete" 
                                    class="p-button-rounded p-button-danger mt-2" 
                                    (click)="deleteSpot(spot)">
                            </button>
                        
                            <!-- Activate Button - shown only if spot.isActive is false -->
                            <button *ngIf="userType !== 'ROLE_CLIENT' && hideButtonsIfDriver !== true && hideButtonsIfSupervisor === false && hideButtonsIfGuard === false && hideButtonsIfSpotter === false && spot.isActive === false" 
                                    pButton pRipple icon="pi pi-refresh" pTooltip="Activate" 
                                    class="p-button-rounded p-button-success mr-2" 
                                    (click)="activateSpot(spot)">
                            </button>
                        </td>
                        <td ><span class="p-column-title">Location</span>
                            {{spot.spotName}}
                        </td>
                        <!-- <td >
                            <span class="p-column-title">Type</span>
                            {{spot.type}}
                        </td> -->
                        <td >
                            <span class="p-column-title">Location Status</span>
                            <p-tag *ngIf="spot.status == 'EMPTY'"  rounded="true" severity="danger" value="Empty"></p-tag>
                            <p-tag *ngIf="spot.status == 'OCCUPIED'" rounded="true" severity="success" value="Occupied"></p-tag>
                            <p-tag *ngIf="spot.status == 'TO_BE_EMPTY'" rounded="true" severity="info" value="To be empty"></p-tag>
                            <p-tag *ngIf="spot.status == 'TO_BE_OCCUPIED'" rounded="true" severity="warning" value="To be occupied"></p-tag>
                        </td>
                        <td >
                            <span class="p-column-title">Trailer Name</span>
                            {{spot.fleet ? spot.fleet.carrier : '-'}} 
                        </td>
                        <td >
                            <span class="p-column-title">Unit #</span>
                            {{spot.fleet ? spot.fleet.unitNumber : '-'}}  
                        </td>
                        <td >
                            <span class="p-column-title">Trailer Status</span>
                            {{spot.fleet ? spot.fleet.fleetStatus : '-'}} 
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="6">No dock/parking spot found.</td>
                    </tr>
                </ng-template>
            </p-table>
            <p-paginator [rows]="10" [showCurrentPageReport] = "true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" [totalRecords]="totalRecords"  (onPageChange)="paginate($event)" #paginator></p-paginator>
        </div>

        <p-dialog [(visible)]="spotDialog" [style]="{width: '450px'}" header="Docks/Parking Spots" [modal]="true" class="p-fluid">
            <ng-template pTemplate="content">
                <form [formGroup]="spotForm">
                    <div class="field">
                        <label for="name">Location<span class="text-danger">*</span></label>
                        <input type="text" pInputText id="name" formControlName="spotName" required autofocus [ngClass]="{'ng-invalid ng-dirty' : spotForm.controls.spotName.touched &&
                        spotForm.controls.spotName.invalid}"/>
                        <small class="text-danger" *ngIf="spotForm.controls.spotName.touched &&
                        spotForm.controls.spotName.invalid">Location is required.</small>
                    </div>
                    <div class="field">
                        <label for="type">Type<span class="text-danger">*</span></label>
                        <p-dropdown [options]="type" placeholder="Select Type" formControlName="type" optionLabel="name" optionValue="code"></p-dropdown>
                        <small class="text-danger" *ngIf="spotForm.controls.type.touched &&
                        spotForm.controls.type.invalid">Type is required.</small>
                    </div>
                    <div class="field">
                        <label for="status">Status<span class="text-danger">*</span></label>
                        <p-dropdown [options]="statuses" placeholder="Select Status" formControlName="status" optionLabel="name" optionValue="code"></p-dropdown>
                        <small class="text-danger" *ngIf="spotForm.controls.status.touched &&
                        spotForm.controls.status.invalid">Type is required.</small>
                    </div>
                    <!-- <div class="formgrid grid">
                        <div class="field col">
                            <label for="latitude">Latitude</label>
                            <input pInputText id="latitude" formControlName="latitude" type="number" />
                            <small class="ng-dirty ng-invalid" *ngIf="spotForm.controls.latitude.touched &&
                            spotForm.controls.latitude.invalid">Latitude is required.</small>
                        </div>
                        <div class="field col">
                            <label for="longitude">Longitude</label>
                            <input pInputText id="longitude" formControlName="longitude" type="number" />
                            <small class="ng-dirty ng-invalid" *ngIf="spotForm.controls.longitude.touched &&
                            spotForm.controls.longitude.invalid">Longitude is required.</small>
                        </div>
                    </div> -->
                    <div class="field">
                        <label for="remarks">Remarks</label>
                        <textarea pInputTextarea autoResize formControlName="remarks" rows="3" cols="30"></textarea>
                    </div>
                </form>
            </ng-template>

            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
                <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" type="button" (click)="saveSpot()"></button>
            </ng-template>
        </p-dialog>

        <p-dialog [(visible)]="deleteSpotDialog" header="Confirm" [modal]="true" [style]="{width:'450px'}">
            <div class="flex align-items-center justify-content-center">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
                <span *ngIf="spot">Are you sure you want to delete <b>{{spot.spotName}}</b>?</span>
            </div>
            <ng-template pTemplate="footer">
                <button pButton pRipple icon="pi pi-times" class="p-button-text" label="No" (click)="deleteSpotDialog = false"></button>
                <button pButton pRipple icon="pi pi-check" class="p-button-text" label="Yes" (click)="confirmDelete()"></button>
            </ng-template>
        </p-dialog>

        <p-dialog [(visible)]="activateSpotDialog" header="Confirm" [modal]="true" [style]="{width:'450px'}">
            <div class="flex align-items-center justify-content-center">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
                <span *ngIf="spot">Are you sure you want to Activate <b>{{spot.spotName}}</b>?</span>
            </div>
            <ng-template pTemplate="footer">
                <button pButton pRipple icon="pi pi-times" class="p-button-text" label="No" (click)="activateSpotDialog = false"></button>
                <button pButton pRipple icon="pi pi-check" class="p-button-text" label="Yes" (click)="confirmActivate()"></button>
            </ng-template>
        </p-dialog>
    </div>
</div>
