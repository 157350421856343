<div class="grid">
    <div class="col-12">
        <div class="card card-w-title">
            <p-breadcrumb [model]="breadcrumbItems" [home]="{icon: 'pi pi-home',routerLink:'../'}"></p-breadcrumb>
        </div>
    </div>
    <div class="col-12">
        <div class="card">
            <p-toast></p-toast>
            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="left">
                    <div class="my-2">
                        <h5 class="m-0">Messages</h5>
                    </div>
                </ng-template>

                <ng-template pTemplate="right">
                    <button pButton pRipple label="New Message" icon="pi pi-plus" class="p-button-success mr-2" (click)="openNew()"></button>
                </ng-template>
            </p-toolbar>

            <p-tabView (onChange)="handleTabChange($event)" [(activeIndex)]="activeTab">
                <p-tabPanel header="Inbox">
                    <p-table #dt [loading]="loading" [value]="messages" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" [responsive]="true" [columns]="cols" [rows]="10" [globalFilterFields]="['type']" [rows]="10" [paginator]="false" [rowHover]="true" dataKey="id" [responsiveLayout]="'scroll'">
                        <ng-template pTemplate="caption">
                            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                <span>
                                    <p-dropdown [showClear]="true" placeholder="Filter Read/Unread" [options]="messageStatus" (onChange)="filterMessagesByReadAndUnread($event)" optionLabel="name" optionValue="code"></p-dropdown>
                                </span>
                                <span class="block mt-2 md:mt-0 p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th></th>
                                <th pSortableColumn="from">From</th>
                                <th pSortableColumn="to">To</th>
                                <th pSortableColumn="type">Type</th>
                                <th pSortableColumn="date">Date Received</th>
                                <th pSortableColumn="jobNumber" *ngIf="userType != 'ROLE_CLIENT'">Spot Number</th>
                                <th pSortableColumn="createdDate" *ngIf="userType != 'ROLE_CLIENT'">Spot Creation Date</th>
                                <th pSortableColumn="completedDate" *ngIf="userType != 'ROLE_CLIENT'">Spot Completion Date</th>
                                <th pSortableColumn="status" *ngIf="userType != 'ROLE_CLIENT'">Spot Status</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-message let-i="rowIndex">
                            <tr>
                                <td>
                                    <button *ngIf="message.status == 'NEW'" pButton pRipple icon="pi pi-envelope" pTooltip="Open" class="p-button-rounded p-button-warning mt-2" (click)="readMessage(message,'changeStatus',1)"></button>
                                    <button *ngIf="message.status !== 'NEW'" pButton pRipple icon="pi pi-folder-open" pTooltip="Read" class="p-button-rounded p-button-success mt-2" (click)="readMessage(message,'none',1)"></button>
                                    <!-- <button pButton pRipple icon="pi pi-reply" pTooltip="Send Reply" class="p-button-rounded p-button-help mt-2 ml-2" (click)="sentReply(message)"></button>
                                    <button *ngIf="userType == 'ROLE_SUPERVISOR' && message?.type == 'TRAILER_TRUCK_MOVE'" pButton pRipple icon="pi pi-plus" pTooltip="Add Spot" class="p-button-rounded p-button-secondary mt-2 ml-2" (click)="addSpot(message)"></button> -->
                                </td>
                                <td>
                                    <span class="p-column-title">From</span>
                                    {{message?.fromUser?.firstName}} {{message?.fromUser?.lastName}}
                                </td>
                                <td>
                                    <span class="p-column-title">To</span>
                                    {{message?.toUser?.firstName}} {{message?.toUser?.lastName}}
                                </td>
                                <td>
                                    <span class="p-column-title">Type</span>
                                    <p-tag *ngIf="message?.type == 'TRAILER_TRUCK_MOVE'"  rounded="true" severity="danger" value="Trailer Truck Move"></p-tag>
                                    <p-tag *ngIf="message?.type == 'HOT_TRAILER'" rounded="true" severity="primary" value="Hot Trailer"></p-tag>
                                    <p-tag *ngIf="message?.type == 'INFORMATION'" rounded="true" severity="success" value="Information"></p-tag>
                                </td>
                                <td><span class="p-column-title">Date Received</span>
                                    {{message.audit?.createdDate}}
                                </td>
                                <td *ngIf="userType != 'ROLE_CLIENT'"><span class="p-column-title">Spot Number</span>
                                    {{message.job?.jobNumber}}
                                </td>
                                <td *ngIf="userType != 'ROLE_CLIENT'"><span class="p-column-title">Spot Creation Date</span>
                                    {{message.job?.audit?.createdDate}}
                                </td>
                                <td *ngIf="userType != 'ROLE_CLIENT'"><span class="p-column-title">Spot Completion Date</span>
                                    {{message.job?.dropDateTime}}
                                </td>
                                <td *ngIf="userType != 'ROLE_CLIENT'"><span class="p-column-title">Spot Status</span>
                                    {{message.job?.status}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <p-paginator [rows]="10" [showCurrentPageReport] = "true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" [totalRecords]="totalRecords"  (onPageChange)="paginate($event)"></p-paginator>
                </p-tabPanel>
                <p-tabPanel header="Sent">
                    <p-table #dt [loading]="loading" [value]="messages" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" [responsive]="true" [columns]="cols" [rows]="10" [globalFilterFields]="['type']" [rows]="10" [paginator]="false" [rowHover]="true" dataKey="id" [responsiveLayout]="'scroll'">
                        <ng-template pTemplate="caption">
                            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                <span>
                                    <p-dropdown [showClear]="true" placeholder="Filter Read/Unread" [options]="messageStatus" (onChange)="filterMessagesByReadAndUnread($event)" optionLabel="name" optionValue="code"></p-dropdown>
                                </span>
                                <span class="block mt-2 md:mt-0 p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th></th>
                                <th pSortableColumn="from">From</th>
                                <th pSortableColumn="to">To</th>
                                <th pSortableColumn="type">Type</th>
                                <th pSortableColumn="date">Date Sent</th>
                                <th pSortableColumn="jobNumber" *ngIf="userType == 'ROLE_CLIENT'">Spot Number</th>
                                <th pSortableColumn="createdDate" *ngIf="userType == 'ROLE_CLIENT'">Spot Creation Date</th>
                                <th pSortableColumn="completedDate" *ngIf="userType == 'ROLE_CLIENT'">Spot Completion Date</th>
                                <th pSortableColumn="status" *ngIf="userType == 'ROLE_CLIENT'">Spot Status</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-message let-i="rowIndex">
                            <tr>
                                <td>
                                    <button *ngIf="message.status == 'NEW'" pButton pRipple icon="pi pi-envelope" pTooltip="Open" class="p-button-rounded p-button-warning mt-2" (click)="readMessage(message,'changeStatus')"></button>
                                    <button *ngIf="message.status !== 'NEW'" pButton pRipple icon="pi pi-folder-open" pTooltip="Read" class="p-button-rounded p-button-success mt-2" (click)="readMessage(message,'none')"></button>
                                </td>
                                <td>
                                    <span class="p-column-title">From</span>
                                    {{message?.fromUser?.firstName}}  {{message?.fromUser?.lastName}}
                                </td>
                                <td>
                                    <span class="p-column-title">To</span>
                                    {{message?.toUser?.firstName}} {{message?.toUser?.lastName}}
                                </td>
                                <td>
                                    <span class="p-column-title">Type</span>
                                    <p-tag *ngIf="message?.type == 'TRAILER_TRUCK_MOVE'"  rounded="true" severity="danger" value="Trailer Truck Move"></p-tag>
                                    <p-tag *ngIf="message?.type == 'HOT_TRAILER'" rounded="true" severity="primary" value="Hot Trailer"></p-tag>
                                    <p-tag *ngIf="message?.type == 'INFORMATION'" rounded="true" severity="success" value="Information"></p-tag>
                                </td>
                                <td><span class="p-column-title">Date Sent</span>
                                    {{message.audit?.createdDate}}
                                </td>
                                <td *ngIf="userType == 'ROLE_CLIENT'"><span class="p-column-title">Spot Number</span>
                                    {{message.job?.jobNumber}}
                                </td>
                                <td *ngIf="userType == 'ROLE_CLIENT'"><span class="p-column-title">Spot Creation Date</span>
                                    {{message.job?.audit?.createdDate}}
                                </td>
                                <td *ngIf="userType == 'ROLE_CLIENT'"><span class="p-column-title">Spot Completion Date</span>
                                    {{message.job?.dropDateTime}}
                                </td>
                                <td *ngIf="userType == 'ROLE_CLIENT'"><span class="p-column-title">Spot Status</span>
                                    {{message.job?.status}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <p-paginator [rows]="10" [showCurrentPageReport] = "true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" [totalRecords]="totalRecords"  (onPageChange)="paginate($event)"></p-paginator>
                </p-tabPanel>
            </p-tabView>
            

            <!-- <p-dataView #dv [value]="jobs" [paginator]="true" [rows]="9" layout="list">

                <ng-template pTemplate="header">
					<div class="grid grid-nogutter">
						<div class="col-6 text-left">
                            <button pButton pRipple label="New Message" icon="pi pi-plus" class="p-button-success mr-2" (click)="openNew()"></button>
						</div>
						<div class="col-6 text-right">
						</div>
					</div>
				</ng-template>

                <ng-template let-job let-i="rowIndex" pTemplate="listItem">
					<div class="col-12">
						<div class="flex flex-column md:flex-row align-items-center p-3 w-full">
							<div class="flex-1 text-center md:text-left">
								<div class="font-bold flex text-xl">
                                    <span class="mr-2">#{{job.id}}</span>  
                                </div>
								<div class="flex align-items-center mt-3">
									<i class="pi pi-directions mr-2"></i>
									<p><span class="font-semibold">To :</span><span class="ml-2">{{job.to}}</span></p>
								</div>
								<div class="flex align-items-center mt-3">
									<i class="pi pi-directions-alt mr-2"></i>
									<p><span class="font-semibold">From :</span><span class="ml-2">{{job.from}}</span></p>
								</div>
                                <div class="flex align-items-center mt-3">
									<i class="pi pi-calendar mr-2"></i>
									<p><span class="font-semibold">Date :</span><span class="ml-2">{{job.date}}</span></p>
								</div>
                                <div class="flex align-items-center mt-3">
									<i class="pi pi-comment mr-2"></i>
									<p><span class="font-semibold">Message :</span><span class="ml-2">{{job.message}}</span></p>
								</div>
							</div>
						</div>
					</div>
				</ng-template>
			</p-dataView> -->
        </div>

        <p-dialog [(visible)]="messageFormDialog" [style]="{width: '600px', minHeight:'400px'}" header="New Message" [modal]="true" class="p-fluid" (onHide)="hideDialog()">
            <ng-template pTemplate="content">
                <form [formGroup]="messageForm">
                <div class="field">
                    <label>Type of Message<span class="text-danger">*</span></label>
                    <p-dropdown [options]="messageType" (onChange)="onTypeChange($event)" placeholder="Select Message Type" optionLabel="name" optionValue="code" formControlName="type"></p-dropdown>
                    <span
                            class="text-danger"
                            *ngIf="
                            messageForm.controls.type.touched &&
                            messageForm.controls.type.invalid && submitted
                            "
                            >Type is required</span
                    >
                </div>
                <div *ngIf="this.messageForm.controls.type.value == 'TRAILER_TRUCK_MOVE'">
                    <div *ngIf="this.userType !== 'ROLE_CLIENT'" class="field">
                        <label>Select Client<span class="text-danger">*</span></label>
                        <p-dropdown placeholder="Select Client" formControlName="clients" [options]="clients" (onChange)="onClientChange($event)" optionLabel="clientName" optionValue="clientId"></p-dropdown>
                    </div>
                    <div class="field">
                        <label>To<span class="text-danger">*</span></label>
                        <p-multiSelect placeholder="Select Recipients" [options]="userList" formControlName="toUserIds" optionLabel="fullName" optionValue="userId"></p-multiSelect>
                    </div>
                    <div class="field" *ngIf="!isNewTrailer">
                        <label>Trailer<span class="text-danger">*</span><p-tag class="tag-hover cursor-pointer" severity="success" (click)="showNewTrailerFields()" value="New Asset" icon="pi pi-plus"></p-tag></label>
                        <p-autoComplete formControlName="trailer" (onFocus)="isInputTouched=true" (onBlur)="isInputTouched=true" (input)="onUnitNumberChange($event)" [suggestions]="filteredFleets" (onSelect)="onFleetSelect($event)" dataKey="trailer" (completeMethod)="filterFleets($event)" [dropdown]="true">
                            <ng-template let-fleet pTemplate="item">
                                <div>{{fleet.trailer}}</div>
                            </ng-template>
                        </p-autoComplete>
                        <span
                            class="text-danger"
                            *ngIf="
                            (messageForm.controls.trailer.touched &&
                            messageForm.controls.trailer.invalid && submitted && !isTrailerFoundInList) || (messageForm.value.fleetId == '' && !isTrailerFoundInList && submitted)
                            "
                            >Please select a Trailer/Truck/Container</span
                    >
                    <span class="text-danger" *ngIf="messageForm.controls.fleetId.invalid && submitted && isTrailerFoundInList">Trailer/Unit # not found. Please add using "+New Asset"</span>
                       
                    </div>
                    <div *ngIf="isNewTrailer">
                        <p-tag class="tag-hover cursor-pointer" severity="warning" (click)="showNewTrailerFields()" value="Back" icon="pi pi-directions-alt"></p-tag>
                        <div class="field">
                            <label>Carrier<span class="text-danger">*</span></label>
                            <input pInputText id="spot" type="text" formControlName="carrier" />
                            <!-- <p-dropdown *ngIf="!newCarrier" [options]="carrierList" placeholder="Select Carrier Name" formControlName="carrier" optionLabel="carrier" optionValue="carrierId"></p-dropdown> -->
                        </div>
                        <div class="field">
                            <label>Type<span class="text-danger">*</span></label>
                            <p-dropdown placeholder="Select Type" formControlName="trailerType" [options]="dropdownItems" optionLabel="name" optionValue="code"></p-dropdown>
                        </div>
                        <div class="field">
                            <label>Trailer#<span class="text-danger">*</span></label>
                            <!-- <p-dropdown placeholder="Select Trailer" formControlName="fleetId" (onChange)="onFleetChange($event)" [options]="fleetList" optionLabel="unitNumber" optionValue="fleetId"></p-dropdown>
                             -->
                            <input pInputText id="spot" type="text" formControlName="trailer" />
                            <span
                                class="text-danger"
                                *ngIf="
                                    messageForm.controls.trailer.touched &&
                                    messageForm.controls.trailer.invalid && submitted
                                "
                                >Please enter the Trailer /Unit #</span
                            >
                           
                        </div>
                       
                    </div>
                    
                    <div class="field">
                        <label>Sequence ASN<span class="text-danger">*</span></label>
                        <input pInputText id="sequenceAsn" type="text" formControlName="sequenceAsn" />
                        <span
                            class="text-danger"
                            *ngIf="
                            messageForm.controls.sequenceAsn.touched &&
                            messageForm.controls.sequenceAsn.invalid && submitted
                            "
                            >Sequence ASN is required</span
                    >
                    </div>
                    <div class="field">
                        <label>Pickup Location<span class="text-danger">*</span></label>
                        <p-dropdown placeholder="Select Location" formControlName="pickupLocationId" (onChange)="onLocationChange($event,'pickup')" [options]="locationList" optionLabel="locationName" optionValue="locationId"></p-dropdown>
                    </div>
                    <div class="field">
                        <label>Pickup Dock/Spot#<span class="text-danger">*</span></label>
                        <p-dropdown placeholder="Select Docks/Spots" formControlName="pickupSpotId" [options]="pickupSpotList" optionLabel="spotAndStatus" optionValue="spotId"></p-dropdown>
                    </div>
                    <div class="field">
                        <label>Drop Location<span class="text-danger">*</span></label>
                        <p-dropdown placeholder="Select Location" formControlName="dropLocationId" (onChange)="onLocationChange($event,'drop')" [options]="locationList" optionLabel="locationName" optionValue="locationId"></p-dropdown>
                    </div>
                    <div class="field">
                        <label>Drop Dock/Spot#<span class="text-danger">*</span></label>
                        <p-dropdown placeholder="Select Docks/Spots" formControlName="dropSpotId" [options]="dropSpotList" optionLabel="spotAndStatus" optionValue="spotId"></p-dropdown>
                    </div>
                    <div class="field">
                        <label for="message">Message<span class="text-danger">*</span></label>
                        <textarea pInputTextarea autoResize rows="3" cols="30" formControlName="messageBody"></textarea>
                        <span
                            class="text-danger"
                            *ngIf="
                            messageForm.controls.messageBody.touched &&
                            messageForm.controls.messageBody.invalid && submitted
                            "
                            >Message Body is required</span
                        >
                    </div>
                    <!-- <div class="field col-12">
                        <div class="grid">
                            <div class="col-12">
                                <label htmlFor="messagePriorities">Message Priorities<span class="text-danger">*</span></label>
                            </div>
                            <div class="col-12 md:col-4">
                                <div class="field-radiobutton mb-0">
                                    <p-radioButton name="priority" value="HIGH" formControlName="priority" id="priority"></p-radioButton>
                                    <p-tag rounded="true" severity="danger" value="High"></p-tag>
                                </div>
                            </div>
                            <div class="col-12 md:col-4">
                                <div class="field-radiobutton">
                                    <p-radioButton name="priority" value="MEDIUM" formControlName="priority"
                                                id="priority2"></p-radioButton>
                                    <p-tag rounded="true" severity="primary" value="Medium"></p-tag>
                                </div>
                            </div>
                            <div class="col-12 md:col-4">
                                <div class="field-radiobutton">
                                    <p-radioButton name="priority" value="LOW" formControlName="priority" id="priority3"></p-radioButton>
                                    <p-tag rounded="true" severity="success" value="Low"></p-tag>
                                </div>
                            </div>
                            <span
                            class="text-danger"
                            *ngIf="
                            messageForm.controls.priority.touched &&
                            messageForm.controls.priority.invalid && submitted
                            "
                            >Priority is required</span
                            >
                        </div>
                    </div> -->
                </div>
                <div *ngIf="this.messageForm.controls.type.value == 'HOT_TRAILER'">
                    <div *ngIf="this.userType !== 'ROLE_CLIENT'" class="field">
                        <label>Select Client<span class="text-danger">*</span></label>
                        <p-dropdown placeholder="Select Client" formControlName="clients" [options]="clients" (onChange)="onClientChange($event)" optionLabel="clientName" optionValue="clientId"></p-dropdown>
                    </div>
                    <div class="field">
                        <label>To<span class="text-danger">*</span></label>
                        <p-multiSelect placeholder="Select Recipients" [options]="userList" formControlName="toUserIds" optionLabel="fullName" optionValue="userId"></p-multiSelect>
                    </div>
                    <div class="field">
                        <label>Client Location<span class="text-danger">*</span></label>
                        <p-dropdown placeholder="Select Location" formControlName="clientLocationId" [options]="locationList" optionLabel="locationName" optionValue="locationId"></p-dropdown>
                        <span
                        class="text-danger"
                        *ngIf="
                        messageForm.controls.clientLocationId.touched &&
                        messageForm.controls.clientLocationId.invalid && submitted
                        "
                        >Client Location is required</span
                    >
                    </div>
                    <div *ngIf="!showHotTrailerNumberField" class="field">
                        <label>Hot Trailer#<span class="text-danger">*</span> <p-tag class="tag-hover" severity="success" (click)="showTrailerFields()" value="Add" icon="pi pi-plus"></p-tag></label>
                        <p-dropdown placeholder="Select Hot Trailer" formControlName="fleetId" (onChange)="onFleetChange($event)" [options]="fleetList" optionLabel="unitNumber" optionValue="fleetId"></p-dropdown>
                    </div>
                    <div *ngIf="showHotTrailerNumberField">
                        <p-tag class="tag-hover" severity="warning" (click)="showTrailerFields()" value="Back" icon="pi pi-directions-alt"></p-tag>
                        <div class="field">
                            <label>Carrier<span class="text-danger">*</span></label>
                            <input pInputText id="spot" type="text" formControlName="carrier" />
                            <!-- <p-dropdown *ngIf="!newCarrier" [options]="carrierList" placeholder="Select Carrier Name" formControlName="carrier" optionLabel="carrier" optionValue="carrierId"></p-dropdown> -->
                        </div>
                        <div class="field">
                            <label>Hot Trailer#<span class="text-danger">*</span></label>
                            <!-- <p-dropdown placeholder="Select Trailer" formControlName="fleetId" (onChange)="onFleetChange($event)" [options]="fleetList" optionLabel="unitNumber" optionValue="fleetId"></p-dropdown>
                             -->
                            <input pInputText id="spot" type="text" formControlName="unitNumber" />
                            <span
                                class="text-danger"
                                *ngIf="
                                    messageForm.controls.unitNumber.touched &&
                                    messageForm.controls.unitNumber.invalid && submitted
                                "
                                >Hot trailer# is required</span
                            >
                        </div>
                    </div>
                    
                    <div class="field">
                        <label for="message">Message<span class="text-danger">*</span></label>
                        <textarea pInputTextarea autoResize formControlName="messageBody" rows="3" cols="30"></textarea>
                        <span
                        class="text-danger"
                        *ngIf="
                        messageForm.controls.messageBody.touched &&
                        messageForm.controls.messageBody.invalid && submitted
                        "
                        >Message Body is required</span
                    >
                    </div>
                    <!-- <div class="field col-12">
                        <div class="grid">
                            <div class="col-12">
                                <label htmlFor="messagePriorities">Message Priorities<span class="text-danger">*</span></label>
                            </div>
                            <div class="col-12 md:col-4">
                                <div class="field-radiobutton mb-0">
                                    <p-radioButton name="priority" value="HIGH" formControlName="priority" id="priority"></p-radioButton>
                                    <p-tag rounded="true" severity="danger" value="High"></p-tag>
                                </div>
                            </div>
                            <div class="col-12 md:col-4">
                                <div class="field-radiobutton">
                                    <p-radioButton name="priority" value="MEDIUM" formControlName="priority"
                                                id="priority2"></p-radioButton>
                                    <p-tag rounded="true" severity="primary" value="Medium"></p-tag>
                                </div>
                            </div>
                            <div class="col-12 md:col-4">
                                <div class="field-radiobutton">
                                    <p-radioButton name="priority" value="LOW" formControlName="priority" id="priority3"></p-radioButton>
                                    <p-tag rounded="true" severity="success" value="Low"></p-tag>
                                </div>
                            </div>
                            <span
                            class="text-danger"
                            *ngIf="
                            messageForm.controls.priority.touched &&
                            messageForm.controls.priority.invalid && submitted
                            "
                            >Priority is required</span
                            >
                        </div>
                    </div> -->
                </div>
                <div *ngIf="this.messageForm.controls.type.value == 'INFORMATION'">
                    <div class="field">
                        <label>To<span class="text-danger">*</span></label>
                        <p-multiSelect placeholder="Select Recipients" [options]="userList" formControlName="toUserIds" optionLabel="fullName" optionValue="userId"></p-multiSelect>
                    </div>
                    <div class="field">
                        <label for="message">Message<span class="text-danger">*</span></label>
                        <textarea pInputTextarea autoResize formControlName="messageBody" rows="3" cols="30"></textarea>
                        <span
                        class="text-danger"
                        *ngIf="
                        messageForm.controls.messageBody.touched &&
                        messageForm.controls.messageBody.invalid && submitted
                        "
                        >Message Body is required</span
                    >
                    </div>
                    <!-- <div class="field col-12">
                        <div class="grid">
                            <div class="col-12">
                                <label htmlFor="messagePriorities">Message Priorities<span class="text-danger">*</span></label>
                            </div>
                            <div class="col-12 md:col-4">
                                <div class="field-radiobutton mb-0">
                                    <p-radioButton name="priority" value="HIGH" formControlName="priority" id="priority"></p-radioButton>
                                    <p-tag rounded="true" severity="danger" value="High"></p-tag>
                                </div>
                            </div>
                            <div class="col-12 md:col-4">
                                <div class="field-radiobutton">
                                    <p-radioButton name="priority" value="MEDIUM" formControlName="priority"
                                                id="priority2"></p-radioButton>
                                    <p-tag rounded="true" severity="primary" value="Medium"></p-tag>
                                </div>
                            </div>
                            <div class="col-12 md:col-4">
                                <div class="field-radiobutton">
                                    <p-radioButton name="priority" value="LOW" formControlName="priority" id="priority3"></p-radioButton>
                                    <p-tag rounded="true" severity="success" value="Low"></p-tag>
                                </div>
                            </div>
                            <span
                            class="text-danger"
                            *ngIf="
                            messageForm.controls.priority.touched &&
                            messageForm.controls.priority.invalid && submitted
                            "
                            >Priority is required</span
                            >
                        </div>
                    </div> -->
                </div>
            </form>
            </ng-template>

            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
                <button pButton pRipple label="Send" icon="pi pi-check" class="p-button-text" (click)="sendMessage()"></button>
            </ng-template>
        </p-dialog>

        <p-dialog [(visible)]="readDialog" header="{{messapegPopupTitle}}" [modal]="true" [style]="{width:'750px'}" (onHide)="hideMessage()">
            <ng-template pTemplate="content">
                <div class="flex flex-column md:flex-row align-items-center p-3 w-full" *ngIf="!jobCheck">
                    <div class="flex-1 text-center md:text-left">
                        <div class="flex align-items-center mt-3">
                            <i class="pi pi-directions mr-2"></i>
                            <p><span class="font-semibold">To :</span><span class="ml-2" *ngIf="!replyDialog">{{modalMessage.toUser?.firstName}} {{modalMessage.toUser?.lastName}}</span><span class="ml-2" *ngIf="replyDialog">{{modalMessage.fromUser?.firstName}} {{modalMessage.fromUser?.lastName}}</span></p>
                        </div>
                        <div class="flex align-items-center mt-3">
                            <i class="pi pi-directions-alt mr-2"></i>
                            <p><span class="font-semibold">From :</span><span class="ml-2" *ngIf="replyDialog">{{modalMessage.toUser?.firstName}} {{modalMessage.toUser?.lastName}}</span><span class="ml-2" *ngIf="!replyDialog">{{modalMessage.fromUser?.firstName}} {{modalMessage.fromUser?.lastName}}</span></p>
                        </div>
                        <div class="flex align-items-center mt-3">
                            <i class="pi pi-calendar mr-2"></i>
                            <p><span class="font-semibold">Date :</span><span class="ml-2">{{modalMessage.audit?.createdDate}}</span></p>
                        </div>
                        <div *ngIf="modalMessage.type == 'TRAILER_TRUCK_MOVE'">
                            <div class="flex align-items-center mt-3">
                                <i class="pi pi-map-marker mr-2"></i>
                                <p><span class="font-semibold">Client :</span><span class="ml-2">{{modalMessage.clientName}}</span></p>
                            </div>
                            <div class="flex align-items-center mt-3">
                                <i class="pi pi-map-marker mr-2"></i>
                                <p><span class="font-semibold">Pickup Location :</span><span class="ml-2">{{modalMessage.pickupLocation.locationName}}</span></p>
                            </div>
                            <div class="flex align-items-center mt-3">
                                <i class="pi pi-map-marker mr-2"></i>
                                <p><span class="font-semibold">Pickup Parking Spot :</span><span class="ml-2">{{modalMessage.pickupSpot.spotName}}</span></p>
                            </div>
                            <div class="flex align-items-center mt-3">
                                <i class="pi pi-map-marker mr-2"></i>
                                <p><span class="font-semibold">Drop Location :</span><span class="ml-2">{{modalMessage.dropLocation.locationName}}</span></p>
                            </div>
                            <div class="flex align-items-center mt-3">
                                <i class="pi pi-map-marker mr-2"></i>
                                <p><span class="font-semibold">Drop Parking Spot :</span><span class="ml-2">{{modalMessage.dropSpot.spotName}}</span></p>
                            </div>
                            <div class="flex align-items-center mt-3">
                                <i class="pi pi-map-marker mr-2"></i>
                                <p><span class="font-semibold">Trailer :</span><span class="ml-2">{{modalMessage.fleet?.unitNumber}}</span></p>
                            </div>
                            <div class="flex align-items-center mt-3">
                                <i class="pi pi-map-marker mr-2"></i>
                                <p><span class="font-semibold">Sequence ASN :</span><span class="ml-2">{{modalMessage.sequenceAsn}}</span></p>
                            </div>
                        </div>
                        <div *ngIf="modalMessage.type == 'HOT_TRAILER'">
                            <div class="flex align-items-center mt-3">
                                <i class="pi pi-map-marker mr-2"></i>
                                <p><span class="font-semibold">Client :</span><span class="ml-2">{{modalMessage.clientName}}</span></p>
                            </div>
                            <div class="flex align-items-center mt-3">
                                <i class="pi pi-map-marker mr-2"></i>
                                <p><span class="font-semibold">Client Location :</span><span class="ml-2">{{modalMessage.clientLocation.locationName ? modalMessage.clientLocation.locationName : '-' }}</span></p>
                            </div>
                            <div class="flex align-items-center mt-3">
                                <i class="pi pi-map-marker mr-2"></i>
                                <p><span class="font-semibold">Hot Trailer# :</span><span class="ml-2">{{modalMessage.fleet.unitNumber ? modalMessage.fleet.unitNumber : '-'}}</span></p>
                            </div>
                            <div class="flex align-items-center mt-3">
                                <i class="pi pi-map-marker mr-2"></i>
                                <p><span class="font-semibold">Carrier :</span><span class="ml-2">{{modalMessage.fleet.carrier ? modalMessage.fleet.carrier : '-'}}</span></p>
                            </div>
                        </div>
                        <div class="flex align-items-center mt-3">
                            <i class="pi pi-comment mr-2"></i>
                            <p><span class="font-semibold">Message :</span><span class="ml-2">{{modalMessage.messageBody}}</span></p>
                        </div>
                        <div class="flex align-items-center mt-3">
                            <i class="pi pi-comment mr-2"></i>
                            <p><span class="font-semibold">Message Type :</span><span class="ml-2">
                                <p-tag *ngIf="modalMessage?.type == 'TRAILER_TRUCK_MOVE'"  rounded="true" severity="danger" value="Trailer Truck Move"></p-tag>
                                    <p-tag *ngIf="modalMessage?.type == 'HOT_TRAILER'" rounded="true" severity="primary" value="Hot Trailer"></p-tag>
                                    <p-tag *ngIf="modalMessage?.type == 'INFORMATION'" rounded="true" severity="success" value="Information"></p-tag>
                            </span></p>
                        </div>

                        <!-- Reply Form Start -->
                        <form [formGroup]="replyForm" *ngIf="replyDialog">
                            <div class="field p-fluid mt-4">
                                <div><label for="message"><p><span class="font-semibold">Reply Message :</span><span class="text-danger">*</span></label></div>
                                <textarea pInputTextarea autoResize formControlName="messageBody" rows="3" cols="30"></textarea>
                                <span class="text-danger d-block" *ngIf="
                                                    replyForm.controls.messageBody.touched &&
                                                    replyForm.controls.messageBody.invalid && replySubmitted
                                                    ">Message Body is required</span>
                            </div>
                        </form>
                        <!-- Reply Form Ends -->

                    </div>
                    
                </div>
                <!-- New Spot Creation starts -->
                <form [formGroup]="jobForm" *ngIf="jobCheck">
                    <div class="p-fluid p-formgrid grid">
                        <div *ngIf="userType !== 'ROLE_CLIENT'" class="field col-12 md:col-12">
                            <label htmlFor="client">Select Client<span class="text-danger">*</span></label>
                            <p-dropdown placeholder="Select Client" formControlName="clientId" [options]="clients" (onChange)="onClientSelect($event)" optionLabel="clientName" optionValue="clientId"></p-dropdown>
                            <span
                                class="text-danger"
                                *ngIf="
                                jobForm.controls.clientId.touched &&
                                jobForm.controls.clientId.invalid && jobSubmitted
                                "
                                >Client is required</span
                                >
                        </div>
                        <div class="field col-12 md:col-6">
                            <label htmlFor="pickupLocation">Pickup Location<span class="text-danger">*</span></label>
                            <p-dropdown [options]="locationList" formControlName="pickupLocationId" placeholder="Select Pickup Location" optionLabel="locationName" (onChange)="onChange($event,'pickup')" optionValue="locationId" appendTo="body"></p-dropdown>
                            <span
                                class="text-danger"
                                *ngIf="
                                jobForm.controls.pickupLocationId.touched &&
                                jobForm.controls.pickupLocationId.invalid && jobSubmitted
                                "
                                >Pickup Location is required</span
                                >
                        </div>
                        <div class="field col-12 md:col-6">
                            <label htmlFor="pickupSpot">Pickup Dock/Parking Spot<span class="text-danger">*</span></label>
                            <p-dropdown [options]="pickupSpots" formControlName="pickupSpotId" placeholder="Select Pickup Spot" optionLabel="spotAndStatus" optionValue="spotId" appendTo="body"></p-dropdown>
                            <span
                                class="text-danger"
                                *ngIf="
                                jobForm.controls.pickupSpotId.touched &&
                                jobForm.controls.pickupSpotId.invalid && jobSubmitted
                                "
                                >Pickup Dock/Parking Spot is required</span
                                >
                        </div>
                        <div class="field col-12 md:col-6">
                            <label htmlFor="dropLocation">Drop Location<span class="text-danger">*</span></label>
                            <p-dropdown [options]="locationList" formControlName="dropLocationId" placeholder="Select Drop Location" optionLabel="locationName" (onChange)="onChange($event,'drop')" optionValue="locationId" appendTo="body"></p-dropdown>
                            <span
                                class="text-danger"
                                *ngIf="
                                jobForm.controls.dropLocationId.touched &&
                                jobForm.controls.dropLocationId.invalid && jobSubmitted
                                "
                                >Drop Location is required</span
                                >
                        </div>
                        <div class="field col-12 md:col-6">
                            <label htmlFor="dropSpot">Drop off Dock/Parking Spot</label>
                            <p-dropdown [options]="dropSpots" formControlName="dropSpotId" placeholder="Select Drop Spot" optionLabel="spotAndStatus" optionValue="spotId" appendTo="body"></p-dropdown>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label htmlFor="sequenceAsn">Sequence ASN<span class="text-danger">*</span></label>
                            <input pInputText id="sequenceAsn" formControlName="sequenceAsn" type="text" />
                            <span
                            class="text-danger"
                            *ngIf="
                            jobForm.controls.sequenceAsn.touched &&
                            jobForm.controls.sequenceAsn.invalid && jobSubmitted
                            "
                            >Sequence ASN is required</span
                            >
                        </div>
                            
                        <div class="field col-12">
                            <label htmlFor="jobDescription">Notes</label>
                            <textarea pInputTextarea autoResize rows="3" cols="30" formControlName="description"></textarea>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label htmlFor="drivers">Drivers/Yard Spotters<span class="text-danger">*</span></label>
                            <p-dropdown [options]="drivers" formControlName="assignedToUserId" optionLabel="fullName" placeholder="Select Drivers/Yard Spotters" optionValue="userId" ></p-dropdown>
                            <span
                                class="text-danger"
                                *ngIf="
                                jobForm.controls.assignedToUserId.touched &&
                                jobForm.controls.assignedToUserId.invalid && jobSubmitted
                                "
                                >Driver/Yard Spotter is required</span
                                >
                        </div>
                        <div class="field col-12 md:col-6"></div>
                        <div class="field col-12 md:col-6">
                            <label htmlFor="trailerTrucks">Trailer/Truck/Container<span class="text-danger">*</span></label>
                            <p-autoComplete formControlName="trailer" (onFocus)="isInputTouched=true" (onBlur)="isInputTouched = true" (input)="onUnitNumberChange_jobForm($event)" [suggestions]="filteredFleets" dataKey="trailer" (onSelect)="onFleetSelect_jobForm($event)" (completeMethod)="filterFleets($event)" [dropdown]="true">
                                <ng-template let-fleet pTemplate="item">
                                    <div>{{fleet.trailer}}</div>
                                </ng-template>
                            </p-autoComplete>
                            
                            <input pInputText type="hidden" formControlName="fleetId" />
                               <span
                                class="text-danger"
                                *ngIf="
                                jobForm.controls.fleetId.touched &&
                                jobForm.controls.fleetId.invalid && 
                                uniqueClientId!=null && jobSubmitted
                                "
                                >Please select a Trailer/Truck/Container</span
                                >
                                <span
                                class="text-danger"
                                *ngIf="trailerIsValid && jobSubmitted"
                                >The selected trailer is either deleted or not active in the system. Please select.</span
                                >
                               
                        </div>
                        <div class="field col-12 md:col-6">
                            <label htmlFor="trailerTrucks">Trailer/Container Status</label>
                            <p-dropdown [options]="trailerStatus" formControlName="fleetStatus" placeholder="Select Trailer/Container Status" optionLabel="name" optionValue="code"></p-dropdown>
                        </div>
                        
                        <div class="field col-12">
                            <div class="grid">
                                <div class="col-12">
                                    <label htmlFor="jobPriorities">Spot Priorities<span class="text-danger">*</span></label>
                                </div>
                                <div class="col-12 md:col-4">
                                    <div class="field-radiobutton mb-0">
                                        <p-radioButton name="priority" value="HIGH" formControlName="priority" id="priority"></p-radioButton>
                                        <p-tag rounded="true" severity="danger" value="High"></p-tag>
                                    </div>
                                </div>
                                <div class="col-12 md:col-4">
                                    <div class="field-radiobutton">
                                        <p-radioButton name="priority" value="MEDIUM" formControlName="priority"
                                                    id="priority2"></p-radioButton>
                                        <p-tag rounded="true" severity="primary" value="Medium"></p-tag>
                                    </div>
                                </div>
                                <div class="col-12 md:col-4">
                                    <div class="field-radiobutton">
                                        <p-radioButton name="priority" value="LOW" formControlName="priority" id="priority3"></p-radioButton>
                                        <p-tag rounded="true" severity="success" value="Low"></p-tag>
                                    </div>
                                </div>
                                <span
                                class="text-danger"
                                *ngIf="
                                jobForm.controls.priority.touched &&
                                jobForm.controls.priority.invalid && jobSubmitted
                                "
                                >Priority is required</span
                                >
                            </div>
                        </div>
                        
                    </div>
                </form>
                <!-- New Spot Creation Ends -->

            </ng-template>
            <ng-template pTemplate="footer">
                <button *ngIf="!replyDialog && !jobCheck && modalMessage.messageType == 1" pButton pRipple icon="pi pi-reply" label= "Reply" class="p-button-help" (click)="sentReply(modalMessage)"></button>
                <button *ngIf="isSupervisorItAdmin && modalMessage?.type == 'TRAILER_TRUCK_MOVE' && !replyDialog && !jobCheck && modalMessage.messageType == 1 && (modalMessage.job == null)" pButton pRipple icon="pi pi-plus" label="New Spot" class="p-button-secondary" (click)="addSpot(modalMessage)"></button>
                <button *ngIf="!replyDialog && !jobCheck" pButton pRipple label="Ok" icon="pi pi-check" class="p-button-success" (click)="hideMessage()"></button>
                <button *ngIf="replyDialog && !jobCheck" pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideReplyDialog()"></button>
                <button *ngIf="replyDialog && !jobCheck" pButton pRipple label="Send Reply" icon="pi pi-check" class="p-button-text" (click)="sendReplyMessage()"></button>
                <button *ngIf="!replyDialog && jobCheck" pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideJobDialog()"></button>
                <button *ngIf="!replyDialog && jobCheck" pButton pRipple label="Submit" icon="pi pi-plus" class="p-button-text" (click)="addJob()"></button>

            </ng-template>
        </p-dialog>

        <!-- <p-dialog [(visible)]="addFleetDialog" header="{{addTrailerHeader}}" [modal]="true" [style]="{width:'450px'}" (onHide)="cancelAddFleet()">
            <div *ngIf="!addFleetDialogConfirm" class="flex align-items-center justify-content-start">
                <i class="pi pi-exclamation-triangle mr-3 my-4" style="font-size: 2rem"></i>
                <span>The trailer number you entered is not found in our system. Do you want to add?</span>
            </div>
            <form [formGroup]="newfleetForm" *ngIf="addFleetDialogConfirm">
                <div class="p-fluid p-formgrid grid">
                    <div class="field col-12 md:col-6">
                        <label htmlFor="type">Type<span class="text-danger">*</span></label>
                        <p-dropdown [options]="dropdownItems" formControlName="type" placeholder="Select Type" optionLabel="name" optionValue="code"></p-dropdown>
                        <span
                            class="text-danger"
                            *ngIf="
                                newfleetForm.controls.type.touched &&
                                newfleetForm.controls.type.invalid && trailerSubmitted
                            "
                            >Type is required</span
                        >
                    </div>
                    <div class="field col-12 md:col-6">
                        <label htmlFor="">Unit Number<span class="text-danger">*</span></label>
                        <input pInputText id="unitNumber" formControlName="unitNumber" type="text" />
                        <span
                            class="text-danger"
                            *ngIf="
                                newfleetForm.controls.unitNumber.touched &&
                                newfleetForm.controls.unitNumber.invalid && trailerSubmitted
                            "
                            >Unit Number is required</span
                        >
                    </div>
                    <div class="field col-12">
                       
                        <label htmlFor="carrier">Carrier<span class="text-danger">*</span></label>
                        <p-autoComplete formControlName="carrier" (completeMethod)="getCarrier($event)" [suggestions]="uniqueCarrierList" dataKey="carrier" [dropdown]="true" (onClear)="clearCarrier()" >
                            <ng-template>
                                <div>{{uniqueCarrierList}}</div>
                            </ng-template>
                        </p-autoComplete>
                        <span
                            class="text-danger"
                            *ngIf="
                            newfleetForm.controls.carrier.touched &&
                            newfleetForm.controls.carrier.invalid && trailerSubmitted
                            ">Carrier is required
                            </span>
                           
                    </div>
                    
                    <div class="field col-12">
                        <label htmlFor="remarks">Notes</label>
                        <textarea pInputTextarea autoResize formControlName="remarks" rows="3" cols="30"></textarea>
                    </div>
                    
                </div>
            </form>
            <ng-template pTemplate="footer">
                <button *ngIf="!addFleetDialogConfirm" pButton pRipple icon="pi pi-times" class="p-button-text" label="No" (click)="addFleetDialog = false"></button>
                <button *ngIf="!addFleetDialogConfirm" pButton pRipple icon="pi pi-check" class="p-button-text" label="Yes" (click)="addFleetConfirm()"></button>
                <button *ngIf="addFleetDialogConfirm" pButton pRipple icon="pi pi-times" class="p-button-text" label="Cancel" (click)="cancelAddFleet()"></button>
                <button *ngIf="addFleetDialogConfirm" pButton type="button" (click)="addFleet()" label="Submit"></button>
            </ng-template>
        </p-dialog> -->

    </div>
</div>
