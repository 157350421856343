import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { AppComponent } from '../app.component';
import { APP_CONSTANTS } from '../constants/app.constants';
import { IpagedQuery } from '../model/IpagedQuery';

@Component({
  selector: 'app-preview-canvas-map',
  templateUrl: './preview-canvas-map.component.html',
  styleUrls: ['./preview-canvas-map.component.scss']
})
export class PreviewCanvasMapComponent implements OnInit {

  @Input('locationMapJson') locationMapJson: any;
  @Input('locations') locations: any;

  canvas: any = "";
  context: any = "";
  countElem: any = "";
  jsondb: any = "";
  query: any = "";
  overlay: any = "";
  zoomVal: any = "";
  protected subscriptions: Subscription[] = [];

  constructor(private appMethods: AppComponent, private http: HttpClient) { }

  ngOnInit(): void {

    var importedJson = this.locationMapJson;

    this.canvas = document.getElementById("canvas");
    this.context = this.canvas.getContext('2d');

    this.zoomVal = document.getElementById("incRedVal");


    this.jsondb = JSON.parse(importedJson);
    this.preview(this.jsondb);

    this.overlay = document.getElementById('overlay');

    this.query = { isActive: true, size: 1000, page: 0 };



    const subscription = this.viewSpots(this.query, this.locations.clientId, this.locations.locationId).subscribe(
      (Response) => {
        var res = JSON.parse(JSON.stringify(Response));


        for (var i = 0; i < res.list.length; i++) {

          this.jsondb.components.find((o, j) => {
            if (o.uidname.toLowerCase() === res.list[i].spotName.toLowerCase()) {
              this.statusUpdate(res.list[i].spotName, res.list[i].status, res.list[i], j);
              return;
            }
          });

        }


        this.preview(this.jsondb);


      },
      (error) => {
      }
    )
    this.subscriptions.push(subscription);

  }



  ngOnDestroy() {
    if (!this.subscriptions) {
      return;
    }
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];

  }


  viewSpots(query: IpagedQuery, clientId, locationId): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        'isActive': query.isActive.valueOf(),
        'page': query.page.toString(),
        'size': query.size.toString(),
        'location.uuid': locationId.toString(),
        'sort': 'type,asc'
      }
    })
    return this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/clients/${clientId}/spots`, { params });
  }


  preview(json: any) {
    if (json.canvas.width != "") {
      this.canvas.setAttribute("width", json.canvas.width);
    }

    if (json.canvas.height != "") {
      this.canvas.setAttribute("height", json.canvas.height);
    }

    var zoom = (json.canvas.zoom != "" && json.canvas.zoom != null) ? json.canvas.zoom : "0.6";
    this.zoomVal.innerHTML = zoom;
    this.canvas.setAttribute("style", "transform: scale(" + zoom + ");transform-origin: 0% 0%;");
    var canvasContainer: any = document.getElementById("canvasContainer");
    canvasContainer.style.height = (zoom * json.canvas.height) + "px";


    this.countElem = 0;



    for (var i = 0; i < json.components.length; i++) {


      if (json.components[i].component == "RECT") this.appMethods.rect(this.context, json.components[i].left, json.components[i].top, json.components[i].w, json.components[i].h, json.components[i].borderWidth, json.components[i].borderColor, json.components[i].bgColor, 0, json.components[i].uidname);

      else if (json.components[i].component == "LINE") this.appMethods.line(this.context, json.components[i].left1, json.components[i].top1, json.components[i].left2, json.components[i].top2, "1", json.components[i].borderColor, 0, json.components[i].uidname);

      else if (json.components[i].component == "ARC") this.appMethods.arc(this.context, json.components[i].left, json.components[i].top, json.components[i].r, json.components[i].deg, json.components[i].borderWidth, json.components[i].borderColor, json.components[i].bgColor, 1, json.components[i].uidname);
      else if (json.components[i].component == "TEXT") this.appMethods.text(this.context, json.components[i].left, json.components[i].top, json.components[i].text, json.components[i].fontSize, json.components[i].fontFamily, json.components[i].color, 0, json.components[i].uidname);
      else if (json.components[i].component == "SPOT") this.appMethods.parkingLot(this.context, json.components[i].left, json.components[i].top, json.components[i].w, json.components[i].h, json.components[i].borderWidth, json.components[i].borderColor, json.components[i].bgColor, json.components[i].text, json.components[i].fontSize, json.components[i].fontFamily, json.components[i].color, 1, json.components[i].uidname);
      else if (json.components[i].component == "IMAGE") this.appMethods.drawImg(this.context, json.components[i].left, json.components[i].top, json.components[i].w, json.components[i].h, json.components[i].url, 1, json.components[i].uidname);



      this.countElem++;
    }

  }

  statusUpdate(a: any, b: any, c: any, i: any) {


    if ((b != undefined) && b.toLowerCase() == "occupied") {
      var color = "#057203";
      if (c.fleet != null) {
        color = ((c.fleet.fleetStatus != undefined) && c.fleet.fleetStatus.toLowerCase() == "full") ? "#ff0000" : "#057203";

        this.jsondb.components[i].fleetStatus = (c.fleet.fleetStatus != null) ? c.fleet.fleetStatus : "-";
        this.jsondb.components[i].carrier = (c.fleet.carrier != null) ? c.fleet.carrier : "-";
        this.jsondb.components[i].unitNumber = (c.fleet.unitNumber != null) ? c.fleet.unitNumber : "-";

      }

      if (c.hasOwnProperty("emptiedSinceSeconds")) {
        var resultstring: any = "";
        if (c.emptiedSinceSeconds != null && c.emptiedSinceSeconds != "") {
          const SECONDS_COUNT = parseInt(c.emptiedSinceSeconds);
          resultstring = Math.floor(moment.duration(SECONDS_COUNT, 'seconds').asDays()) + ':' + moment.duration(SECONDS_COUNT, 'seconds').hours() + ':' + moment.duration(SECONDS_COUNT, 'seconds').minutes() + ':' + moment.duration(SECONDS_COUNT, 'seconds').seconds();

        } else {
          resultstring = "-";
        }
        this.jsondb.components[i].timeElapsed = resultstring;
      }
      if (c.hasOwnProperty("occupiedSinceSeconds")) {
        var resultstring1: any = "";
        if (c.occupiedSinceSeconds != null && c.occupiedSinceSeconds != "") {
          const SECONDS_COUNT = parseInt(c.occupiedSinceSeconds);
          resultstring1 = Math.floor(moment.duration(SECONDS_COUNT, 'seconds').asDays()) + ':' + moment.duration(SECONDS_COUNT, 'seconds').hours() + ':' + moment.duration(SECONDS_COUNT, 'seconds').minutes() + ':' + moment.duration(SECONDS_COUNT, 'seconds').seconds();

          console.log(c.occupiedSinceSeconds);

        }
        else {
          resultstring1 = "-";
        }
        this.jsondb.components[i].occupiedTime = resultstring1;
      }
      this.jsondb.components[i].bgColor = color;

      this.jsondb.components[i].color = "#ffffff";
      this.jsondb.components[i].status = (b != undefined) ? b : "-";
      this.jsondb.components[i].type = (c.type != undefined) ? c.type : "Spot";

    } else if ((b != undefined) && b.toLowerCase() == "scheduled") {

      if (c.fleet != null) {

        this.jsondb.components[i].fleetStatus = (c.fleet.fleetStatus != null) ? c.fleet.fleetStatus : "-";
        this.jsondb.components[i].carrier = (c.fleet.carrier != null) ? c.fleet.carrier : "-";
        this.jsondb.components[i].unitNumber = (c.fleet.unitNumber != null) ? c.fleet.unitNumber : "-";

      }
      if (c.hasOwnProperty("emptiedSinceSeconds")) {
        var resultstring: any = "";
        if (c.emptiedSinceSeconds != null && c.emptiedSinceSeconds != "") {
          const SECONDS_COUNT = parseInt(c.emptiedSinceSeconds);
          resultstring = Math.floor(moment.duration(SECONDS_COUNT, 'seconds').asDays()) + ':' + moment.duration(SECONDS_COUNT, 'seconds').hours() + ':' + moment.duration(SECONDS_COUNT, 'seconds').minutes() + ':' + moment.duration(SECONDS_COUNT, 'seconds').seconds();

        } else {
          resultstring = "-";
        }
        this.jsondb.components[i].timeElapsed = resultstring;
      }

      this.jsondb.components[i].bgColor = "#e68a00";
      this.jsondb.components[i].color = "#ffffff";
      this.jsondb.components[i].status = (b != undefined) ? b : "";
      this.jsondb.components[i].type = (c.type != undefined) ? c.type : "Spot";

    }

    else if ((b != undefined) && b.toLowerCase() == "to_be_occupied") {

      if (c.hasOwnProperty("emptiedSinceSeconds")) {
        var resultstring: any = "";
        if (c.emptiedSinceSeconds != null && c.emptiedSinceSeconds != "") {
          const SECONDS_COUNT = parseInt(c.emptiedSinceSeconds);
          resultstring = Math.floor(moment.duration(SECONDS_COUNT, 'seconds').asDays()) + ':' + moment.duration(SECONDS_COUNT, 'seconds').hours() + ':' + moment.duration(SECONDS_COUNT, 'seconds').minutes() + ':' + moment.duration(SECONDS_COUNT, 'seconds').seconds();

        } else {
          resultstring = "-";
        }
        this.jsondb.components[i].timeElapsed = resultstring;
      }

      this.jsondb.components[i].bgColor = "#e68a00";
      this.jsondb.components[i].color = "#ffffff";
      this.jsondb.components[i].status = (b != undefined) ? b : "";
      this.jsondb.components[i].type = (c.type != undefined) ? c.type : "Spot";

    }
    else if ((b != undefined) && b.toLowerCase() == "to_be_empty") {

      if (c.hasOwnProperty("emptiedSinceSeconds")) {
        var resultstring: any = "";
        if (c.emptiedSinceSeconds != null && c.emptiedSinceSeconds != "") {
          const SECONDS_COUNT = parseInt(c.emptiedSinceSeconds);
          resultstring = Math.floor(moment.duration(SECONDS_COUNT, 'seconds').asDays()) + ':' + moment.duration(SECONDS_COUNT, 'seconds').hours() + ':' + moment.duration(SECONDS_COUNT, 'seconds').minutes() + ':' + moment.duration(SECONDS_COUNT, 'seconds').seconds();

        } else {
          resultstring = "-";
        }
        this.jsondb.components[i].timeElapsed = resultstring;
      }


      this.jsondb.components[i].bgColor = "#ffff00";
      this.jsondb.components[i].color = "#000000";
      this.jsondb.components[i].status = (b != undefined) ? b : "";
      this.jsondb.components[i].type = (c.type != undefined) ? c.type : "Spot";

    }
    else if ((b != undefined) && b.toLowerCase() == "empty") {

      if (c.hasOwnProperty("emptiedSinceSeconds")) {
        var resultstring: any = "";
        if (c.emptiedSinceSeconds != null && c.emptiedSinceSeconds != "") {
          const SECONDS_COUNT = parseInt(c.emptiedSinceSeconds);
          resultstring = Math.floor(moment.duration(SECONDS_COUNT, 'seconds').asDays()) + ':' + moment.duration(SECONDS_COUNT, 'seconds').hours() + ':' + moment.duration(SECONDS_COUNT, 'seconds').minutes() + ':' + moment.duration(SECONDS_COUNT, 'seconds').seconds();

        } else {
          resultstring = "-";
        }
        this.jsondb.components[i].timeElapsed = resultstring;
      }


      this.jsondb.components[i].bgColor = "#ffffff";
      this.jsondb.components[i].color = "#000000";
      this.jsondb.components[i].status = (b != undefined) ? b : "";
      this.jsondb.components[i].type = (c.type != undefined) ? c.type : "Spot";
    }


  }



  hit(rect: any, x: any, y: any) {
    return (x >= parseInt(rect.left) && x <= parseInt(rect.left) + parseInt(rect.w) && y >= parseInt(rect.top) && y <= parseInt(rect.top) + parseInt(rect.h));
  }


  selectedUId: any = "";
  selectedColor: any = "";

  handleMouseDown(e: any) {
    var rectr = this.canvas.getBoundingClientRect();
    var mouseX: number = e.offsetX;
    var mouseY = e.offsetY;

    e.preventDefault();
    for (var i = 0; i < this.jsondb.components.length; i++) {

      if (this.selectedUId.toString() != "" && i == this.selectedUId) {
        this.jsondb.components[i].bgColor = this.selectedColor;
        this.selectedUId = "";
        this.selectedColor = "";
        break;
      }

    }

    var overlayVisible = 0;

    for (var i = 0; i < this.jsondb.components.length; i++) {
      if (this.jsondb.components[i].component == "SPOT") {
        if (this.hit(this.jsondb.components[i], mouseX, mouseY)) {
          this.overlay.style.display = "block";
          this.overlay.style.marginTop = (mouseY * this.zoomVal.innerHTML + 30) + "px";
          this.overlay.style.marginLeft = (mouseX * this.zoomVal.innerHTML) + "px";

          this.selectedUId = i;
          this.selectedColor = this.jsondb.components[i].bgColor;

          var uidnameShow: any = document.getElementById("uidnameShow");
          uidnameShow.innerHTML = this.jsondb.components[i].uidname;

          var overlayType: any = document.getElementById("overlayType");
          overlayType.innerHTML = this.jsondb.components[i].type;

          var overlayUidnameShow: any = document.getElementById("overlayUidnameShow");
          overlayUidnameShow.innerHTML = this.jsondb.components[i].uidname;

          var overlayStatus: any = document.getElementById("overlayStatus");
          var status = (this.jsondb.components[i].hasOwnProperty("status")) ? this.jsondb.components[i].status.replaceAll("_", " ") : "-";
          if ((status != undefined) && status.toLowerCase() == "to be occupied") {
            status = "Location Reserved";
          } else if ((status != undefined) && status.toLowerCase() == "to be empty") {
            status = "Scheduled for Pick-Up";
          }
          overlayStatus.innerHTML = status;

          var overlayTrailer = document.getElementById("overlayTrailer");
          overlayTrailer.innerHTML = (this.jsondb.components[i].hasOwnProperty("carrier")) ? this.jsondb.components[i].carrier : "-";


          var overlayUnitNumber = document.getElementById("overlayUnitNumber");
          overlayUnitNumber.innerHTML = (this.jsondb.components[i].hasOwnProperty("unitNumber")) ? this.jsondb.components[i].unitNumber : "-";


          var overlayTimeElapsed = document.getElementById("overlayTimeElapsed");
          overlayTimeElapsed.innerHTML = (this.jsondb.components[i].hasOwnProperty("timeElapsed")) ? this.jsondb.components[i].timeElapsed : "-";

          var occupiedTime = document.getElementById("occupiedTime");
          occupiedTime.innerHTML = (this.jsondb.components[i].hasOwnProperty("occupiedTime")) ? this.jsondb.components[i].occupiedTime : "-";

          overlayVisible = 1;

        }
      }

    }

    if (overlayVisible == 0) this.overlay.style.display = "none";
    this.preview(this.jsondb);

  }

  zoom(a: boolean) {
    var incRedVal: any = 0;
    if (a) {
      incRedVal = (parseFloat(this.zoomVal.innerHTML) + 0.1).toFixed(1);
      incRedVal = (incRedVal >= 1.0) ? 1.0 : incRedVal;

      this.zoomVal.innerHTML = String(incRedVal);
    } else if (!a) {
      incRedVal = (parseFloat(this.zoomVal.innerHTML) - 0.1).toFixed(1);
      incRedVal = (incRedVal <= 0.5) ? 0.5 : incRedVal;

      this.zoomVal.innerHTML = String(incRedVal);

    }

    this.canvas.setAttribute("style", "transform: scale(" + incRedVal + ");transfrom-origin:0% 0%;");
    var canvasContainer: any = document.getElementById("canvasContainer");
    canvasContainer.style.height = (incRedVal * this.canvas.style.height) + "px";

  }


}
