import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-userprivacy-policy',
  templateUrl: './userprivacy-policy.component.html',
  styleUrls: ['./userprivacy-policy.component.scss']
})
export class UserprivacyPolicyComponent implements OnInit {
  
  breadcrumbItems: MenuItem[];
  
  constructor() { }

  ngOnInit(): void {

    this.breadcrumbItems = [];
    this.breadcrumbItems.push({ label: 'Privacy Policy'});
  }

}
