<div class="grid">
    <div class="col-12">
        <div class="card card-w-title">
            <p-breadcrumb [model]="breadcrumbItems" [home]="{icon: 'pi pi-home',routerLink:'../'}"></p-breadcrumb>
        </div>
    </div>
    <div class="col-12">
        <div class="card">
            <p-toast></p-toast>
            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="left">
                    <div class="my-2">
                        <h5 class="m-0">Entry / Exit Detail</h5>
                    </div>
                </ng-template>

                <ng-template pTemplate="right">
                    <button *ngIf="userType !== 'ROLE_CLIENT'" pButton pRipple label="New" icon="pi pi-plus" class="p-button-secondary mr-2"  (click)="openEntryExitDialog()"></button>
                    <!-- <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help" (click)="exportExcel()"></button> -->
                    <p-splitButton label="Export" [model]="items" (onClick)="exportExcel()" styleClass="p-button-raised p-button-help mr-2 "></p-splitButton>
                        
                </ng-template>
            </p-toolbar>

            <p-table #dt [value]="entryExitList" [loading]="loading" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" [responsive]="true" [columns]="cols" [rows]="10" [globalFilterFields]="['location.locationName','dateOfEntry','fleet.carrier','fleet.unitNumber','fleet.owner','type','entryTime','audit.createdBy.firstName']" [rows]="10" [paginator]="false" [rowHover]="true" dataKey="id">
                <ng-template pTemplate="caption">
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <span>
                            <p-dropdown *ngIf="userType !== 'ROLE_CLIENT'" [showClear]="true" placeholder="Select Client" [options]="clients" (onChange)="filterFleetsByClient($event)" optionLabel="clientName" optionValue="clientId"></p-dropdown>
                        </span>
                        <span class="block mt-2 md:mt-0 p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th></th>
                        <th pSortableColumn="locations">Locations</th>
                        <th pSortableColumn="dateOfEntry">Date of Entry</th>
                        <th pSortableColumn="carrier">Carrier</th>
                        <th pSortableColumn="unitNumber">Unit #</th>
                        <th pSortableColumn="owner">Trailer/Container Owner</th>
                        <th pSortableColumn="typeOfEntry">Type</th>
                        <th pSortableColumn="trailer">Trailer/Container#</th>
                        <th pSortableColumn="entryTime">Entry Time</th>
                        <th pSortableColumn="exitTime">Exit Time</th>
                        <th pSortableColumn="reportBy">Reported By</th>
                        <!-- <th pSortableColumn="remarks">Remarks <p-sortIcon field="remarks"></p-sortIcon></th> -->
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-entry>
                    <tr>
                        <td>
                            <button pButton pRipple icon="pi pi-book" pTooltip="View Notes" [ngClass]="entry.type == 'ENTRY' ? 'p-button-success' : 'p-button-danger' " class="p-button-rounded mt-2" (click)="viewNotes(entry.notes)"></button>
                            <button *ngIf="userType !== 'ROLE_CLIENT' && hideButtonIfGuard === false" pButton pRipple icon="pi pi-trash" pTooltip="Delete" class="p-button-rounded p-button-danger mt-2" (click)="deleteEntryExit(entry.location.clientId,entry.guardEntryExitId)"></button>
                        </td>
                        <td>
                            <span class="p-column-title">Locations</span>
                            {{entry?.location?.locationName}}
                        </td>
                        <td>
                            <span class="p-column-title">Date of Entry</span>
                            {{ entry?.dateOfEntry }}
                        </td>
                        <td>
                            <span class="p-column-title">Carrier</span>
                            {{entry?.fleet?.carrier}}
                        </td>
                        <td>
                            <span class="p-column-title">Unit #</span>
                            {{entry?.fleet?.unitNumber}}
                        </td>
                        <td>
                            <span class="p-column-title">Trailer/Container Owner</span>
                            {{entry?.fleet?.owner ? entry?.fleet?.owner : '-'}}
                        </td>
                        <td>
                            <span class="p-column-title">Type</span>
                            {{entry?.type}}
                        </td>
                        <td>
                            <span class="p-column-title">Trailer/Container#</span>
                            {{entry?.fleet?.unitNumber}}
                        </td>
                        
                        <td>
                            <span class="p-column-title">Entry Time</span>
                            {{entry?.type == 'ENTRY' ? entry?.entryTime : '-'}}
                        </td>
                        <td>
                            <span class="p-column-title">Exit Time</span>
                            {{entry?.type == 'EXIT' ? entry?.entryTime : '-'}}
                        </td>
                        <td>
                            <span class="p-column-title">Reported By</span>
                            {{entry?.audit?.createdBy?.firstName}} {{entry?.audit?.createdBy?.lastName}}
                        </td>
                        <!-- <td style="width:14%; min-width: 10rem;"><span class="p-column-title">Remarks</span>
                            {{product.remarks}}
                        </td> -->
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="9">No entry exit record found.</td>
                    </tr>
                </ng-template>
            </p-table>
            <p-paginator [rows]="10" [showCurrentPageReport] = "true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" [totalRecords]="totalRecords"  (onPageChange)="paginate($event)"></p-paginator>
        </div>

        <p-dialog [(visible)]="deleteProductDialog" header="Notes" [modal]="true" [style]="{width:'450px'}">
            <div class="flex flex-column md:flex-row align-items-center p-3 w-full">
                <div class="flex-1 md:text-left">
                    <div class="flex align-items-center mt-3">
                        <p>{{modalNotes}}</p>
                    </div>
                </div>
            </div>
            <ng-template pTemplate="footer">
                <button pButton pRipple icon="pi pi-check" class="p-button-success" label="Ok" (click)="hideDialog()"></button>
            </ng-template>
        </p-dialog>

        
        <p-dialog [(visible)]="entryExitDialog" [style]="{width: '450px'}" header="Create Entry/Exit Record" [modal]="true" class="p-fluid">
            <ng-template pTemplate="content">
                <form [formGroup]="entryExitForm">
                    <div class="field">
                        <label htmlFor="client">Select Client<span class="text-danger">*</span></label>
                        <p-dropdown placeholder="Select Client" formControlName="clientId" [options]="clientList" (onChange)="onClientSelect($event)" optionLabel="clientName" optionValue="clientId"></p-dropdown>
                        <small class="text-danger" *ngIf="entryExitForm.controls.clientId.touched &&
                        entryExitForm.controls.clientId.invalid || isTrailerClicked ">Client is required.</small>
                    </div>
                    <div class="field">
                        <label htmlFor="pickupLocation">Location<span class="text-danger">*</span></label>
                        <p-dropdown [options]="clientsLocationsList" formControlName="locationId" (onChange)="forLocationId($event)"  placeholder="Select Pickup Location" optionLabel="locationName" optionValue="locationId"></p-dropdown>
                        <small class="text-danger" *ngIf="entryExitForm.controls.locationId.touched &&
                        entryExitForm.controls.locationId.invalid">Location is required.</small>
                    </div>
                    <div class="field">
                        <label htmlFor="pickupLocation">Trailer/Unit#<span class="text-danger">*</span></label>
                        <p-autoComplete placeholder="Filter By Trailer/Unit#" formControlName="fleetId" [suggestions]="fleetList" (onSelect)="onFleetSelect($event)" dataKey="fleetId" (completeMethod)="getFleetList($event)" [dropdown]="true" (onClear)="clearFilter($event)">
                            <ng-template let-fleet pTemplate="item">
                                <div>{{fleet.fleetAndHotTrailer}}</div>
                            </ng-template>
                        </p-autoComplete>
                        <small class="text-danger" *ngIf="entryExitForm.controls.fleetId.touched &&
                        entryExitForm.controls.fleetId.invalid">Trailer is required.</small>
                        <!-- <label for="fleet">Trailer/Container#<span class="text-danger">*</span></label>
                        <p-dropdown [options]="fleetList" placeholder="Select Trailer/Container" formControlName="fleetId" optionLabel="fleetAndHotTrailer" optionValue="fleetId"></p-dropdown>
                        <small class="ng-dirty ng-invalid" *ngIf="entryExitForm.controls.fleetId.touched &&
                        entryExitForm.controls.fleetId.invalid">Trailer/Container is required.</small> -->
                    </div>
                    <div class="field">
                        <label for="type">Type<span class="text-danger">*</span></label>
                        <p-dropdown [options]="type" placeholder="Select Type" formControlName="type" optionLabel="name" optionValue="code"></p-dropdown>
                        <small class="text-danger" *ngIf="entryExitForm.controls.type.touched &&
                        entryExitForm.controls.type.invalid">Type is required.</small>
                    </div>
                    <div class="field">
                        <label for="notes">Notes</label>
                        <textarea pInputTextarea autoResize formControlName="notes" rows="3" cols="30"></textarea>
                    </div>
                </form>
            </ng-template>

            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
                <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" type="button" (click)="createEntryExit()"></button>
            </ng-template>
        </p-dialog>
    </div>
</div>


<p-dialog [(visible)]="deleteEntryExitDialog" header="Confirm" [modal]="true" [style]="{width:'450px'}">
	<div class="flex align-items-center justify-content-start">
		<i class="pi pi-exclamation-triangle mr-3 my-4" style="font-size: 2rem"></i>
		<span *ngIf="entryExitId">Are you sure you want to delete this entry exit record ?</span>
	</div>
	<ng-template pTemplate="footer">
		<button pButton pRipple icon="pi pi-times" class="p-button-text" label="No" (click)="deleteEntryExitDialog = false"></button>
		<button pButton pRipple icon="pi pi-check" class="p-button-text" label="Yes" (click)="confirmDelete()"></button>
	</ng-template>
</p-dialog>
