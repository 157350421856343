import { Component, OnInit } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { AccountService } from './security/account.service';
import { AppLoaderService } from './app-loader/service/app-loader.service';
import { TokenService } from './security/token.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styleUrls:['./app.topbar.component.scss']
})
export class AppTopBarComponent implements OnInit {

    items: MenuItem[];
    username: string;
    userRoles: any;

    constructor(public appMain: AppMainComponent,
        private accountService:AccountService,
        private loader:AppLoaderService,
        private tokenService:TokenService,
        private router:Router) { }

    ngOnInit() {
        // this.items = [
        //     {
        //         label: 'John Doe',
        //         items: [{label: 'Logout',icon:'pi pi-fw pi-sign-out'}]
        //     }
        // ];
        this.username = this.tokenService.getUserFullName();
        this.userRoles = this.tokenService.getUserRoles();
    }

    logout(){
        this.loader.show();
        this.accountService.logout().subscribe((data: any) => {
          this.tokenService.removeToken();
          this.router.navigateByUrl('');
          this.loader.hide();
        }, error => {
          this.tokenService.removeToken();
          this.router.navigateByUrl('');
          this.loader.hide();
        });
    }
}
