
	<div class="block-content">
		<div class="pt-8 flex align-items-center justify-content-center"> 
            <img class="logo" src="../../../assets/logo/spoton.png" alt="logo"> 
        </div>
		<div *ngIf="isNewUser">
            <div *ngIf="!isUserActivated">
                <div class="surface-ground px-4 pt-6 md:px-6 lg:px-8 flex align-items-center justify-content-center">
                    <div class="surface-card p-4 shadow-0 border-round w-full lg:w-6">
                        <div class="text-center mb-5">
                            <!-- <img src="assets/demo/images/blocks/logos/hyper.svg" alt="Image" height="50" class="mb-3"> -->
                            <div class="text-900 text-3xl font-medium mb-3 text-white">Welcome  {{userName}}</div>
                        </div>
                        <div>
                            <form [formGroup]="newUserForm">
                                <label for="password1" class="block text-900 mb-2 text-white">Set Password</label>
                                 <input id="password1" type="password" formControlName="password1" pInputText class="w-full mb-3" placeholder="Password">
                                 <span class="text-danger" *ngIf="newUserForm.controls.password1.touched && newUserForm.controls.password1.invalid">please enter valid password</span> 
                                 
                                 <label for="password2" class="block text-900 mb-2 text-white">Re-enter Password</label>
                                 <input id="password2" type="password" formControlName="password2" pInputText class="w-full mb-3" placeholder="Password" (blur)="validatePassword()">
                                 <span class="text-danger" *ngIf="newUserForm.controls.password2.touched && newUserForm.controls.password2.invalid">Please enter the same password in both fields</span> 
                                 
                                 <div class="flex align-items-center justify-content-center mb-4"> 
                                    <button (click)="activate()" pButton pRipple label="Activate Account" class="flex w-6 p-button-rounded text-800 custom-button"></button> 
                                </div>
                            </form> 
                        </div>	
                    </div>
			</div>
            </div>
            <div *ngIf="isUserActivated">
                <div class="surface-ground px-4 pt-6 md:px-6 lg:px-8 flex align-items-center justify-content-center">
                    <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
                        <div class="text-center mb-5">
                            <!-- <img src="assets/demo/images/blocks/logos/hyper.svg" alt="Image" height="50" class="mb-3"> -->
                            <div class="text-900 text-3xl font-medium mb-3 text-white">Your account has been activated.</div>
                        </div>
                        	
                    </div>
			</div>
            </div>
		</div>
        <div *ngIf="!isNewUser">
            <div class="surface-ground px-4 pt-6 md:px-6 lg:px-8 flex align-items-center justify-content-center">
                <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
                    <div class="text-center mb-5">
                        <!-- <img src="assets/demo/images/blocks/logos/hyper.svg" alt="Image" height="50" class="mb-3"> -->
                        <div class="text-900 text-3xl font-medium mb-3 text-white">Your account has already been activated. </div>
                    </div> 
                </div>
        </div>
        </div>
	</div>
