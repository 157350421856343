import { Injectable } from '@angular/core';
import SockJs from "sockjs-client";
import Stomp from "stompjs";
import { APP_CONSTANTS } from './constants/app.constants';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {

  constructor() { }
  
  public connect() {
    let url = APP_CONSTANTS.BASE_API_URL + "/socket";
    let socket = new SockJs(url);

    let stompClient = Stomp.over(socket);

    return stompClient;
}
}
