<div class="grid">
    <div class="col-12">
        <div class="card card-w-title">
            <p-breadcrumb [model]="breadcrumbItems" [home]="{icon: 'pi pi-home',routerLink:'../'}"></p-breadcrumb>
        </div>
    </div>
    <div class="col-12">
        <div class="card">
            <p-toast></p-toast>
            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="left">
                    <div class="my-2">
                        <h5 class="m-0">Overtime Users</h5>
                    </div>
                </ng-template>

                <ng-template pTemplate="right">
                    <button *ngIf="userType !== 'ROLE_CLIENT' && hideButtonsIfDriver !== true && hideButtonsIfGuard === false && hideButtonsIfSpotter === false" pButton pRipple label="New" icon="pi pi-plus" class="p-button-success mr-2" (click)="routeToAddUser()"></button>
                    <!-- <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help" (click)="exportExcel()"></button> -->
<!-- 
                    <p-splitButton *ngIf="hideButtonsIfDriver !== true && hideButtonsIfSpotter === false" label="Export" [model]="items" (onClick)="exportExcel()" styleClass="p-button-raised p-button-help mr-2 "></p-splitButton>
                    <button *ngIf="userType == 'ROLE_DRIVER' || userType == 'SPOTTER'" pButton pRipple label="User Availability" icon="pi pi-plus" class="p-button-secondary mr-2" (click)="routeToUserAvailability()"></button>
                    <button pButton pRipple label="Overtime Users" icon="pi pi-plus" class="p-button-secondary" (click)="routeToOvertimeUsers()"></button> -->
                </ng-template>
            </p-toolbar>

            <p-table #dt [value]="userList" [loading]="loading" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" [responsive]="true" [columns]="cols" [rows]="10"  [rowHover]="true" dataKey="id">
                <ng-template pTemplate="caption">
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <span></span>
                        <!-- <span class="block mt-2 md:mt-0 p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="search()" [(ngModel)]="searchboxValue" placeholder="Search Name" />
                        </span> -->
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th></th>
                        <!-- <th pSortableColumn="date">Date</th> -->
                        <th pSortableColumn="firstName">First Name</th>
                        <th pSortableColumn="lastName">LastName</th>
                        <th pSortableColumn="email">Email</th>
                        <th pSortableColumn="phone">Phone</th>
                        <!-- <th pSortableColumn="startingTime">Start Time</th>
                        <th pSortableColumn="EndingTime">End Time</th> -->
                    </tr>
                    
                </ng-template>
                <ng-template pTemplate="body" let-user>
                    <tr>
                        <td>
                            <button pButton pRipple icon="pi pi-trash" pTooltip="Delete" class="p-button-rounded p-button-danger" (click)="deleteOvertimeUser(user)"></button>
                            <!-- <button *ngIf="user.isActive === false && hideButtonsIfDriver !== true "  pButton pRipple icon="pi pi-refresh" pTooltip="Activate" class="p-button-rounded p-button-success mr-2" (click)="activateUser(user.userId)"></button> -->
                            <!-- <button pButton pRipple icon="pi pi-key" pTooltip="Reset Password" class="p-button-rounded p-button-primary" (click)="resetPassword(user.userId)"></button> -->
                        </td>
                        <!-- <td><span class="p-column-title">Date</span>
                            {{user.date}}
                        </td> -->
                        <td><span class="p-column-title">First Name</span>
                            {{user.firstName}}
                        </td>
                        <td>
                            <span class="p-column-title">Last Name</span>
                            {{user.lastName}}
                        </td>
                        <td>
                            <span class="p-column-title">Email</span>
                            {{user.email}}
                        </td>
                        <td>
                            <span class="p-column-title">Phone</span>
                            {{user.phone}}
                        </td>
                        <!-- <td>
                            <span class="p-column-title">Start Time</span>
                            {{user.startingTime}}
                        </td>
                        <td>
                            <span class="p-column-title">End Time</span>
                            {{user.endingTime}}
                        </td> -->
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="7">No User found.</td>
                    </tr>
                </ng-template>
            </p-table>
            



            <p-paginator [rows]="100" [showCurrentPageReport] = "true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" [totalRecords]="totalRecords"  (onPageChange)="paginate($event)"></p-paginator>
            
        </div>
    </div>
    
    <!-- <p-dialog [(visible)]="resetPasswordModal" [style]="{width: '450px'}" header="New Password" [modal]="true" class="p-fluid">
        <ng-template pTemplate="content">
            <div class="flex align-items-center justify-content-center">
                <h4 class="mb-0">{{password}}</h4>
            </div>
        </ng-template>

        <ng-template pTemplate="footer">
            <button pButton pRipple label="Ok" icon="pi pi-check" class="p-button-text" type="button" (click)="resetPasswordModal=false"></button>
        </ng-template>
    </p-dialog> -->
</div>
