import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AppAlertService } from 'src/app/app-alert/service/app-alert.service';
import { AppLoaderService } from 'src/app/app-loader/service/app-loader.service';
import { ErrorService } from 'src/app/error-handling/error.service';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { TokenService } from 'src/app/security/token.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  emailForm: FormGroup;
  token = false;
  passwordForm: FormGroup;

  constructor(private router:Router,
    private tokenService:TokenService,
    private loader:AppLoaderService,
    private authenticationService: AuthenticationService,
    private errorService: ErrorService,
    private alertService:AppAlertService ) { 
    this.emailForm = new FormGroup({
      email : new FormControl('',[Validators.required,Validators.email])
    })

    this.passwordForm = new FormGroup({
      newPassword : new FormControl('',[Validators.required]),
      confirmNewPassword : new FormControl('',[Validators.required]),
      token : new FormControl('',[Validators.required]) 
    },this.pwdMatchValidator)
  }

  pwdMatchValidator(frm: FormGroup) {
    return frm.get('newPassword').value === frm.get('confirmNewPassword').value
       ? null : {'mismatch': true};
  }

  ngOnInit(): void {
  }

  sendOtp(){
    if(this.emailForm.invalid){
      this.emailForm.markAllAsTouched();
    }else{
      this.loader.show();
      this.authenticationService.sendOtp(this.emailForm.value).subscribe(res=>{
        this.token = true;
        this.loader.hide();
      },error=>{
        this.loader.hide();
        this.errorService.handleError(error,true);
      })
    }
  }

  showPasswordForm(){
    this.token = true;
  }

  hidePasswordForm(){
    this.token = false;
  }

  resetPassword(){
    if(this.passwordForm.invalid){
      this.passwordForm.markAllAsTouched();
    }else{
      this.loader.show();
      this.authenticationService.resetPassword(this.passwordForm.value).subscribe(res=>{
        this.alertService.alertSuccess(['Password reset successfully!']);
        this.router.navigate(['login'])
        this.loader.hide();
      },error=>{
        this.loader.hide();
        this.errorService.handleError(error,true);
      })
    }
  }

}
