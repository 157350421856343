import { environment } from '../../environments/environment.prod';

/**
 * Constants object which can hold app level constants
 */
export const APP_CONSTANTS = {
  BASE_API_URL: environment.API_URL,
  SOCKET_URL: environment.SOCKET_URL,
  VERSION:environment.VERSION,
  TOKEN_TYPES: {
    BEARER: 'bearer'
  },
  WEB_STORAGE_TYPE: {
    LOCAL: 'local',
    SESSION: 'session'
  },
  USER_ROLES: {
    SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
    ROLE_ADMIN: 'ROLE_ADMIN',
    ROLE_IT:'ROLE_IT',
    ROLE_DRIVER:'ROLE_DRIVER',
    ROLE_CLIENT:'ROLE_CLIENT',
    ROLE_SPOTTER:'ROLE_SPOTTER',
    ROLE_SUPERVISOR:'ROLE_SUPERVISOR',
    ROLE_GUARD:'ROLE_GUARD'
  },
  PATTERNS: {
    EMAIL: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  },
  FILE_TYPE: {
    IMAGE: 'IMAGE',
    VIDEO: 'VIDEO',
    DOCUMENT: 'DOCUMENT'
  }
};
